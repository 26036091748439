import { Button } from "@mui/joy";
import React, { useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { addOrganizationUsers } from "../../services/toolsService";
import { SubjectSharp } from "@mui/icons-material";

const InviteUsers = (props) => {
  const [selected, setSelected] = useState([]);
  const [invited, setInvited] = useState(false);

  return (
    !invited ? <div>
      <pre>{JSON.stringify(selected)}</pre>
      <TagsInput
        value={selected}
        onChange={setSelected}
        name="invitees"
        placeHolder="Enter email id of invitees"
      />
      <div style={{color: 'grey'}}>press enter or comma to add new tag</div>
      <Button style={{marginTop: 20}}type="submit" onClick={()=>{addOrganizationUsers({users: selected, org: props?.org}).then((res)=>{
        setInvited(true);
      }).catch(()=>{})
    
    }}>Add Users</Button>
    </div> : <div style={{display: 'flex', height: '10%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        Users has been added, please go to manage users tab for further actions.
    </div>
  );
};

export default InviteUsers;