import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import appReducer from './appSlice';

function getHourDifference(date1, date2) {
  const millisecondsDifference = Math.abs(date2 - date1);
  const hoursDifference = millisecondsDifference / (1000 * 60 * 60); // milliseconds in an hour

  return hoursDifference;
}



// Define a middleware function to save the state to local storage
const saveToLocalStorage = store => next => action => {
  const result = next(action);
  // Save the relevant part of the state to local storage
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
  return result;
};

// Load the state from local storage (if available) when creating the store
const loadFromLocalStorage = () => {
  try {
    
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    localStorage.clear();
    console.error('Error loading state from local storage:', error);
    return undefined;
  }
};

const preloadedState = loadFromLocalStorage();

export default configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
  },
  preloadedState, // Set the preloaded state retrieved from local storage
  middleware: [...getDefaultMiddleware(), saveToLocalStorage], // Use default middleware along with the custom middleware
});
