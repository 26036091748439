import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { ModalClose, Textarea } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { createIntro, createJob, getUserOrganizations, getWorkflows, updateJob } from '../../services/toolsService';
import WEditor from '../WYSIWYG/WEditor';
import FormWizard from "react-form-wizard-component";
import 'react-form-wizard-component/dist/style.css';

function JobWizard(props) {
    const [name, setName] = React.useState('');
    const [org, setOrg] = React.useState('personal');
    const [desc, setDesc] = React.useState(props.edit? props.selected.description : '');
    const [workflows, setWorkflows] = React.useState([]);
    const [workflow, setWorkflow] = React.useState(null);
    const [endDate, setEndDate] = React.useState(new Date());
    const [orgs, setOrgs] = React.useState([]);
    React.useEffect(()=>{
      getUserOrganizations().then((res)=>{
          console.log(res.data);
          
          setOrgs(res.data);
          if(res.data.length > 0){
            setOrg(res.data[0].id)
          }
      
         }).catch(()=>{})
  },[])
  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };
  const tabChanged = ({
    prevIndex,
    nextIndex,
  }) => {
    console.log("prevIndex", prevIndex);
    console.log("nextIndex", nextIndex);
  };
  const handleChange = (event, newValue) => {
    //alert(`You chose "${newValue}"`);
    setOrg(newValue)
  };
  return (
    <>
      {/* <FormWizard
        shape="circle"
        color="#e74c3c"
        onComplete={handleComplete}
        onTabChange={tabChanged}
      >
        <FormWizard.TabContent title="Initial Info" icon="ti-user"> */}
         
        <Typography id="basic-modal-dialog-title" component="h2">
            {props?.edit ? 'Update Job' : 'Add new job'}
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
          {props?.edit ? 'Update Job Details' : 'Create a job for your client'}
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              props.setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input  value={ name} onChange={(e)=>{
                
                  setName(e.target.value)
                
                  }} autoFocus required />
              </FormControl>
              <FormControl>
                <FormLabel>Under</FormLabel>
                <Select value={props.edit? props.selected.org_id : org} disabled={props.edit} onChange={handleChange}>
                
      {orgs.map((y)=>(
 <Option value={y.id}>{y.name}</Option>
      ))}
     
      
    </Select>
              </FormControl>
             
              <FormControl  style={{height: '100%'}}>
                <FormLabel>Description</FormLabel>
                <WEditor desc={desc} onChange={(e)=>{setDesc(e.target.value)}}></WEditor>
              </FormControl>
              <Stack spacing={2}>
              <Button onClick={()=>{
                createJob({name, description: desc, organization: org, workflow_id: workflow, end_date: endDate}).then((res)=>{
                  props.setOpen(false);
                }).catch(()=>{})
              }}>Create Job</Button>
              </Stack>
              
            </Stack>
          </form>
        {/* </FormWizard.TabContent> */}
        {/* <FormWizard.TabContent title="Additional Requirements" icon="ti-settings">
          <h1>Checklist</h1>
          <p>Some content for the second tab</p>
        </FormWizard.TabContent> */}
        {/* <FormWizard.TabContent title="Last step" icon="ti-check">
          <h1>Last Tab</h1>
          <p>Some content for the last tab</p>
        </FormWizard.TabContent> */}
      {/* </FormWizard> */}
      {/* add style */}
      <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
    </>
  );
}

export default JobWizard;