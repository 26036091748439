import { Button } from "@mui/joy";
import { useSelector } from "react-redux";
import { createApplication } from "../../services/toolsService";
import { useState } from "react";
import AlertDialogModal from "../../components/Modals/AlertDialogModal";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';


export default function OrgJob(props){
  const [alert, setAlert] = useState({open: false, title: '', actionText: '', text: '', exp: ''});
  const [full, setFull] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  
  const [applied, setApplied] = useState(false);
    return (
      <>
      <>
      <Stack direction="row" spacing={1}>
       
      </Stack>
      <Modal open={full} onClose={() => setFull(false)} >
        <ModalDialog layout={'fullscreen'}>
          <ModalClose />
        <div style={{maxWidth: '95%'}}>
          <div className="flexRow" style={{justifyContent: 'space-around'}}>
            <div className="flexCol" style={{ height: window.innerHeight, justifyContent: 'flex-start',alignItems: 'flex-start'}}>
            {props.logo!='' ? <div style={{width: 200, height: 200, borderRadius: 10  ,backgroundImage: `url("${props.logo}")`}}>
           
           </div>: <></>}
            </div>
            <div className="flexCol" style={{height: window.innerHeight,boxSizing: 'border-box', justifyContent: 'flex-start'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center',backgroundColor: 'white', width: '90%', height: (10/100)*window.innerHeight}}><h2>{props.title}</h2></div>
            <div dangerouslySetInnerHTML={{__html: `${props.desc}`}} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingTop: 10,height: (80/100)*window.innerHeight, width: '90%', overflowY: 'scroll', backgroundColor: 'white'}}>
              {/* {props.desc.substring(0,800)}{props.desc.length>800 ? '... more' : '' } */}
              </div>
            </div>
            <div className="flexCol" style={{ paddingTop: (5/100)*window.innerHeight,height: window.innerHeight,justifyContent: 'flex-start'}}>
            {props.applied.filter(x=>x.job ==props.link).length>0 || applied ? <Button style={{pointerEvents: 'none'}} variant={'soft'} color="success" >Applied</Button> : <Button color="primary" onClick={()=>{
                    if(user){
                      if(user?.type == 0){
                         setAlert({open: true, title: 'Are you sure you want to apply?', text: 'You are a recruiter and you are about to apply to a job. If you want to proceed with this action click Continue or click Cancel to close this modal.', actionText: 'Continue'})
                      } else if(props?.inCompleteProfile == true) {
                        setAlert({open: true, title: 'Incomplete Profile', text: 'Your profile is incomplete, please fill in your profile details before applying. Go to My Profile tab to fill in your details.', actionText: 'Go to My Profile'})
                      // createApplication({job: props.link}).then(()=>{
                      //   setApplied(true)
                      // }).catch(()=>{})
                    } else {
                       createApplication({job: props.link}).then(()=>{
                        setApplied(true)
                      }).catch(()=>{})
                    }
                    } else {
                    if(props.resume){
                       console.log(props.resume);
                    } else {
                       props.onApply(props.link);
                    }
                    }

                  }}>Apply</Button>}
              
            </div>
          </div>
        </div>
        </ModalDialog>
      </Modal>
    </>
      <AlertDialogModal onAction={()=>{
        if(props?.inCompleteProfile == true){
          navigate('/account')
        } else {
        createApplication({job: props.link}).then(()=>{
          setApplied(true)
        }).catch(()=>{})
        setAlert({...alert, open: false});
      }
      }} title={alert.title} actionText={alert.actionText} exp={alert.exp} text={alert.text} open={alert.open} setOpen={(val)=>{ setAlert({...alert, open: val})}} ></AlertDialogModal>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  width: '100%', height: 250,marginTop: 10, backgroundColor: 'white', borderRadius: 10, borderBottom: '1px solid gainsboro'}}>
        
       {props.logo!='' ? <div style={{width: 200, height: 200, borderRadius: 10  ,backgroundImage: `url("${props.logo}")`}}>
           
        </div>: <></>}
           <div style={{ display: 'flex', boxSizing: 'border-box',flexDirection: 'column', width: '80%', height: 200, backgroundColor: 'white', paddingLeft: 20, paddingRight: 20}}>
             <div style={{display: 'flex', boxSizing: 'border-box',flexDirection: 'row', alignItems:'center',backgroundColor: 'white', width: '100%', height: '20%'}}><h2>{props.title}</h2></div>
             <div style={{display: 'flex',boxSizing: 'border-box', flexDirection: 'row', backgroundColor: 'green', width: '100%', height: '80%'}}>
              <div dangerouslySetInnerHTML={{__html: `${props.desc.substring(0,400)}`}} style={{display: 'flex', overflow: 'hidden',flexDirection: 'row', alignItems: 'flex-start',width: '80%', paddingTop: 10,height: '100%',boxSizing: 'border-box', backgroundColor: 'white'}}>
              {/* {props.desc.substring(0,800)}{props.desc.length>800 ? '... more' : '' } */}
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',width: '20%', height: '100%', backgroundColor: 'white'}}>
              <Button variant='outlined' onClick={()=>{setFull(true)}} style={{marginRight: 10}}>More</Button>
                  {/* {props.applied.filter(x=>x.job ==props.link).length>0 || applied ? <Button style={{pointerEvents: 'none'}} variant={'soft'} color="success" >Applied</Button> : <Button color="primary" onClick={()=>{
                    if(user){
                      if(user?.type == 0){
                         setAlert({open: true, title: 'Are you sure you want to apply?', text: 'You are a recruiter and you are about to apply to a job. If you want to proceed with this action click Continue or click Cancel to close this modal.', actionText: 'Continue'})
                      } else if(props?.inCompleteProfile == true) {
                        setAlert({open: true, title: 'Incomplete Profile', text: 'Your profile is incomplete, please fill in your profile details before applying. Go to My Profile tab to fill in your details.', actionText: 'Go to My Profile'})
                      // createApplication({job: props.link}).then(()=>{
                      //   setApplied(true)
                      // }).catch(()=>{})
                    } else {
                       createApplication({job: props.link}).then(()=>{
                        setApplied(true)
                      }).catch(()=>{})
                    }
                    } else {
                    window.location.href = `#/signup?job=${props.link}`;
                    }

                  }}>Apply</Button>} */}
              </div>
             </div>
           </div>
        </div>
        </>
    )
}