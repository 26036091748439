import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip, { chipClasses } from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import DropZone from './DropZone';
import FileUpload from './FileUpload';
import CountrySelector from './CountrySelector';
import EditorToolbar from './EditorToolbar';
import { fileUpload } from '../../services/fileUploadService';
import AccountsTab from './AccountTabs';
import { TabPanel } from '@mui/joy';
import Organizations from './Organizations';
import TeamCreation from './TeamCreation';
import Teams from './Teams';
import ExperienceInput from '../ExperienceInput/ExperienceInput';
import { createAccount, createEducation, createFAQ, getAccount, getCandidateLinksProjects, getEducation, getFAQ, getLinksProjects, updateLinksProjects } from '../../services/toolsService';
import PersonalIntro from './PersonalIntro';
import CandidateView from '../../pages/CandidateView/CandidateView';
import EducationInput from '../ExperienceInput/EducationInput';
import UserCard from '../../pages/CandidateView/UserCard';
import Links from '../ExperienceInput/Links';

export default function LinksAndPortfolio(props) {
   const [links, setLinks] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
 
    React.useEffect(()=>{
      if(props?.viewMode == true){

        getCandidateLinksProjects({username: props?.username}).then((res)=>{
          setLinks(JSON.parse(res.data.links));
          setProjects(JSON.parse(res.data.portfolio));
        }).catch(()=>{})
      } else {
      getLinksProjects().then((res)=>{
        setLinks(JSON.parse(res.data.links));
        setProjects(JSON.parse(res.data.portfolio));
      }).catch(()=>{})
    }
    },[])
  //education
  let config = {
    onUploadProgress: progressEvent => {
     
    }
  }
  return (
    <Box
    style={{pointerEvents: props?.viewMode== true ? 'none': 'auto'}}
          sx={{
            pt: 3,
            pb: 10,
            display: 'grid',
            gridTemplateColumns: {
              xs: '100%',
              sm: 'minmax(120px, 30%) 1fr',
              lg: '280px 1fr minmax(120px, 208px)',
            },
            columnGap: { xs: 2, sm: 3, md: 4 },
            rowGap: { xs: 2, sm: 2.5 },
            '& > hr': {
              gridColumn: '1/-1',
            },
          }}
        >
        
         
         
         
          <Box>
            <FormLabel>Links</FormLabel>
            <FormHelperText>Share your links.</FormHelperText>
          </Box>
          <Stack useFlexGap spacing={1.5}>
          {/* <EducationInput></EducationInput> */}
               <Links viewMode={props.viewMode} links={links} 
               handleLinkChange = {(i,val)=>{
                let tempLinks = [...links];
                tempLinks[i].link = val;
                setLinks(tempLinks);
               }}
               handleVisibility={(i,val)=>{
                let tempLinks = [...links];
                tempLinks[i].isVisible = val;
                setLinks(tempLinks);
               }}
               handleAddLink={()=>{
                let tempLinks = [...links];
                tempLinks.push({link:'', isVisible: false});
                setLinks(tempLinks)
               }}
               ></Links>
          </Stack>
          <Divider role="presentation" />
           <Box>
            <FormLabel>Projects</FormLabel>
            <FormHelperText>Share your portfolio.</FormHelperText>
          </Box>
          <Stack useFlexGap spacing={1.5}>
          <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                    
                      fileUpload(e,config,{token: 'B37vlz4qQea8GMr7h8IYJOSlffK8FCkOY8FSgK7kUi/pM+UQ0nPsyxp+p1gSubrnZqEeiERDdK8xbTVheEakXQ=='})
                        .then(data => {
                         let userFiles = [...projects];
                         userFiles.push(data);
                         setProjects(userFiles);
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            // alert(err);
                            // this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="portfolio"
                  />
           { props?.viewMode == true ? <></>: <DropZone profilePicUpload={()=>{ document.getElementById("portfolio").click();}}/>}
{ 
  projects.map((x)=>(
    <FileUpload
    fileName={x.split('files/')[1].replace(/%20/g,' ')}
    fileSize=""
    progress={100}
  />
  ))
}
          

          </Stack>

          <Divider role="presentation" />

          {props?.viewMode == true ? <></> : <Box
            sx={{
              gridColumn: '1/-1',
              justifySelf: 'flex-end',
              display: 'flex',
              gap: 1,
            }}
          >
            <Button variant="outlined" color="neutral" size="sm">
              Cancel
            </Button>
            <Button size="sm" onClick={()=>{
             
             updateLinksProjects({links: JSON.stringify(links), portfolio: JSON.stringify(projects)})
             .then(()=>{})
             .catch(()=>{})
             
            }}>Save</Button>
          </Box>}
        </Box>
       
  );
}
