
import ViewPort from '../../components/ViewPort/ViewPort';
import './Candidates.css';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Autocomplete from '@mui/joy/Autocomplete';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import ChipDelete from '@mui/joy/ChipDelete';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import Divider from '@mui/joy/Divider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Slider from '@mui/joy/Slider';
import Sheet from '@mui/joy/Sheet';

// Icons import
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';

import Pagination from '@mui/material/Pagination';
import Search from '../../components/Search/Search';
import CandidateViewModal from '../../components/Modals/CandidateViewModal';
import { useState } from 'react';




function Candidates(props) {
  const [modal, setModal] = useState(false);
  const [candidate, setCandidate] = useState(null);
  return (
    
    <div className='candidates_container'>
      <CandidateViewModal candidate = {candidate} open={modal} setOpen={(x)=>{setModal(x); setCandidate(null);}}></CandidateViewModal>
   <div className='candidates'>
    {props.candidates.map((candidate)=>(
    <div className='candidate_card'>
              <Sheet
                // key={index}
                
                style={{}}
                component="li"
                variant="outlined"
                sx={{
                  borderRadius: 'sm',
                  p: 2,
                  listStyle: 'none',
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Avatar
                    src={candidate.pic}
                    srcSet={candidate.pic}
                    sx={{ borderRadius: 'sm' }}
                  />
                  <Box>
                    <Typography style={{}}>{candidate?.name}</Typography>
                    <Typography level="body3">{candidate?.role}</Typography>
                  </Box>
                </Box>
                <Divider component="div" sx={{ my: 2 }} />
                {
                  candidate?.exp !=null ? candidate?.exp.length>0 ? 
                  <List sx={{ '--ListItemDecorator-size': '48px' }}>
                  {candidate?.exp.slice(0,2).map((exp)=>(
 <ListItem sx={{ alignItems: 'flex-start' }}>
 <ListItemDecorator
   sx={{
     '&:before': {
       content: '""',
       position: 'absolute',
       height: '100%',
       width: '2px',
       bgcolor: 'divider',
       left: 'calc(var(--ListItem-paddingLeft) + 15px)',
       top: '50%',
     },
   }}
 >
   <Avatar
     size="sm"
     // src="https://www.vectorlogo.zone/logos/dribbble/dribbble-icon.svg"
     sx={{ backgroundColor: 'rgb(216, 222, 238)' }}
   >{exp.employer[0]}</Avatar>
 </ListItemDecorator>
 <ListItemContent>
   <Typography style={{}} fontSize="sm">{exp.employer}</Typography>
   <Typography level="body3"></Typography>
 </ListItemContent>
 <Typography level="body2">{`${exp.startDate.split('-')[0]} - ${exp.endDate == 'Present' ? 'now' : exp.endDate.split('-')[0]}`}</Typography>
</ListItem>
                  ))}
                 
                 
                </List> : <></> : <></>
                }
               
                <Button
                  size="sm"
                  variant="plain"
                  endDecorator={<KeyboardArrowRightRoundedIcon fontSize="small" />}
                  sx={{ px: 1, mt: 1 }}
                  onClick={()=>{setModal(true); setCandidate(candidate)}}
                >
                  Expand
                </Button>
                {/* <Divider component="div" sx={{ my: 2 }} />
                <div className={'bottom_actions'}>
                <Button className="shortlist" style={{backgroundColor: '#DAA520'}} color="warning" variant="outlined">Shortlist</Button>
                <Button variant="outlined">Accept</Button>
                <Button color="danger" variant="outlined">Reject</Button>
                </div> */}
              
              </Sheet>
              </div>))}
             
   </div>
   {/* <Pagination onChange={(event, value)=>{alert(value)}} style={{marginTop: 20, marginBottom: 20}} count={10} variant="outlined" color="primary" size="large" /> */}
   </div>

  );
}

export default Candidates;
