// SideNavbar.js
import React, { useState } from 'react';
import { Drawer, Card, CardContent, CardActions, Button, Typography, Collapse } from '@mui/material';
import { faHome, faContactCard, faBriefcase, faTools, faCircle, faPowerOff,  faCalendarTimes, faNetworkWired, faEnvelope, faUser, faContactBook, faBlackboard } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userLogout } from '../../store/authSlice';
import NavTab from '../Navbar/NavTab';
import NavButton from '../Navbar/NavButton';
const SideNav = () => {
  const [open, setOpen] = useState(false); // State to manage the collapse action
  const dispatch = useDispatch();
  const type = useSelector((state) => state.auth.user.type);

  const handleExpandClick = () => {
    setOpen(!open);
  };

  return (
    // <Drawer
    //   variant="permanent"
    //   sx={{
    //     width: 15/100*window.innerWidth,
    //     overflowY: 'scroll',
    //     flexShrink: 0,
    //     '& .MuiDrawer-paper': {
    //       width: 15/100*window.innerWidth,
    //       overflowY: 'scroll',
    //       boxSizing: 'border-box',
    //     },
    //   }}
    // >
    <>
      <Card style={{ overflowY: 'scroll',}}>
        <CardContent>
          <Typography variant="h6">Interviewable</Typography>
        </CardContent>
        <CardActions disableSpacing>
         
        </CardActions>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <CardContent>
          {type == 0 ? <div className='tab'><NavTab dotColor={'orange'} link={'home'} icon={ <FontAwesomeIcon size='2x' icon={faHome} />} text={'Home'}></NavTab> </div> : <></>}
      {type == 0 ? <div className='tab'><NavTab dotColor={'red'} link={'kanban'} icon={ <FontAwesomeIcon size='2x' icon={faBlackboard} />} text={'Boards'}></NavTab></div> : <></>}
      <div className='tab'><NavTab dotColor={'pink'} link={'jobs'} icon={ <FontAwesomeIcon size='2x' icon={faBriefcase} />} text={'Jobs'}></NavTab></div>
      {/* {type == 0 ? <div className='tab'><NavTab dotColor={'blue'} link={'tools'} icon={ <FontAwesomeIcon size='2x' icon={faTools} />} text={'Tools'}></NavTab></div> :<></>} */}
      {type == 0 ? <div className='tab'><NavTab dotColor={'pink'} link={'ats'} icon={ <FontAwesomeIcon size='2x' icon={faTools} />} text={'ATS'}></NavTab></div> :<></>}
      {/* {type == 0 ?<div className='tab'><NavTab dotColor={'green'} link={'interviews'} icon={ <FontAwesomeIcon size='2x' icon={faCalendarTimes} />} text={'Calendar'}></NavTab></div>:<></>} */}
      {/* {type==0? <div className='tab'><NavTab dotColor={'orange'} link={'network'} icon={ <FontAwesomeIcon size='2x' icon={faNetworkWired} />} text={'Network'}></NavTab></div> : <></>} */}
      {/* <div className='tab'><NavTab dotColor={'yellow'} link={'messages'} icon={ <FontAwesomeIcon size='2x' icon={faEnvelope} />} text={'Messages'}></NavTab></div> */}
     {/* {type==0? <div className='tab'><NavTab dotColor={'orange'} link={'dashboard'} icon={ <FontAwesomeIcon size='2x' icon={faNetworkWired} />} text={'Dash'}></NavTab></div> : <></>} */}
      
      <div className='tab'><NavTab dotColor={'purple'} link={'account'} icon={ 
    
      <FontAwesomeIcon size='2x' icon={faUser} />
      
      } text={'My Profile'}></NavTab></div>
     
     
      <div className='tab'><NavButton click={()=>{dispatch(userLogout())}} icon={ <FontAwesomeIcon color='red' size='2x' icon={faPowerOff} />} text={'Logout'}></NavButton></div>
          </CardContent>
        </Collapse>
      </Card>
      {/* Repeat for other menu items as needed */}
    
    </>
  );
};

export default SideNav;
