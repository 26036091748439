/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
// import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
// import IconButton from '@mui/joy/IconButton';
import Settings from '@mui/icons-material/Settings';
import InputModal from '../../components/Modals/InputModal';
import { getIntro, getJobs } from '../../services/toolsService';
import JobModal from '../../components/Modals/JobModal';
import Loading from '../Loading/Loading';
import FullScreenModal from '../../components/Modals/FullScreenModal';
//import intros from '../../../../services/toolsService';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(
  order,
  orderBy,
) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function JobsTable() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState('desc');
  const [selected, setSelected] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [view, setView] = React.useState(false);

  React.useEffect(()=>{
    setIsLoading(true);
getJobs().then((res)=>{
  setIsLoading(false);
console.log(res.data);
setRows(res.data);
}).catch(()=>{

})
  },[])
  const renderFilters = () => (
    <React.Fragment>
    
    <ButtonGroup style={{height: 'max-content', marginTop: 'auto'}} spacing="1rem" aria-label="spacing button group">
      {/* <Button variant='solid' color='warning' onClick={()=>{navigate(-1)}}>Back to Tools</Button> */}
      <Button variant='solid' color='danger' style={{marginLeft: 40}}onClick={()=>{
        setOpen(true);
      }}>Add New Job</Button>
      {/* <IconButton>
        <Settings />
      </IconButton> */}
    </ButtonGroup>
    </React.Fragment>
  );
  return (
     isLoading ? <Loading></Loading> : <React.Fragment>
      <FullScreenModal id={selected != null ? selected?.id: ''} open={view} setOpen={(x)=>{setView(x)}}></FullScreenModal>
      <JobModal open={open} edit={edit} view={view} selected={selected} setOpen={(val)=>{setOpen(val); if(!val){
        setEdit(false);
        setView(false);
      }}}></JobModal>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          justifyContent: 'space-between',
          py: 2,
          display: {
            xs: 'none',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <FormControl sx={{ flex: 0.5 }} size="md">
          {/* <FormLabel>Search</FormLabel> */}
          {/* <Input placeholder="Search" startDecorator={<i data-feather="search" />} /> */}
        </FormControl>

        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': (theme) =>
              theme.vars.palette.background.level1,
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': (theme) =>
              theme.vars.palette.background.level1,
          }}
        >
          <thead>
            <tr>
              {/* <th style={{ width: 48, textAlign: 'center', padding: 12 }}>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th> */}
              <th style={{ width: 140, padding: 12 }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  fontWeight="lg"
                  endDecorator={<i data-feather="arrow-down" />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Name
                </Link>
              </th>
              <th style={{ width: 120, padding: 12 }}>Date</th>
              <th style={{ width: 120, padding: 12 }}>Organization</th>
              <th style={{ width: 220, padding: 12 }}>Created</th>
              {/* <th style={{ width: 120, padding: 12 }}>Type</th> */}
              <th style={{ width: 160, padding: 12 }}> </th>
            </tr>
          </thead>
          <tbody>
            {/* {stableSort(rows, getComparator(order, 'id')) */}
            {rows.map((row,i) => (
              <tr key={i}>
                {/* <td style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={selected.includes(row.id)}
                    color={selected.includes(row.id) ? 'primary' : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td> */}
                <td>
                  <Typography fontWeight="md">{row.name}</Typography>
                </td>
                <td>{row.date}</td>
                <td>
                  {/* <Chip
                    variant="soft"
                    size="sm"
                    startDecorator={
                      {
                        Active: <i data-feather="check" />,
                        Disabled: <i data-feather="x" />,
                      }['active']
                    }
                    color={
                      {
                        Active: 'success',
                        Disabled: 'neutral',
                        Cancelled: 'danger',
                      }['active']
                    }
                  >
                    {'active'}
                  </Chip> */}
                   <Typography fontWeight="md">{row.organization}</Typography>
                </td>
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {/* <Avatar size="sm">{row.created}</Avatar> */}
                    <div>
                      {/* <Typography
                        fontWeight="lg"
                        level="body3"
                        textColor="text.primary"
                      >
                        {row.created}
                      </Typography> */}
                      <Typography fontWeight="md">{row.created}</Typography>
                      {/* <Typography level="body3">{row.created}</Typography> */}
                    </div>
                  </Box>
                </td>
                {/* <td>{row.subscription}</td> */}
                <td>
                <Link fontWeight="lg" component="button"  color="warning" onClick={()=>{
                 setSelected(row)
                 setEdit(true)
                 setOpen(true)
                }}>
                    Edit
                  </Link>
                  <Link fontWeight="lg" component="button" sx={{ ml: 2 }} color="danger" onClick={()=>{
                setSelected(row)
}}>
                    Delete
                  </Link>
                 
                  <Link
                    fontWeight="lg"
                    component="button"
                    color="primary"
                    sx={{ ml: 2 }}
                    onClick={()=>{
                      setSelected(row)
                      setView(true)
                      

                    }}
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      {/* <Box
        className="Pagination-mobile"
        sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}
      >
        <IconButton
          aria-label="previous page"
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <i data-feather="arrow-left" />
        </IconButton>
        <Typography level="body2" mx="auto">
          Page 1 of 10
        </Typography>
        <IconButton
          aria-label="next page"
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <i data-feather="arrow-right" />
        </IconButton>
      </Box>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 4,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="plain"
          color="neutral"
          startDecorator={<i data-feather="arrow-left" />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {['1', '2', '3', '…', '8', '9', '10'].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? 'outlined' : 'plain'}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="plain"
          color="neutral"
          endDecorator={<i data-feather="arrow-right" />}
        >
          Next
        </Button>
      </Box> */}
    </React.Fragment>
  );
}
