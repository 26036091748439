import React, { useEffect, useState } from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import UserCard from '../CandidateView/UserCard';
import BioCard from './UserCard';
import Groups from './Groups';
import { Button } from '@mui/joy';
import AddUsersModal from '../../components/Modals/AddUsersModal';
import { GetConnections, getOrganizationUsers } from '../../services/toolsService';

const users = [1,2,3,4,5,6,7,8,9,10];

const Connections = () => {
    const [selected, setSelected] = React.useState([]);
    const [addConnection, setAddConnection] = React.useState(false);
    const [connections, setConnections] = useState([]);

    useEffect(() => {
      GetConnections().then((res) => {
        setConnections(res.data);
      }).catch((err) => {});
    }, []);
  
    return (
      <div style={{display: 'flex', flex: 1}}>
        <AddUsersModal open={addConnection} org={''} mode={'connections'} setOpen={setAddConnection}></AddUsersModal>
        <Tabs style={{display: 'flex', flex: 1}} aria-label="Basic tabs" defaultValue={0}>
          <TabList>
            <Tab>Connections</Tab>
            <Tab>Organizations</Tab>
          </TabList>
          <TabPanel value={0}>
            <div className='flexRow' style={{ marginTop: 20, marginLeft: 40, marginRight: 40 }}>
              <div className='flexRow' style={{ width: '40%', justifyContent: 'space-around' }}>
                <Button onClick={()=>{setAddConnection(true)}}>New Connection</Button>
              </div>
            </div>
            <div className='gridContainer'>
              {connections.map((user, index) => (
                <BioCard
                  key={index}
                  checked={selected.includes(index)}
                  id={index}
                  user={user}
                  unSelect={(x) => {
                    let sel = selected.concat([]);
                    sel = sel.filter((y) => y !== x);
                    setSelected(sel);
                    console.log(selected);
                  }}
                  onSelect={(x) => {
                    let sel = selected.concat([]);
                    sel.push(x); // Use push separately
                    setSelected(sel);
                    console.log(selected);
                  }}
                  style={{ marginLeft: 20 }}
                ></BioCard>
              ))}
              {users.length === 0 ? <div className='gridItem'>No Connections</div> : <></>}
            </div>
          </TabPanel>
          <TabPanel value={1} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div className='gridItem'>Organisation Connect feature is coming soon.</div>
          </TabPanel>
        </Tabs>
      </div>
    );
  };
  
  export default Connections;
  