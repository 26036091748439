import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { verifyUser } from '../services/toolsService';

function VerifyComponent() {
 const [message, setMessage] = React.useState('Please Wait, you will be redirected to the login page shortly.');

  let [searchParams] = useSearchParams();
  let token = searchParams.get('token');
  let username = searchParams.get('username');

  useEffect(() => {
    verifyUser({ token, username }).then((res)=>{
        setMessage("Verification Successful, you can now login.");
    }).catch(()=>{});
  }, []);


  return (
    <div>
      <h1>Verification Page</h1>
     <p>
        {message}
     </p>
     
    </div>
  );
}

export default VerifyComponent;
