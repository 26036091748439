import * as React from 'react';
import './WorkflowModal.css';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { Textarea } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { createIntro, createWorkflow, getWorkflowActions } from '../../services/toolsService';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

export default function WorkflowModal(props) {
   const [name, setName] = React.useState('');
   const [confirm, setConfim] = React.useState(false);

   const [availableActions, setAvailableActions] = React.useState([]);
   const [selectedActions, setSelectedActions] = React.useState([]);
   const [org, setOrg] = React.useState('personal');
   const [desc, setDesc] = React.useState('');
   React.useEffect(()=>{
    getWorkflowActions().then((res)=>{
      setAvailableActions(res.data)
    }).catch(()=>{})
   },[])
   const handleChange = (event, newValue) => {
    //alert(`You chose "${newValue}"`);
    setOrg(newValue)
  };
  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: '80%', height: '80%'}}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Add new workflow
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
           Link together actions to create a custom workflow.
          </Typography>
          {!confirm ? <form
            onSubmit={(event) => {
              event.preventDefault();
              props.setOpen(false);
            }}
          >
            <Stack spacing={2}>
             
            <div class="jobs_container">
    {/* <div class="header">{this.state.screen2.title}</div> */}
    <div class="main">
    <div class="actions_div">
    <div class="action_div_header">
        <label >Search Actions.</label>
        <input class="input"  type="text"  placeholder="Search"></input>
        </div>
        <div class="actions_basket">
            {availableActions.map((action,index)=>(
               <div key={index} class="action"><div class="action_div_left"></div><div class="action_div_center">{action.name}</div><div onClick={()=>{
               
                let selected = [...selectedActions];
                selected.push(action)
                setSelectedActions(selected);
                
                setAvailableActions(availableActions.filter(x=>x.name!=action.name))
                // let selectedActions = this.state.screen2.selectedActions;
                // selectedActions.push(action);
                // let availableActions = this.state.screen2.availableActions;

                // availableActions = availableActions.filter(x=>x.name!=action.name);
                // this.setState({...this.state, screen2:{...this.state.screen2, selectedActions,availableActions}});
               }} class="action_div_right"><i className={`fa-sharp fa-solid fa-chevron-right`}></i></div></div> 
            ))}
       
        </div>
        
    </div>
    <div class="actions_div">
    <div class="action_div_header">
    <div style={{padding: 10}}>Use the arrow keys to reorder actions in the flow. Actions appear to the candidates in order.</div>
    </div>
    <div class="actions_basket">
        {selectedActions.map((action,index)=>(
            <div class="action"><div class="action_div_left"><i onClick={()=>{
                let actions = [...availableActions];
                let selActions = [...selectedActions];
                selActions = selActions.filter(x=>x.name != action.name);
                actions.push(action);
                setSelectedActions(selActions)
                setAvailableActions(actions);
                // this.setState({...this.state, screen2: {...this.state.screen2, selectedActions, availableActions}});
            }} className={`fa-sharp fa-solid fa-chevron-left`}></i></div><div class="action_div_center">{action.name}</div><div class="action_div_right"><i onClick={()=>{
                   let actions = [...selectedActions];
                // let selectedActions = this.state.screen2.selectedActions;
                if(index!==actions.length-1){
                        actions[index].order = index+1;
                    actions[index+1].order=index;
                    let temp = actions[index];
                    actions[index] = actions[index+1];
                    actions[index+1]= temp;
                    setSelectedActions(actions)
                }
                //     selectedActions[index].order = index+1;
                //     selectedActions[index+1].order=index;
                //     let temp = selectedActions[index];
                //     selectedActions[index] = selectedActions[index+1];
                //     selectedActions[index+1]= temp;
                //     console.log(selectedActions)
                //     this.setState({...this.state, screen2: {...this.state.screen2, selectedActions}}); 
                // }
            }} className={`fa-sharp fa-solid fa-chevron-down`}></i><i onClick={()=>{
              let actions = [...selectedActions];
                // let selectedActions = this.state.screen2.selectedActions;
                if(index!==0){
                    actions[index].order = index-1;
                    actions[index-1].order=index;
                    let temp = actions[index];
                    actions[index] = actions[index-1];
                    actions[index-1]= temp;
                    
                    setSelectedActions(actions);
                } 
            }} className={`fa-sharp fa-solid fa-chevron-up`} ></i></div></div>
        ))}
    </div>
    </div>
    <div class="actions_div">
    <div style={{height: '100%',display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{padding: 10}}>Once you click confirm your posting will be live and the candidates will be able to see and respond to the post. A share link will be availble in the next screen which can be send to candidates directly or posted in social media sites.</div>
    <button class="action_button" onClick={()=>{
        // this.setState({...this.state, currentScreen: 1})
        }}>Back</button>
    <button class="action_button" onClick={()=>{
      console.log(selectedActions);
      setConfim(true);
     

        // let obj = {
        //     title: this.state.screen1.heading,
        //     role: this.state.screen1.role,
        //     technologies: this.state.screen1.technologies,
        //     company: this.state.screen1.company[0].name,
        //     last: this.state.screen1.last.toISOString().slice(0, 10),
        //     jd: this.state.screen1.jd,
        //     schedule: this.state.screen1?.schedule?.toISOString().slice(0, 10),
        //     stakeholders: this.state.screen1.stakeholders,
        //     flow: this.state.screen2.selectedActions
        // };
        // console.log(obj)
        // createJob(obj).then((res)=>{
        //     console.log(res.data)
        // }).catch((err)=>{
        //     console.log(err)
        // })
        
    }}>Confirm</button>
    </div>
    </div>
    </div>
    </div>


            </Stack>
          </form> : <div style={{maxWidth: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <FormControl style={{width: '100%'}}>
                <FormLabel>Workflow Name</FormLabel>
                <Input readOnly={props.view} value={name} onChange={(e)=>{
                
                  setName(e.target.value)
                
                  }} autoFocus required />
              </FormControl>
              <FormControl style={{width: '100%'}}>
                <FormLabel>Description</FormLabel>
                <Input readOnly={props.view} value={desc} onChange={(e)=>{
                
                  setDesc(e.target.value)
                
                  }} autoFocus required />
              </FormControl>
              <FormControl style={{width: '100%'}}>
                <FormLabel>Under</FormLabel>
                <Select  disabled={props.view} defaultValue={org} onChange={(e)=>{
                  setOrg(e.target.value)
                }}>
      <Option value="personal">Personal</Option>
      <Option value="xilligence">Xilligence</Option>
     
    </Select>
              </FormControl>
          <button class="action_button" onClick={()=>{
            setConfim(false)
          }}>
            Back
          </button>
          <button class="action_button" onClick={()=>{
 let data = {
  name: name,
  description: desc,
  organization: org,
  json: JSON.stringify(selectedActions)
};
console.log(data)
createWorkflow(data).then(()=>{
  props.setOpen(false);
}).catch(()=>{})

          }}>
            Complete
          </button>
          </div>}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}