import * as React from 'react';
import Table from '@mui/joy/Table';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { getOrganizationUsers, updateOrganizationUser } from '../../services/toolsService';
import { Button } from '@mui/joy';

export default function ManageOrgUsers(props) {
    const [users, setUsers] = React.useState([]);
    React.useEffect(()=>{
     getOrganizationUsers(props.id).then((res)=>{
        setUsers(res.data);
     }).catch(()=>{})
    },[])
  return (
    <Table aria-label="basic table">
      <thead>
        <tr>
          <th >Name</th>
          <th style={{width: '20%'}}>Role</th>
          <th style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>Action</th>
         
        </tr>
      </thead>
      <tbody>
        {users.map((user)=>(
              <tr>
              <td>{user.name}</td>
              <td>
              <Select onChange={(e,n)=>{let tempUsers = [...users];
               tempUsers.filter(x=> x.id == user.id)[0].role = n;
                setUsers(tempUsers);
               
            }} defaultValue={user.role}>
          <Option value="Admin">Admin</Option>
          <Option value="Member">Member</Option>
        </Select>
              </td>
              <td>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <Button onClick={()=>{
                    let data = user;
                    data.mode = 'update';
                    console.log(data);
                    updateOrganizationUser(data).then((res)=>{
                        alert('updated');
                    }).catch(()=>{});
                }} size="sm">Update</Button>
                <Button onClick={()=>{
                     let data = user;
                     data.mode = 'delete';
                     console.log(data);
                     updateOrganizationUser(data).then((res)=>{
                         alert('deleted');
                     }).catch(()=>{});
                }} size="sm" style={{marginLeft: 10}} color="danger">Delete</Button>
                </div>
              </td>
             
            </tr>
        ))}
        {/* <tr>
          <td>Frozen yoghurt</td>
          <td>
          <Select defaultValue="dog">
      <Option value="dog">Dog</Option>
      <Option value="cat">Cat</Option>
    </Select>
          </td>
          <td>6</td>
         
        </tr>
        <tr>
          <td>Ice cream sandwich</td>
          <td>237</td>
          <td>9</td>
          
        </tr>
        <tr>
          <td>Eclair</td>
          <td>262</td>
          <td>16</td>
         
        </tr>
        <tr>
          <td>Cupcake</td>
          <td>305</td>
          <td>3.7</td>
         
        </tr>
        <tr>
          <td>Gingerbread</td>
          <td>356</td>
          <td>16</td>
         
        </tr> */}
      </tbody>
    </Table>
  );
}