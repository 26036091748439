import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { Textarea } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { createIntro } from '../../services/toolsService';
import InviteUsers from './InviteUsers';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import ManageOrgUsers from './ManageOrgUsers';
import IconButton from '@mui/joy/IconButton';
// import Stack from '@mui/joy/Stack';
import FormatBold from '@mui/icons-material/FormatBold';

export default function AddUsersModal(props) {

  const [hideProfile, setHideProfile] = React.useState(false);
  const [hideJobs, setHideJobs] = React.useState(false);
  const [hideJobLogo, setHideJobLogo] = React.useState(false);
 
  let customURL = ()=>{
  
   let url = `https://interviewable.com/#/org/${props.org}?hideProfile=${hideProfile}&hideJobs=${hideJobs}&hideJobLogo=${hideJobLogo}`;
   return url;
  
  }
  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: '60%'}}
        >
           <Tabs aria-label="Basic tabs" defaultValue={0}>
      <TabList>
      {props?.mode !='connections' ?<Tab>Invite Users</Tab>:<></>}
        {props?.mode !='connections' ? <Tab>Manage Users</Tab> : <></>}
        {props?.mode !='connections' ?<Tab>Embed</Tab> : <></>}
        
      </TabList>
      <TabPanel value={0}>
      {/* <Typography id="basic-modal-dialog-title" component="h2">
            Invite users
          </Typography> */}
           {props?.mode !='connections' ? <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
            Add users to organization
          </Typography> : <Typography id="basic-modal-dialog-description" textColor="text.tertiary">Add connections</Typography>}
        
            <Stack spacing={2}>
             <InviteUsers mode={props?.mode} org={props.org}></InviteUsers>
            </Stack>
      </TabPanel>
      <TabPanel value={1}>
      <ManageOrgUsers id={props.org}> </ManageOrgUsers>
      </TabPanel>
      <TabPanel value={2}>
       <div style={{display: 'flex', paddingTop: 20, paddingBottom: 20,flexDirection: 'column', justifyContent: 'center'}}>
        <div><span>Your organization URL : </span> <a href={`https://interviewable.com/#/org/${props.org}`}>{`https://interviewable.com/#/org/${props.org}`}</a></div>
        <div style={{marginTop: 30}}>
          <span> Customize Profile Page </span>
        <Stack style={{marginTop: 20}}spacing={2} direction="row">
      <Button
        variant="outlined"
        color="neutral"
        onClick={() => setHideProfile(!hideProfile)}
        aria-pressed={hideProfile ? 'true' : 'false'}
        sx={(theme) => ({
          [`&[aria-pressed="true"]`]: {
            ...theme.variants.outlinedActive.neutral,
            borderColor: theme.vars.palette.neutral.outlinedHoverBorder,
          },
        })}
      >
        Hide Profile Section
      </Button>
      <Button
        variant="outlined"
        color="neutral"
        onClick={() => setHideJobs(!hideJobs)}
        aria-pressed={hideJobs ? 'true' : 'false'}
        sx={(theme) => ({
          [`&[aria-pressed="true"]`]: {
            ...theme.variants.outlinedActive.neutral,
            borderColor: theme.vars.palette.neutral.outlinedHoverBorder,
          },
        })}
      >
        Hide Jobs Section
      </Button>
      <Button
        variant="outlined"
        color="neutral"
        onClick={() => setHideJobLogo(!hideJobLogo)}
        aria-pressed={hideJobLogo ? 'true' : 'false'}
        sx={(theme) => ({
          [`&[aria-pressed="true"]`]: {
            ...theme.variants.outlinedActive.neutral,
            borderColor: theme.vars.palette.neutral.outlinedHoverBorder,
          },
        })}
      >
        Hide Jobs Logo
      </Button>
    
    </Stack>
        </div>
        <div style={{marginTop: 30, marginBottom: 10}}>
          <span>Customized URL : </span>
        </div>
        <div><a href={customURL()}>
          {customURL()}
          
          </a></div>
       </div>
      </TabPanel>
    
    </Tabs>
         
        
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}