import axios from "axios";
import store from "../store/store";

var baseUrl = "https://interviewableapi20220528123614.azurewebsites.net/api/";

// if(window.location.href.includes('localhost')){
//   baseUrl = "http://localhost:7071/api/";
// }
// baseUrl = "http://localhost:7071/api/";

axios.interceptors.request.use(
    config => {
      const token = store.getState().auth.token;
      if (token) {
        config.headers['x-functions-key'] = token
      }
      // config.headers['Content-Type'] = 'application/json';
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

function createUser(data){
    const promise = axios.post(
        `${baseUrl}createuser`,
        data
      );
   return promise;
}

function createGroup(data){
  const promise = axios.post(
      `https://interviewableapi20220528123614.azurewebsites.net/api/creategroup`,
      data
    );
 return promise;
}

function getFriends(){
  const promise = axios.get(
      `${baseUrl}getfriends`
    );
 return promise;
}

function login(data){
    const promise = axios.post(
        `${baseUrl}login`,
        data
      );
   return promise;
}

function checkValidity(data){
  const promise = axios.post(
      `${baseUrl}CheckValidity`,
      data
    );
 return promise;
}

function invite(data){
    const promise = axios.post(
        `${baseUrl}inviteusers`,
        data
      );
   return promise;
}



export {
    createUser,
    login,
    invite,
    getFriends,
    createGroup,
    checkValidity
}