import { MainContainer, Container, Input, ButtonContainer, Button } from '../../Elements';
import React, { useState, useEffect } from 'react';
import { login } from '../../services/userService';
import myImage from '../../assets/images/login.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { userLogin, userLogout } from '../../store/authSlice';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { setLoading } from '../../store/appSlice';
import { GoogleLogin } from '@react-oauth/google';
import { googleSignin } from '../../services/toolsService';
const inputElements = [
  {
    placeholder: 'Email',
    type: 'email',
  },
  {
    placeholder: 'Password',
    type: 'password',
  },
];

const bgImg1 = 'https://static.vecteezy.com/system/resources/previews/002/007/000/original/work-from-home-concept-young-man-freelancers-working-on-laptops-at-home-people-at-home-in-quarantine-pink-background-back-view-staying-at-home-illustration-flat-design-character-vector.jpg';

const Login = () => {
 
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const job = queryParams.get('job');
  const org = queryParams.get('org');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');





  

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (user) {
  //     // Redirect to home page if user is already logged in
  //     //dispatch(userLogout());
  //   }
  // }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };

  const onSubmit = () => {
    dispatch(setLoading(true));
    login({ email, password, job, org })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(userLogin({ user: res.data.user, token: res.data.token }));
       navigate(`/${res.data.user.type==0 ? 'home' :  'jobs'}${location.search}`);
      })
      .catch(() => {
        dispatch(setLoading(false));
        // Do something on error
      });
  };

  return (
    <MainContainer>
      <Container style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, backgroundImage: `url(${myImage})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} />
      <Container style={{ borderTopRightRadius: 40, borderBottomRightRadius: 40 }}>
        <span style={{ fontSize: '2.5em', fontWeight: 'bold', color: 'rgba(37,40,73,1)' }}>Let's Start,</span><br></br>
        {inputElements.map((el, i) => (
          <Input
            onChange={handleChange}
            name={el.placeholder.toLowerCase()}
            key={i}
            placeholder={el.placeholder}
            type={el.type}
          />
        ))}
        <ButtonContainer>
          <Button style={{ backgroundColor: 'rgba(37,40,73,1)', height: 50 }} onClick={onSubmit}>Login</Button>


        </ButtonContainer>
        <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
    dispatch(setLoading(true));
    googleSignin({token: credentialResponse?.credential}).then((res)=>{
      console.log(res)
      dispatch(setLoading(false));
      dispatch(userLogin({ user: res.data.result.user, token: res.data.result.token }));
     navigate(`/${res.data.result.user.type==0 ? 'home' :  'jobs'}${location.search}`);
    }).catch(()=>{
      dispatch(setLoading(false));
    })
  }}
  onError={() => {
    console.log('Login Failed');
    dispatch(setLoading(false));
  }}
/>
      </Container>
    </MainContainer>
  );
};

export default Login;
