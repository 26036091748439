import BioCard from "../../components/BioCard/BioCard"
import './Network.css';

function Network(){
    return (
     <div className="network-container">
        {[1,2,3,4,5,6,7,8,9,10].map(()=>(   
    <BioCard></BioCard>)
        )
        }
    </div>
    )
}

export default Network;