import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
// import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
// import IconButton from '@mui/joy/IconButton';
import Settings from '@mui/icons-material/Settings';
import InputModal from '../../components/Modals/InputModal';
import { GetATS, SearchATS, deleteATS, getIntro, getJobs, singleFileUploader, upResume } from '../../services/toolsService';
import JobModal from '../../components/Modals/JobModal';
import Loading from '../Loading/Loading';
import FullScreenModal from '../../components/Modals/FullScreenModal';
import { Container } from '@mui/joy';
import { fileUpload, multiFileUploader } from '../../services/fileUploadService';
import ApplicantDetails from './ApplicatDetails';
import StarRating from '../../components/Rating';
import Timer from './Timer';
import StringList from './StringList';

const JobATS = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState('desc');
  const [selected, setSelected] = React.useState(null);
  const [selectedUrl, setSelectedUrl] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [uploadResult, setUploadResult] = React.useState([]);
  const [duplicate, setDuplicate] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [searchSelector, setSearchSelector] = React.useState('skills');
  const [edit, setEdit] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(-1);
  const [remarks, setRemarks] = React.useState([]);
  const [checked, setChecked] = React.useState([]);

  const [seconds, setSeconds] = React.useState(0);
    // Your component logic here

    return (
        <div>
             <Modal open={view} onClose={() => setView(false)} >
        <ModalDialog layout={'fullscreen'}>
          {/* <ModalClose /> */}
        <div className="flexCol" style={{maxWidth: '100%',height: window.innerHeight, boxSizing: 'border-box',overflowY: 'scroll', alignItems: 'center'}}>
         {
          selected!= null ? <ApplicantDetails remarks={remarks} setRemarks={(val)=>{setRemarks(val)}} id={selectedId} close={()=>{setView(false); }} applicantData={selected} url={selectedUrl}></ApplicantDetails>
          : <></>
         }
        </div>
        </ModalDialog>
      </Modal>
            <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': (theme) =>
              theme.vars.palette.background.level1,
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': (theme) =>
              theme.vars.palette.background.level1,
          }}
        >
          <thead>
            <tr>
              {/* <th style={{ width: 48, textAlign: 'center', padding: 12 }}>
                <Checkbox
                  indeterminate={
                    checked.length > 0 && checked.length !== rows.length
                  }
                  checked={checked.length === rows.length}
                  onChange={(event) => {
                    setChecked(
                      event.target.checked ? rows.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    checked.length > 0 || checked.length === rows.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th> */}
              <th style={{ width: 100, padding: 12 }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  fontWeight="lg"
                  endDecorator={<i data-feather="arrow-down" />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Name
                </Link>
              </th>
              <th style={{ width: 150, padding: 12 }}>Email</th>
              {/* <th style={{ width: 100, padding: 12 }}>Phone</th> */}
              <th style={{ width: 220, padding: 12 }}>Skills</th>
              {/* <th style={{ width: 150, padding: 12 }}>Rating</th> */}
              {/* <th style={{ width: 120, padding: 12 }}>Type</th> */}
              <th style={{ width: 160, padding: 12 }}> </th>
            </tr>
          </thead>
          <tbody>
            {/* {stableSort(rows, getComparator(order, 'id')) */}
            {props.rows.map((row,i) => (
              <tr key={i}>
                {/* <td style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={checked.indexOf(row.id) !== -1}
                    color={'primary'}
                    onChange={(event) => {
                      setChecked((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td> */}
                <td>
                  <Typography fontWeight="md">{row.name}</Typography>
                </td>
                <td>{row.email}</td>
                {/* <td>
                
                   <Typography fontWeight="md">{row.phone}</Typography>
                </td> */}
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {/* <Avatar size="sm">{row.created}</Avatar> */}
                    <div>
                      {/* <Typography
                        fontWeight="lg"
                        level="body3"
                        textColor="text.primary"
                      >
                        {row.created}
                      </Typography> */}
                      <Typography fontWeight="md">{row.skills}</Typography>
                      {/* <Typography level="body3">{row.created}</Typography> */}
                    </div>
                  </Box>
                </td>
                {/* <td>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <StarRating id={row.id}></StarRating>
                </Box>
                </td> */}
                {/* <td>{row.subscription}</td> */}
                <td>
              
                  <Link fontWeight="lg" component="button" sx={{ ml: 2 }} color="danger" onClick={()=>{
               
}}>
                    Delete
                  </Link>
                 
                  <Link
                    fontWeight="lg"
                    component="button"
                    color="primary"
                    sx={{ ml: 2 }}
                    onClick={()=>{
                      setSelectedId(row.id);
                      setRemarks(JSON.parse(row.remarks));
                      setSelected(JSON.parse(row.output.trim()))
                      setSelectedUrl(row.url);
                      
                      setView(true)
                      

                    }}
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
    );
};

export default JobATS;
