import React, { useEffect, useState } from 'react';
import Editor from 'react-simple-wysiwyg';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { renderToString } from 'react-dom/server';
import FormHelperText from '@mui/joy/FormHelperText';
import { Button, Textarea } from '@mui/joy';
import { updateATSRemarks, updateResumeJSON } from '../../services/toolsService';
import StarRating from '../../components/Rating';
import { useSelector } from 'react-redux';

export default function ResumeView(props) {

  const user = useSelector((state) => state.auth.user);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [skills, setSkills] = useState('');
 
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const [rating, setRating] = useState(0);
  const [remarks, setRemarks] = useState('');

  const handleEducationChange = (name, value, i) => {
    // Create a new array with the updated value
    const updatedEducation = [...education];
    updatedEducation[i] = {
      ...updatedEducation[i],
      [name]: value,
    };
    
    // Update the state with the new array
    setEducation(updatedEducation);
  };

  const handleExperienceChange = (name, value, i) => {
    // Create a new array with the updated value
    const updatedExperience = [...experience];
    updatedExperience[i] = {
      ...updatedExperience[i],
      [name]: value,
    };
    
    // Update the state with the new array
    setExperience(updatedExperience);
  };
  const renderEducationContent = (content) => (
    <section>
    <ul>
      {content && content.map((edu, index) => (
        <div>
          <label>
            Name:
            <Input name='name' type="text" value={edu.name} onChange={(e)=>{handleEducationChange(e.target.name,e.target.value, index)}} />
          </label>
          <br></br>
          <label>
            Institution:
            <Input name='institution' type="text" value={edu.institution}  onChange={(e)=>{handleEducationChange(e.target.name,e.target.value, index)}}/>
          </label>
          <br></br>
          <label>
            Date:
            <Input name='date' type="text" value={edu.date}  onChange={(e)=>{handleEducationChange(e.target.name,e.target.value, index)}}/>
          </label>
          
        </div>
      ))}<Button onClick={()=>{

        let tempEducation = [...education];
        tempEducation.push({
          name: '',
          institution: '',
          date: ''
        });
        setEducation(tempEducation);
      }}>Add Education</Button>
    </ul>
  </section>
  );
  const renderExperienceContent = (content) =>(
    <section>
    <ul>
      {content && content.map((exp, index) => (
        <div>
          <label>
            Institution:
            <Input name='name' type="text" value={exp.name} onChange={(e)=>{handleExperienceChange(e.target.name, e.target.value, index)}}/>
          </label>
          <br></br>
          <label>
            Place:
            <Input name='place' type="text" value={exp.place} onChange={(e)=>{handleExperienceChange(e.target.name, e.target.value, index)}} />
          </label>
          <br></br>
          <label>
            Date:
            <Input name='date' type="text" value={exp.date} onChange={(e)=>{handleExperienceChange(e.target.name, e.target.value, index)}} />
          </label>
       </div>
      ))} <Button onClick={()=>{
        let tempExperience = [...experience];
        tempExperience.push({
          name: '',
          place: '',
          date: ''
        });
        setExperience(tempExperience);
      }}>Add Experience</Button>
    </ul>
  </section>
  

  )



  useEffect(()=>{
    

    setName(props.data.name);
    setEmail(props.data.email);
    setPhone(props.data.phone);
    

   
    setEducation(props.data.education);
    setExperience(props.data.experience);
  
    setSkills(props.data.skills ? props.data.skills.join() : '');

  },[])
  
  return (
    <div className="flexRow" style={{ width: '100vw', height: '100vh' }}>
      <div
        className="flexRow"
        onClick={props.close}
        style={{
          position: 'fixed',
          top: 15,
          left: 15,
          height: 40,
          width: 40,
          borderRadius: 20,
          backgroundColor: 'red',
          color: 'white',
        }}
      >
        X
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
          boxSizing: 'border-box',
          justifyContent: 'flex-start',
          paddingTop: '50px', // Add some padding to the top
        }}
        className="flexCol"
      >
        <div className="flexCol" style={{ width: '90%', padding: '20px', justifyContent: 'flex-start' }}>
          <Input
            size="lg"
            placeholder="Name"
            value={name}
            onChange={(e)=>{
              setName(e.target.value)
            }}
            style={{ marginBottom: '15px', width: '100%' }}
          />
          <Input
            size="lg"
            placeholder="Email"
            value={email}
            onChange={(e)=>{
              setEmail(e.target.value)
            }}
            style={{ marginBottom: '15px', width: '100%' }}
          />
          <Input
            size="lg"
            placeholder="Phone"
            value={phone}
            onChange={(e)=>{
              setPhone(e.target.value)
            }}
            style={{ marginBottom: '15px', width: '100%' }}
          />
          {/* <Input
            size="lg"
            placeholder="Experience"
            style={{ marginBottom: '15px', width: '100%' }}
          /> */}
          <FormControl style={{ marginBottom: '15px', width: '100%' }}>
            <FormLabel>Skills</FormLabel>
            <Textarea style={{ minHeight: 200 }} value={skills} onChange={(e) => setSkills(e.target.value)} />
           
          </FormControl>
          <FormControl style={{ marginBottom: '15px', width: '100%' }}>
            <FormLabel>Education</FormLabel>
            {renderEducationContent(education)}
            
          </FormControl>
          <FormControl style={{ marginBottom: '15px', width: '100%' }}>
            <FormLabel>Experience</FormLabel>
            {/* <Editor style={{ minHeight: 200 }} value={experience} onChange={(e) => setExperience(e.target.value)} />
            <FormHelperText>This is a helper text.</FormHelperText> */}
            {renderExperienceContent(experience)}
          </FormControl>
          <Button style={{marginTop: 20}} onClick={()=>{
            let obj = {
              name,
              email,
              phone,
              education,
              experience,
              skills: skills.split(',')
            }
            console.log(obj);
            console.log(props.url);
            updateResumeJSON({json: JSON.stringify(obj), url: props.url}).then((res)=>{
              alert('Updated');
            }).catch(()=>{})
          }}>Update</Button>
        </div>
      </div>
      <div
        style={{
          width: '50%',
          height: '100%',
          overflowY: 'scroll',
          boxSizing: 'border-box',
          justifyContent: 'flex-start',
          paddingTop: '50px', // Add some padding to the top
        }}
        className="flexCol"
      >
        <div style={{width: '100%'}}>
       <FormControl style={{ marginBottom: '15px', width: '90%' }}>
       <label>Rating</label>
                  <StarRating rating={rating} setRating={setRating}></StarRating>
                  </FormControl>
                  <FormControl style={{ marginBottom: '15px', width: '90%' }}>
                    <label>Remarks</label>
                   <textarea onChange={(e)=>{setRemarks(e.target.value)}}></textarea>
                   </FormControl>
                   <FormControl style={{ marginBottom: '15px', width: '90%' }}>
                    <Button onClick={()=>{
                     
                      let obj = {
                        rating,
                        remarks,
                        username: user.username
                      }
                      let remarksArray = props.remarks;
                      remarksArray = remarksArray.filter((el)=>el.username!=user.username);
                      remarksArray.push(obj);
                    
                      updateATSRemarks({json: JSON.stringify(remarksArray), id: props.id}).then((res)=>{
                        alert('Updated');
                        props.setRemarks(remarksArray);
                      }).catch(()=>{})
                    }}>
                      Update rating and Remarks
                    </Button>
                   </FormControl>
                   </div>
                   <div style={{marginTop: 50, width: '100%'}}>
                    <FormControl style={{ marginBottom: '15px', width: '90%' }}>
                      <label>Ratings and Remarks</label>
                      {props.remarks.length>0 ? <div className='flexCol' style={{alignItems: 'flex-start', marginTop: 20}}>
                        {props.remarks && props.remarks.map((el)=>(
                          <div style={{marginBottom: 10}}><div>{el.username}</div> <div> <StarRating rating={el.rating}></StarRating> </div><div> {el.remarks}</div></div>
                        ))}
                      </div> : <p>No ratings and remarks</p>}
                      </FormControl>
                   </div>
       
      </div>
      <div style={{ width: '100%', height: '95%', backgroundColor: 'white', boxSizing: 'border-box', justifyContent: 'flex-start' }} className="flexCol">
        <iframe
          style={{
            width: '100%',
            height: '100%',
            transform: 'scale(1)',
            transformOrigin: '0 0', // Set the transform origin to the top-left corner
          }}
          width={'100%'}
          height={'100%'}
          src={
            props.url.includes('.pdf')
              ? `https://mozilla.github.io/pdf.js/web/viewer.html?file=${props.url}`
              : `https://view.officeapps.live.com/op/view.aspx?src=${props.url}`
          }
        ></iframe>
      </div>
    </div>
  );
}