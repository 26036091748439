import Navbar from '../../components/Navbar/Navbar';
import StatsPane from '../../components/StatsPane/StatsPane';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import Avatar from '@mui/joy/Avatar';
import Typography from '@mui/joy/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



import ViewPort from '../../components/ViewPort/ViewPort';
import './Home.css';
import HomeAction from '../../components/HomeActions/HomeAction';

import { faBriefcase, faTools, faCalendarTimes, faNetworkWired, faVideoCamera, faUserGroup, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
import { getAccount } from '../../services/toolsService';
import Stats from './Stats';
import LeftBigPane from './LeftBigPane';
import RightBigPane from './RightBigPane';



function Home() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [profilePic, setProfilePic] = useState('');
  useEffect(()=>{
    getAccount().then((res)=>{
      let data = res.data;
      setFirstName(data?.firstName);
      setLastName(data?.lastName);
     setRole(data?.role);
      setProfilePic(data?.profilePic);
     
    }).catch(()=>{})
  },[])
  return (
   <div className='home'>
   <div>
      <Stats></Stats>
      </div>
      <div className='flexRow' style={{justifyContent: 'space-between'}}>
<LeftBigPane></LeftBigPane><RightBigPane></RightBigPane>
      </div>
   </div>
  );
}

export default Home;
