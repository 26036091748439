import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import Checkbox from '@mui/joy/Checkbox'; // Import Checkbox component

export default function BioCard(props) {
  return (
    <Card
      sx={{
        width: 320,
        maxWidth: '100%',
        boxShadow: 'lg',
        position: 'relative', // Set position relative for absolute positioning of Checkbox
      }}
    >
      <Checkbox
        color="primary"
        value={props.checked}
        onChange={(e) => {if(e.target.checked){props.onSelect(props.id)}
       
    else{props.unSelect(props.id)}}}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          margin: '8px', // Adjust margin as needed
        }}
      />
      <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
        <Avatar src="/static/images/avatar/1.jpg" sx={{ '--Avatar-size': '4rem' }} />
        <Typography level="title-lg" style={{ marginTop: 10 }}>
          {props.user.fullName}
        </Typography>
      </CardContent>
      <CardOverflow sx={{ bgcolor: 'background.level1' }}>
        <CardActions buttonFlex="1">
          <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
            <Button color="success">Connected</Button>
          </ButtonGroup>
        </CardActions>
      </CardOverflow>
    </Card>
  );
}
