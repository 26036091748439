import { MainContainer, Container, Input, ButtonContainer, Button } from '../../Elements';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { login } from '../../services/userService';
import myImage from '../../assets/images/login.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { userLogin, userLogout } from '../../store/authSlice';
import { createUser } from '../../services/userService';
import { setLoading } from '../../store/appSlice';

const inputElements = [
  {
    placeholder: 'First Name',
    name: 'firstName',
    type: 'text',
  },
  {
    placeholder: 'Last Name',
    name: 'lastName',
    type: 'text',
  },
  {
    placeholder: 'Email',
    name: 'email',
    type: 'email',
  },
  {
    placeholder: 'Password',
    name: 'password',
    type: 'password',
  },
];

const bgImg1 = 'https://static.vecteezy.com/system/resources/previews/002/007/000/original/work-from-home-concept-young-man-freelancers-working-on-laptops-at-home-people-at-home-in-quarantine-pink-background-back-view-staying-at-home-illustration-flat-design-character-vector.jpg';

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const job = queryParams.get('job');
  const org = queryParams.get('org');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

 

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (user) {
  //     // Redirect to home page if user is already logged in
  //     dispatch(userLogout());
  //   }
  // }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
        case 'firstName':
        setFirstName(value);
        break;
        case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };
  
  
  const onSubmit = () => {
    dispatch(setLoading(true));
    createUser({ firstName, lastName,email, password, job, org })
      .then(() => {
        login({ email, password, job, org })
    .then((res) => {
      dispatch(setLoading(false));
      dispatch(userLogin({ user: res.data.user, token: res.data.token }));
     navigate(`/${res.data.user.type==0 ? 'home' :  'jobs'}${location.search}`);
     navigate(`/${res.data.user.type==0 ? 'home' :  'jobs'}${location.search}`);
     navigate(`/${res.data.user.type==0 ? 'home' :  'jobs'}${location.search}`);
     navigate(`/${res.data.user.type==0 ? 'home' :  'jobs'}${location.search}`);
    })
    .catch(() => {
      // Do something on error
    });
      })
      .catch((err) => {
        console.log(err)
        // Do something on error
      });
    
  };

  return (
    <MainContainer>
      <Container style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, backgroundImage: `url(${myImage})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} />
      <Container style={{ borderTopRightRadius: 40, borderBottomRightRadius: 40 }}>
        <span style={{ fontSize: '2.5em', fontWeight: 'bold', color: 'rgba(37,40,73,1)' }}>Get Onboard,</span><br></br>
        {inputElements.map((el, i) => (
          <Input
            onChange={handleChange}
            name={el.name}
            key={i}
            placeholder={el.placeholder}
            type={el.type}
          />
        ))}
        <ButtonContainer>
          <Button style={{ backgroundColor: 'rgba(37,40,73,1)', height: 50 }} onClick={onSubmit}>Signup</Button>
         
        </ButtonContainer>
        <div>
        <span style={{cursor: 'pointer', marginTop: 5}}onClick={()=>{navigate(`/login${location.search}`)}}>Login if already a member</span>
        </div>
      </Container>
    </MainContainer>
  );
};

export default Signup;
