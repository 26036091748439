

import Navbar from '../../components/Navbar/Navbar';
import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import customTheme from './theme';
import Header from '../../components/Account/Header';
import MyProfile from '../../components/Account/MyProfile';
import CableIcon from '@mui/icons-material/Cable';
import Feed from '../../components/feed/Feed';
import Card, { CardProps } from '@mui/joy/Card';
import Playlists from '../../components/Playlists/Playlists';
import { Button, Input, Typography } from '@mui/joy';
import PlaylistPreview from '../../components/PlaylistPreview/PlaylistPreview';
import TabContext from '../../components/ViewPort/TabContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import store from '../../store/store';
import { getUserDetails } from '../../services/toolsService';
import { AccountBalance, Favorite, Folder, FolderShared } from '@mui/icons-material';

const useEnhancedEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export default function Account() {
  const { tabs, setTabs } = React.useContext(TabContext);
  const [username, setUsername] = React.useState(store.getState().auth.user.username);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [profilePic, setProfilePic] = React.useState('');
  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof window.feather !== 'undefined') {
      // @ts-ignore
      window.feather.replace();
    }
    setTabs([{icon: <Favorite style={{fontSize:'30px',color:"grey",position:'relative',  opacity:'0.7'}}/>, label: 'Connections', to: '/connections'},
  {icon: <AccountCircleIcon style={{fontSize:'30px',color:"grey",position:'relative',  opacity:'0.7'}}/>, label: 'Account', to: '/account'},
  {icon: <AccountBalance style={{fontSize:'30px',color:"grey",position:'relative',  opacity:'0.7'}}/>, label: 'Organizations', to: '/profile'},
  {icon: <Folder style={{fontSize:'30px',color:"grey",position:'relative',  opacity:'0.7'}}/>, label: 'Folders', to: '/playlists'}
  ])
   console.log(store.getState().auth.user);
   getUserDetails().then((res) => {
    setName(res.data.name);
    setEmail(res.data.email);
    setProfilePic(res.data.profilePic);
   }).catch((err) => {});
  }, []);

  return (
    <div className="flexRow" style={{flex: 1,overflow: 'hidden', boxSizing: 'border-box', backgroundColor: 'white'}}>
      <Card style={{display: 'flex', flex: 1, width: '40vw', marginLeft: 40, marginRight: 40}}>
    <Typography>Basic Info</Typography>
    <Input placeholder="profile" style={{marginTop: 20}} value={username} disabled={true}></Input>
    <Input placeholder="email" value={email}></Input>
    <Input placeholder="full name" value={name}></Input>
   </Card>
   
   <Feed style={{maxWidth: '50vw'}}></Feed>
   
   <div style={{display: 'flex',flexDirection: 'column', width: '10vw', marginLeft: 20,marginRight: 40}}>
   <Typography>Connections</Typography>
   <PlaylistPreview></PlaylistPreview>
      
   
   </div>

      {/* <CssBaseline />
     
        <Box
          component="main"
          className="MainContent"
          sx={(theme) => ({
            '--main-paddingTop': {
              xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
              md: '32px',
            },
            px: {
              xs: 2,
              md: 3,
            },
            pt: 'var(--main-paddingTop)',
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
           
            gap: 1,
            overflow: 'auto',
          })}
        >
          <MyProfile/>
        </Box> */}
 
    </div>
  );
}
