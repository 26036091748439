import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { Textarea } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { createIntro } from '../../services/toolsService';

export default function InputModal(props) {
   const [name, setName] = React.useState('');
   const [org, setOrg] = React.useState('personal');
   const [desc, setDesc] = React.useState('');
   React.useEffect(()=>{
    if(props.view || props.edit){
      setName(props.selected.name);
      setDesc(props.selected.description)
      setOrg(props.selected.organization);
    }
   },[props])
   const handleChange = (event, newValue) => {
    //alert(`You chose "${newValue}"`);
    setOrg(newValue)
  };
  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: '60%'}}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Add new intro
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
        An intro allows the recruiter to collect an short video from the candidate
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              props.setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input readOnly={props.view} value={name} onChange={(e)=>{
                
                  setName(e.target.value)
                
                  }} autoFocus required />
              </FormControl>
              <FormControl>
                <FormLabel>Under</FormLabel>
                <Select value={org} disabled={props.view} defaultValue="personal" onChange={handleChange}>
      <Option value="personal">Personal</Option>
      <Option value="xilligence">Xilligence</Option>
    </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea readOnly={props.view} onChange={(e)=>{setDesc(e.target.value)}} value={desc} minRows={4} />
              </FormControl>
              { !props.view ? <Button type="submit" onClick={()=>{
                let obj = {
                  name,
                  organization: org,
                  description: desc
                }
                createIntro(obj).then((res)=>{
                  console.log(res);
                  props.setOpen(false);

                }).catch(()=>{})
              }}>{props.edit ? 'Update' : 'Submit'}</Button> : ''}
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}