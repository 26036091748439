
import { useSelector } from 'react-redux';
import Navbar from '../../components/Navbar/Navbar';
import './Calendar.css';


import Iframe from 'react-iframe'
import SideNav from '../../components/ViewPort/SideNav';




function Calendar() {
  const token = useSelector((state) => state.auth.token);
  return (
    <div className="flexCol" style={{justifyContent: 'space-between',width: window.innerWidth,height: window.innerHeight}}>
       <SideNav></SideNav>
    <Iframe url={`https://www.interviewable.com/calendar?token=${token}`}
    width="80%"
    height="84%"
    styles={{marginLeft: 90,border: 'none'}}
    id=""
    className=""
    display="block"
    position="relative"/>
    </div>
  );
}

export default Calendar;
