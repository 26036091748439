import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc';
import Controls from './Controls';
import { Divider, Input, Textarea } from '@mui/joy';
import VideoContainer from './VideoContainer';
import React, { useRef, useState, useEffect } from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { chunkUpload } from '../../services/uploadService';
import { createPreInterview, getPreInterviews } from '../../services/toolsService';



export default function PreInterview(props){


  const recorder = useRef(null);
  let urlList = [];
  const [isConfirm, setIsConfirm] = useState(false);
  const [introConfirm, setIntroConfirm] = useState(false);
    const recorderRef = useRef(null);
    const [interviewName, setInterviewName] = useState('');
    const [interviewDesc, setInterviewDesc] = useState('');
    const [org, setOrg] = useState('personal');
  const [previewIndex, setPreViewIndex] = React.useState(0);
  const [blobs, setBlobs] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [interviewState, setInterviewState] = useState('not started');
  // not started , recording, paused, stopped, back mode
  const [videoStream, setVideoStream] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [videoList, setVideoList] = useState([]);
 
  const [recorderState, setRecorderState] = useState('stopped');
  const handleRecording = async () => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
         video: {
           width: 720,
           height: 480,
           frameRate: 24,
         },
         audio: true,
       });
      
   
   recorder.current.srcObject = mediaStream;
   recorderRef.current = new RecordRTC(mediaStream, { type: 'video' });
   setVideoStream(mediaStream);
   recorderRef.current.startRecording();
   setInterviewState('recording');
   setCurrentVideoIndex(0);
 };

 const handleStop = () => {
    let count = videoCount;
    count+=1;
    setVideoCount(count);
    recorderRef.current.stopRecording((blobUrl) => {
      // let blbs = [...blobs];
      let currentBlob = recorderRef.current.getBlob();
  
      uploadFile(currentBlob, count-1)
      .then((res)=>{
       
        urlList.push(res.data);
      
        setBlobs(prevBlobs => prevBlobs.concat([res.data]));
      
      }).catch(()=>{

      })
      let list = videoList;
      list.push(blobUrl);
      setVideoList(list);
      
      //console.log(list);
     videoStream.getTracks().forEach(element => {
      element.stop();
    });
    setVideoStream(null);
    setInterviewState('stopped')
         
    });
  
   }


   
   const pause= ()=>{
    recorderRef.current.pauseRecording();
    setInterviewState('paused');
   }
   const resume=()=>{
    recorderRef.current.resumeRecording();
    setInterviewState('recording');
   }
   const handleNext = () =>{
    if(interviewState!=='stopped'){
    handleStop();
    }
    handleRecording();
    let index = currentVideoIndex;
    index+=1;
    setCurrentVideoIndex(index);
   }
   let config = {
    onUploadProgress: progressEvent => {
      //console.log(progressEvent)
      
    }
  }
   const uploadFile= (myBlob,index) => {
    myBlob.name=`${+new Date}_${index}.mp4`;
    //var file = new File([myBlob], "mytest.mp4", {type: 'video/mp4', lastModified: Date.now()});
    var file = myBlob;
    return chunkUpload(file,config,{token: 'B37vlz4qQea8GMr7h8IYJOSlffK8FCkOY8FSgK7kUi/pM+UQ0nPsyxp+p1gSubrnZqEeiERDdK8xbTVheEakXQ=='})
                    // .then(res => {
                    //     console.log(res.data)
                        
                    // })
                    // .catch((err) => {
                    //   if(err=="You have exceeded your storage quota. Please Upgrade."){
                    //     alert(err);
                    //     this.setState({ ...this.state, showModal: false });
                    //   }
                    // });
    
  }
    return ( 
    <Sheet sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '1% 5% 1% 5%'}}>

{
isConfirm ? <><Input type='text' value={interviewName} onChange={(e)=>{setInterviewName(e.target.value)}}></Input>
<Textarea value={interviewDesc} onChange={(e)=>{setInterviewDesc(e.target.value)}}></Textarea>
<Select value={org}  defaultValue="personal" onChange={(e)=>{setOrg(e)}}>
      <Option value="personal">Personal</Option>
      <Option value="xilligence">Xilligence</Option>
    </Select>
</> : <VideoContainer index={currentVideoIndex} previewIndex={previewIndex} setPreViewIndex={setPreViewIndex} videoCount= {videoCount} blobs={blobs} list={videoList} state={interviewState} recorder={recorder}></VideoContainer>
}
<Controls mode={props.mode} onConfirm={()=>{props.onConfirm(blobs)}} isConfirm={isConfirm} introConfirm = {introConfirm} complete={()=>{
  let data = {
    name: interviewName,
    description: interviewDesc,
    urls: blobs
  }
  console.log(data);
  createPreInterview(data).then((res)=>{
    console.log(res.data);
  }).catch(()=>{})
}} state={interviewState} previewIndex={previewIndex} setPreViewIndex={setPreViewIndex} confirm={()=>{
 
  if(props.mode != "intro"){
    setIsConfirm(true);
  } else {
    setIntroConfirm(true)
    
  }
 
}} record={handleRecording} next={()=>{handleNext();}} stop={()=>{handleStop();  setInterviewState('preview');

}} pause={pause} resume={resume}></Controls>
    </Sheet>)
}