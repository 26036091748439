import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Navbar from '../../components/Navbar/Navbar';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import customTheme from './theme';
import MyMessages from '../../components/Messages/MyMessages';

const useEnhancedEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export default function Messages() {
//   const status = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof window.feather !== 'undefined') {
      // @ts-ignore
      window.feather.replace();
    }
  }, []);

  return (
    <div style={{height: '100%', overflow: 'hidden', boxSizing: 'border-box'}}>
    <CssVarsProvider disableTransitionOnChange theme={customTheme}>
       
      <GlobalStyles
        styles={{
          '[data-feather], .feather': {
            color: 'var(--Icon-color)',
            margin: 'var(--Icon-margin)',
            fontSize: 'var(--Icon-fontSize, 20px)',
            width: '1em',
            height: '1em',
          },
        }}
      />
      {/* <CssBaseline /> */}
     
      <Box sx={{ display: 'flex', height: '100%'}}>
        {/* <Header />
        <Sidebar /> */}
        
        <Box component="main" className="MainContent" flex={1} style={{paddingBottom: '100px' }}>
          <MyMessages />
        </Box>
      </Box>
    </CssVarsProvider>
    </div>
  );
}
