import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import CenterLayout from './CenterLayout';
import ScrollCard from './ScrollCard';
import ScrollCard2 from './ScrollCard2';
import ScrollCard3 from './ScrollCard3';

export default function Privacy() {
  return (
    <CenterLayout>
    <div className='flexCol' style={{width: '100%'}}>
  {/* <Typography color="primary" fontSize="lg" fontWeight="lg">
    The power to do more
  </Typography> */}
  <Typography
    level="h1"
    fontWeight="xl"
    fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
  >
  Privacy Policy
  </Typography>
 <div className='flexRow' style={{paddingTop: 20,width: '60%',justifyContent: 'space-around'}}>
  <Typography style={{marginTop: 20}}fontSize="lg" textColor="text.secondary" lineHeight="lg">
  Your privacy is important to us. Interviewable collects and processes personal information in accordance with applicable privacy laws. By using our Hiring Platform, you consent to the collection, use, and disclosure of your personal information as outlined in this policy. We may collect information such as your name, contact details, and employment history to facilitate job matches. Your data will be used for platform functionality, communication, and improvement. We do not sell your information to third parties.
  </Typography>
    {/* <ScrollCard></ScrollCard> */}
 </div>
 
 

  </div>
</CenterLayout>
  );
}