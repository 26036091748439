

import './WorkFlows.css';
import ListItems from '../../../components/List/List';
import OrderTable from '../../../components/List/OrderTable';
import WorkFlowsTable from './WorkFlowsTable';




function WorkFlowsList() {
  return (
    <div className='intros_container'>
    <ListItems table={<WorkFlowsTable></WorkFlowsTable>}></ListItems>
   </div>
  );
}

export default WorkFlowsList;
