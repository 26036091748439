import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Organizations from '../Account/Organizations';
import Candidates from '../../pages/Candidates/Candidates';
import { getAppliedCandidates, getAppliedCandidatesByJob } from '../../services/toolsService';
import JobATS from '../../pages/ATS/JobATS';


export default function FullScreenModal(props) {
  const [layout, setLayout] = React.useState('fullscreen');
  const [isLoading, setIsLoading] = React.useState(false);
  const [candidates, setCandidates] = React.useState([]);
  React.useEffect(()=>{
if(props.open){
    setIsLoading(true);
    getAppliedCandidatesByJob({id: props.id}).then((res)=>{
      setIsLoading(false);
      let tempCandidates = res.data;
      // tempCandidates.forEach((c,i)=>{
      //  tempCandidates[i].exp = (c.exp == '' || c.exp == null) ? [] : JSON.parse(c.exp);
      // })
      // console.log(tempCandidates)
setCandidates(tempCandidates)

    }).catch(()=>{})
}
  },[props])
  return (
    <React.Fragment>
      <Stack direction="row" spacing={1}>
       
      </Stack>
      <Modal open={props.open} onClose={() => props.setOpen(false)} >
        <ModalDialog layout={layout}>
          <ModalClose />
        <div style={{maxWidth: '95%', overflowY: 'scroll'}}>
          <JobATS rows = {candidates}></JobATS>
        {/* <Candidates candidates={candidates}></Candidates> */}
        </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}