import React, { useEffect, useState } from 'react';
import Card from '@mui/joy/Card';
import { Typography } from '@mui/joy';
import { GetConnections } from '../../services/toolsService';

const PlaylistPreview = () => {
  const [users, setUsers] = useState([]); // Add a state to store the users
  useEffect(() => {
    GetConnections().then((res) => {
      console.log(res.data);
      setUsers(res.data);
    }).catch((err) => {});
  },[]);
  // Define the number of circles you want to render
  const circles = new Array(5).fill(null); // This will create 5 circles for example

  return (
    <Card  style={styles.card}>
    {/* <Typography style={{width: '100%'}}variant="h6">Managers</Typography> */}
      {users.map((user, index) => (
        <div className='flexRow' key={index} style={styles.circle}>
          <h4>{user.fullName.trim().split(' ').length>1 ? `${user.fullName.trim().split(' ')[0][0]} ${user.fullName.trim().split(' ')[1][0]}` : `${user.fullName.trim().split(' ')[0][0]} ${user.fullName.trim().split(' ')[0][1]}`}</h4>
        </div>
      ))}
      <div className='flexRow' style={styles.circle}>
          <h6>...</h6>
    </div>
    </Card>
  );
};

const styles = {
  card: {
    border: 'none',
    display: 'flex',
    flexDirection: 'row', // Explicitly set the flex direction to row
    flexWrap: 'wrap', // Allow the items to wrap
    gap: '10px', // Adjust the space between circles as needed
    padding: '10px', // Add padding inside the Card
    width: '100%', // Set a fixed width or use '100%' for full width
    height: 'auto', // Adjust height automatically based on content
    justifyContent: 'flex-start', // Align items to the start of the container
    alignItems: 'flex-start', // Align items to the start of the container vertically
  },
  circle: {
    width: 60,
    height: 60,
    backgroundColor: 'pink',
    borderRadius: '50%', // 50% will make it a perfect circle
  },
};

export default PlaylistPreview;
