import * as React from 'react';

import Box from '@mui/joy/Box';

import Chip, { chipClasses } from '@mui/joy/Chip';

import Sheet from '@mui/joy/Sheet';

import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import { useSelector } from 'react-redux';
import UserCard from '../../pages/CandidateView/UserCard';


export default function AccountsTab(props) {
  const type = useSelector((state) => state.auth.user.type);
  return (
    <Sheet
      sx={{
        bgcolor: 'background.body1',
        flex: 1,
        maxWidth: 1200,
        boxSizing: 'border-box',
        width: '100%',
        paddingBottom: '50px',
        mx: 'auto',
      }}
    >
      {/* <UserCard></UserCard> */}
      {/* <Typography level="h1" fontSize="xl2" sx={{ mb: 1 }}>
        My profile
      </Typography> */}
      <Tabs
        defaultValue={2}
        sx={{
          bgcolor: 'background.body',
          '--Tab-height': '48px',
        }}
      >
        <Box
          sx={{
            '--_shadow-height': '16px',
            height: 0,
            position: 'sticky',
            top: 'calc(var(--Tab-height) - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
            zIndex: 1,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'relative',
              zIndex: 1,
              height: 'var(--_shadow-height)',
              background:
                'radial-gradient(closest-side, rgba(0 0 0 / 0.12), transparent 100%)',
            },
          }}
        />
        <TabList
        
          variant="plain"
          size="lg"
          sx={(theme) => ({
            '--List-padding': '0px',
            '--ListItem-minHeight': 'var(--Tab-height)',
            '--Chip-minHeight': '20px',
            '--_TabList-bg': theme.vars.palette.background.body,
            backgroundColor: 'var(--_TabList-bg)',
            boxShadow: `inset 0 -1px 0 0 ${theme.vars.palette.divider}`,
            position: 'sticky',
            top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
            zIndex: 10,
            width: '100%',
            overflow: 'auto hidden',
            alignSelf: 'flex-start',
            borderRadius: 0,
            scrollSnapType: 'inline',
            '&::after': {
              pointerEvents: 'none',
              display: { xs: 'block', sm: 'none' },
              content: '""',
              position: 'sticky',
              top: 0,
              width: 40,
              flex: 'none',
              zIndex: 1,
              right: 0,
              borderBottom: '1px solid transparent',
              background: `linear-gradient(to left, var(--_TabList-bg), rgb(0 0 0 / 0))`,
              backgroundClip: 'content-box',
            },
            '&::-webkit-scrollbar': {
              width: 0,
              display: 'none',
            },
            [`& .${tabClasses.root}`]: {
              '&:first-of-type': {
                ml: 'calc(-1 * var(--ListItem-paddingX))',
              },
              scrollSnapAlign: 'start',
              bgcolor: 'transparent',
              boxShadow: 'none',
              flex: 'none',
              '&:hover': {
                bgcolor: 'transparent',
              },
              [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  zIndex: 1,
                  bottom: 0,
                  left: 'var(--ListItem-paddingLeft)',
                  right: 'var(--ListItem-paddingRight)',
                  height: '2px',
                  bgcolor: 'primary.500',
                },
                [`& .${chipClasses.root}`]: theme.variants.solid.primary,
              },
            },
          })}
        >
          {/* <Tab value={0}>{`My ${type == 0 ? 'Recruiter Profile' : 'Resume'}`}</Tab> */}
          {/* { type == 1 ?<Tab value={1}>Pre Interviews</Tab> : <Tab value={1}>Intro Video</Tab>}. */}
          
          { type == 0 && props?.viewMode != true ? <Tab value={2}>
          Organizations{' '}
            {/* <Chip size="sm" variant="soft" color="neutral" sx={{ ml: 1 }}>
              2
            </Chip> */}
          </Tab> : <></>}
         
          {/* <Tab value={3}>Links & Portfolio</Tab> */}
          
          {/* <Tab value={4}>Cover Letter</Tab> */}
         
        </TabList>
       {props.tab}
      </Tabs>
    </Sheet>
  );
}
