import React from "react";
import "./Tag.css";
const Tag = (props) => {
  return (
    // <div className='tag'>
    props?.tagName.trim().length>1? <span className="tag" style={{ ...props.style,backgroundColor: `${props?.color}` }}>
      {props?.tagName}
    </span>:<></>
    // </div>
  );
};

export default Tag;
