import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { CreateATSFolder, getOrganizations } from '../../services/toolsService';

export default function CreateFolder(props) {
  const [open, setOpen] = React.useState(false);
  const [org, setOrg] = React.useState(null);
  const [orgs, setOrgs] = React.useState([]);
  const [name, setName] = React.useState('');
  const [desc, setDesc] = React.useState('');
  React.useEffect(()=>{
    getOrganizations().then((res)=>{
      setOrgs(res.data);
    }).catch((err)=>{})
  },[])
  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog>
          <Typography><h4>Create new folder</h4></Typography>
        
          <form
            onSubmit={(event) => {
              event.preventDefault();
              props.setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input autoFocus required onChange={(e)=>{setName(e.target.value)}}/>
              </FormControl>
              <FormControl>
                <FormLabel>Organisation</FormLabel>
                <Select defaultValue={org} onChange={(e,v)=>{setOrg(v);}}>
                  {
                    orgs.map((org, index)=>(
                      <Option value={org.id}>{org.name}</Option>
                    ))
                  }
                 
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input required onChange={(e)=>{setDesc(e.target.value)}}/>
              </FormControl>
              <Button type="submit" onClick={()=>{
                let obj = {
                  name,
                  description: desc,
                  org: org
                }
                CreateATSFolder(obj).then((res)=>{
                  props.setOpen(false);
                }).catch((err)=>{})
              }}>Create</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}