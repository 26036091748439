
import './HomeAction.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function HomeAction(props) {
  return (
    <div onClick={()=>{props.nav()}} className='action_container'>
   <div style={{width: (window.innerWidth/16), height: (window.innerWidth/16) , backgroundColor: `${props.color}`}} className="home_action">
   <FontAwesomeIcon size='2x' icon={props.icon} />
   </div>
   <div className='action_title'>
    {props.title}
   </div>
   </div>
  );
}

export default HomeAction;
