import { PropaneSharp } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';

const Timer = (props) => {
 

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.seconds < 300 && props.uploadResult.length == 0) {
        props.setSeconds(props.seconds + 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount

  }, [props.seconds]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      <p style={{color: 'red'}}>{props.uploadResult.length>0 ? `Completed in ${formatTime(props.seconds)}`: formatTime(props.seconds)}</p>
    </div>
  );
};

export default Timer;
