import React, { useState } from 'react';
import { Tab, Tabs, Typography, Box } from '@mui/material';
import Iframe from 'react-iframe';
import ResumeView from './ResumeViewScreen';

const ApplicantResume = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <ResumeView id={props.id} remarks={props.remarks} setRemarks={props.setRemarks} close={props.close} data={props.applicantData} url = {props.url}></ResumeView>
    // <div style={{ maxWidth: '70%', maxHeight: window.innerHeight - 120, overflow: 'hidden' }}>
    //   <Tabs value={activeTab} onChange={handleTabChange} centered style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
    //     <Tab label="Extracted" />
    //     <Tab label="Original" />
    //   </Tabs>

    //   <div style={{ overflowY: 'auto', width: '100%', height: 'calc(100% - 48px)' }}>
    //     <TabPanel value={activeTab} index={0}>
    //     <div style={{width: 80/100*window.innerWidth}}>
    //     <h2>{props.applicantData.name}</h2>
    //   <p>Email: {props.applicantData.email}</p>
    //   <p>Phone: {props.applicantData.phone}</p>

    //   <section>
    //     <h3>Education</h3>
    //     <ul>
    //       {props.applicantData.education.map((edu, index) => (
    //         <li key={index}>
    //           <p>{edu.name}</p>
    //           <p>{edu.institution}</p>
    //           <p>{edu.date}</p>
    //         </li>
    //       ))}
    //     </ul>
    //   </section>

    //   <section>
    //     <h3>Experience</h3>
    //     <ul>
    //       {props.applicantData.experience.map((exp, index) => (
    //         <li key={index}>
    //           <p>{exp.name}</p>
    //           <p>{exp.place}</p>
    //           <p>{exp.date}</p>
    //           <p>{exp.responsibilities}</p>
    //         </li>
    //       ))}
    //     </ul>
    //   </section>

    //   <section>
    //     <h3>Skills</h3>
    //     <ul>
    //       {props.applicantData.skills.map((skill, index) => (
    //         <li key={index}>
    //           <p>{skill}</p>
    //         </li>
    //       ))}
    //     </ul>
    //   </section>

    //   <section>
    //     <h3>Hobbies</h3>
    //     <ul>
    //       {props.applicantData.hobbies.map((hobby, index) => (
    //         <li key={index}>
    //           <p>{hobby}</p>
    //         </li>
    //       ))}
    //     </ul>
    //   </section>

    //   <section>
    //     <h3>Languages</h3>
    //     <ul>
    //       {props.applicantData.languages.map((language, index) => (
    //         <li key={index}>
    //           <p>{language}</p>
    //         </li>
    //       ))}
    //     </ul>
    //   </section>
    //   </div>
    //     </TabPanel>

    //     <TabPanel value={activeTab} index={1}>
    //       <div style={{width: window.innerWidth, height: window.innerHeight}}>
         
    //       <Iframe
    //         url={props.url.includes('.pdf') ? `https://mozilla.github.io/pdf.js/web/viewer.html?file=${props.url}` : `https://view.officeapps.live.com/op/view.aspx?src=${props.url}`}
    //         width="100%"
    //         height="100%"
    //         styles={{ border: 'none' }}
    //         id=""
    //         className={props.url.includes('.docx')? 'docx' : props.url.includes('.doc') ? 'doc' : 'pdf'}
    //         display="block"
    //         position="relative"
    //       />
    //       </div>
    //     </TabPanel>
    //   </div>
    // </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default ApplicantResume;

