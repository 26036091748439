import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    user: null,
    token: null,
  },
  reducers: {
    userLogin: (state, action) => {
      console.log(action.payload)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.isLoggedIn = true;
      state.user = action.payload?.user;
      state.token = action.payload?.token;
    },
    userLogout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { userLogin, userLogout } = authSlice.actions

export default authSlice.reducer