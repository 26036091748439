import * as React from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { getUserOrganizations } from '../../services/toolsService';

export default function OrgSelector(props) {
    const [orgs, setOrgs] = React.useState([{id:'personal', name:'Personal'}]);
    React.useEffect(()=>{
        getUserOrganizations().then((res)=>{

            
            setOrgs(orgs.concat(res.data));
        
           }).catch(()=>{})
    },[])
  return (  <Select onChange={(e,value)=>{props.orgChange(value)}} style={{marginTop: 5}} value={props.org}>
    {
        orgs.map((x)=>(
            <Option value={x.id}>{x.name}</Option>
        ))
    }

</Select>)
}