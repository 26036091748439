
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './StatsPane.css';
import { faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';

function StatsPane(props) {
  return (
   <div style={{width: (window.innerWidth/6), height: (window.innerWidth/6) , border: `1px solid gainsboro`}} className="pane">
    <div className='top-half'>
        <div className='left'>
            {props.title}
        </div>
        <div className='right'>
          {/* <img src={props.icon}></img> */}
          <FontAwesomeIcon size='2x' icon={props.icon} />
          </div>
    </div>
    <div style={{color:  `rgb(104, 96, 96)`}} className='bottom-half'>
      {props.count}
    </div>
   </div>
  );
}

export default StatsPane;
