
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

export default function StatCard(props){
    return(
        <Card  style={{backgroundColor: '#e6b87c', height: 150, width: 150}} variant="solid">
        <CardContent>
            <div className='flexCol' style={{height: '100%', width: '100%'}}>
            <div className='flexRow' style={{height: '10%'}}>
            <Typography fontSize="md">{props.name}</Typography>
            </div>
            <div className='flexRow' style={{height: '90%'}}>
            <Typography level="h1" fontSize="30" >10</Typography>
            </div>
         </div>
        </CardContent>
      </Card>
    )
}