import { Button, Input, Textarea } from '@mui/joy';
import React, { useEffect } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { PropaneSharp } from '@mui/icons-material';
import { CreateATSFolder,GetATSFolders } from '../../services/toolsService';

const ATSFolders = (props) => {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [desc, setDesc] = React.useState('');
    const [folders, setFolders] = React.useState([]);
    const [selectedFolder, setSelectedFolder] = React.useState(null);
    // Component logic goes here

    const GetFolders = () => {
      setTimeout(() => {
        GetATSFolders(props.org).then((res)=>{
           
            setFolders(res.data);
            props.setFolders(res.data);
            setOpen(false);
            console.log(res.data);
        }).catch((err)=>{});
      }, 1000);
    }

    useEffect(()=>{
        GetFolders();
    },[])
    return (
        // JSX code goes here
        <div className='flexRow'>
              <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
           Add a new folder
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
           You can classify applicants into folders for later use.
          </Typography>
          <div className='flexCol'>
            <Input onChange={(e)=>{
                setName(e.target.value);
            }} placeholder='Folder Name' style={{marginTop: 20}}/> 
            <Textarea style={{marginTop: 20}} placeholder={'A small description about your folder'} onChange={(e)=>{setDesc(e.target.value)}}>

            </Textarea>
            <Button onClick={()=>{
              let obj = {
                name,
                description: desc,
                org: props.org
              }
              if(folders.filter((x)=>(x.name == name)).length > 0) { alert('Folder with same name already exists'); 
            } else {
              CreateATSFolder(obj).then((res)=>{
                GetFolders();
              }).catch((err)=>{})
            }

            }} variant={'outlined'} style={{marginTop: 20,marginLeft: 20}}>Create Folder</Button>
          </div>
        </Sheet>
      </Modal>
           {folders.map((x)=>(<Button style={{marginRight:10,backgroundColor: props?.selectedFolder?.id == x?.id ? '#addbff' : 'white'}} onClick={()=>{
                if(props?.selectedFolder?.id == x?.id){
                    setSelectedFolder(null);
                    props?.setSelectedFolder(null);
                    props.getFolderApplicants(null);
                    return;
                }
                setSelectedFolder(x);
                props.getFolderApplicants(x?.id);
                props?.setSelectedFolder(x);
           }} color= 'primary' variant="soft">{x.name}</Button>))}<Button variant={'outlined'} style={{marginLeft: 20}} onClick={()=>{setOpen(true)}}>New Folder</Button>
        </div>
    );
};

export default ATSFolders;
