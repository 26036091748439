import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';



export default function Controls(props){
    return ( 
    <ButtonGroup sx={{width: '100%',marginTop: '20px', display:'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} spacing="0.5rem" aria-label="spacing button group">
      {props.state == 'not started' ? <Button onClick={()=>{props.record()}}>Record</Button> :
      props.isConfirm ?  <Button onClick={()=>{props.complete()}}>Complete</Button> :
      props.introConfirm ?   <Button onClick={()=>{props.onConfirm()}}>Next Step</Button> 
      :
      <>
      
      {props.state !== 'stopped' ? <><Button onClick={()=>{props.next()}}>Next</Button>
      <Button onClick={()=>{props.stop()}}>Stop</Button>
      </>:<>
      {props.mode != "intro" ?<Button onClick={()=>{
       props.setPreViewIndex(props.previewIndex-1);
      }}>Scroll Back</Button> : <></>}
       {props.mode != "intro" ? <Button onClick={()=>{
        props.setPreViewIndex(props.previewIndex+1);
      }}>Scroll Forward</Button> : <></>}
       <Button onClick={()=>{
       
      }}>Redo</Button>
      {props.mode != "intro" ? <Button onClick={()=>{
       
      }}>Delete</Button> : <></>}
      <Button onClick={()=>{
        if(props.mode == 'intro'){
          props.onConfirm()
        } else {
        props.confirm()
        }
      }}>Confirm</Button>
      </>
      }
      </>}
      
    </ButtonGroup>)
}