import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers';

const recentSaveDate = localStorage.getItem('recentSave');
if(recentSaveDate !=null){
  try{
  let recent = JSON.parse(recentSaveDate);
  var oneHourLater = new Date(recent.getTime() + 60 * 60 * 1000); // 60 minutes * 60 seconds * 1000 milliseconds

// Set up a setTimeout to trigger after one hour
var timeoutId = setTimeout(function() {
    // Code to execute after one hour
    alert("Session expired, please login again");
    window.location.href = 'https://www.interviewable.com/#/login';
}, oneHourLater.getTime() - recent.getTime());
  } catch{}
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
    <GoogleOAuthProvider clientId="347227022176-q1q4hphs3kj2qt6gg7e1lkvmph4uc63d.apps.googleusercontent.com">
     <Provider store={store}>
    <App style={{minHeight: '100%', width: '100%'}}/>
    </Provider>
    </GoogleOAuthProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
