// ProtectedRoute.js
import React, { useEffect } from 'react';
import { Route, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../pages/Login/Login';
import Website from '../pages/Website/Website';
import Loading from '../pages/Loading/Loading';
import { checkValidity } from '../services/userService';
// You can use your authentication state management here

const ProtectedRoute = ({ element }) => {
  const token = useSelector((state) => state.auth.token);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  function containsSlugOrFragment(url) {
    // Get the URL pathname and remove leading and trailing slashes
    const pathname = new URL(url).pathname.replace(/^\/|\/$/g, '');
  
    // Get the fragment identifier and remove leading and trailing slashes
    const fragment = new URL(url).hash.replace(/^#|\/$/g, '');
  
    // Check if either the cleaned pathname or fragment is non-empty
    return pathname.length > 0 || fragment.length > 0;
  }
  useEffect(()=>{
    if(token!=null){
      if(isLoggedIn){
          checkValidity({token}).then((res)=>{
            if(!res.data){
              localStorage.clear();
              window.location.reload();
            } else if(!containsSlugOrFragment(window.location.href)){
              window.location.href = `${window.location.href}#/home`;
            }
          }).catch((err)=>{
            console.log(err)
          })
      }
    }
  },[])
  console.log(isLoggedIn)
  const navigate = useNavigate();

//   if (!isLoggedIn) {
//     navigate('/login'); // Redirect to the login page if not authenticated
//     return null; // Return null to prevent rendering the protected component
//   }

  return isLoggedIn? element : <Website></Website>; // Render the protected component's child routes
};

export default ProtectedRoute;
