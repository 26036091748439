import React, { useState } from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Checkbox from '@mui/joy/Checkbox';
import Input from '@mui/joy/Input'; // Import Input instead of TextField
import Grid from '@mui/joy/Grid';
import Button from '@mui/joy/Button';

const ScheduleForm = (props) => {
 
 

 
  const handleCustomHoursChange = (event) => {
    const { name, value, checked } = event.target;
    props.setCustomHours((prevHours) => ({
      ...prevHours,
      [name]: value || checked,
    }));
  };

  const renderCustomHours = () => {
    if (true) {
      return (
        <div className="customHours">
          <FormLabel component="legend">Custom Hours</FormLabel>
          <Checkbox
            checked={props.customHours.sunday}
            onChange={handleCustomHoursChange}
            name="sunday"
          />
          Sunday
          <Checkbox
            checked={props.customHours.monday}
            onChange={handleCustomHoursChange}
            name="monday"
          />
          Monday
          <Checkbox
            checked={props.customHours.tuesday}
            onChange={handleCustomHoursChange}
            name="tuesday"
          />
          Tuesday
          <Checkbox
            checked={props.customHours.wednesday}
            onChange={handleCustomHoursChange}
            name="wednesday"
          />
          Wednesday
          <Checkbox
            checked={props.customHours.thursday}
            onChange={handleCustomHoursChange}
            name="thursday"
          />
          Thursday
          <Checkbox
            checked={props.customHours.friday}
            onChange={handleCustomHoursChange}
            name="friday"
          />
          Friday
          <Checkbox
            checked={props.customHours.saturday}
            onChange={handleCustomHoursChange}
            name="saturday"
          />
          Saturday
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Input
                placeholder="From"
                name="from"
                value={props.customHours.from}
                onChange={handleCustomHoursChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              -
            </Grid>
            <Grid item xs={5}>
              <Input
                placeholder="To"
                name="to"
                value={props.customHours.to}
                onChange={handleCustomHoursChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    return null;
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        How do you want to offer your availability for this event type?
      </FormLabel>
     
      {renderCustomHours()}
     
    </FormControl>
  );
};

export default ScheduleForm;
