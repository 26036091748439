import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { Textarea } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import { createIntro, createOrganization } from '../../services/toolsService';
import { fileUpload } from '../../services/fileUploadService';

export default function OrganizationModal(props) {
   const [name, setName] = React.useState('');
   const [desc, setDesc] = React.useState('');
   const [addr, setAddr] = React.useState('');
   const [profilePic, setProfilePic] = React.useState('');
   let config = {
    onUploadProgress: progressEvent => {
     
    }
  }
   React.useEffect(()=>{
   
   },[props])
   const handleChange = (event, newValue) => {
    //alert(`You chose "${newValue}"`);
  
  };
  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: '60%'}}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Add new organization
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
        Create a new Organization
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              props.setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', padding: 20}}>

                <FormControl>
               <Card component="li" sx={{ width: 200, height: 200, flexGrow: 1 }} onClick={()=>{document.getElementById("profilePic").click();}}>
        <CardCover>
          <img
            src={profilePic == "" ? "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=400" : profilePic}
            srcSet={profilePic == "" ? "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=400&dpr=2 2x" : profilePic}
            loading="lazy"
            alt=""
          />
        </CardCover>
        <CardContent>
          <Typography
            level="body-lg"
            fontWeight="lg"
            textColor="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            Logo
          </Typography>
        </CardContent>
      </Card>
               </FormControl>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '80%'}}>
                <FormControl>
                <FormLabel>Name</FormLabel>
                <Input readOnly={props.view} value={name} onChange={(e)=>{
                
                  setName(e.target.value)
                
                  }} autoFocus required />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea readOnly={props.view} onChange={(e)=>{setDesc(e.target.value)}} value={desc} minRows={4} />
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Textarea readOnly={props.view} onChange={(e)=>{setAddr(e.target.value)}} value={addr} minRows={4} />
              </FormControl>
              { !props.view ? <Button type="submit" style={{marginTop: 10}} onClick={()=>{
                let obj = {
                  name,
                  description: desc,
                  address: addr,
                  logo: profilePic
                }
               createOrganization(obj).then(()=>{
                props.setOpen(false)
               }).catch((j)=>{})
              }}>{props.edit ? 'Update' : 'Submit'}</Button> : ''}
                </div>
              </div>
             
              <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                    
                      fileUpload(e,config,{token: 'B37vlz4qQea8GMr7h8IYJOSlffK8FCkOY8FSgK7kUi/pM+UQ0nPsyxp+p1gSubrnZqEeiERDdK8xbTVheEakXQ=='})
                        .then(data => {
                            setProfilePic(data)
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            // alert(err);
                            // this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="profilePic"
                  />
              
             
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}