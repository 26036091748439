import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';

import Typography from '@mui/joy/Typography';


export default function PreInterviewModal(props) {

  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: '60%'}}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Add new pre interview
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
           Click on the record button to start recording.
          </Typography>
         {props.content}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}