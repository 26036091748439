import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import Stack from '@mui/joy/Stack';
import useScript from './useScript';
// import FirstSidebar from './components/FirstSidebar';
// import Header from './components/Header';
import customTheme from './theme';
import Timeline from '../Timeline/Timeline';
import OutlinedTimeline from '../Timeline/Timeline';
// import RentalCard from './components/RentalCard';
// import Main from './components/Main';
// import HeaderSection from './components/HeaderSection';
// import Search from './components/Search';
// import Filters from './components/Filters';
// import Toggles from './components/Toggles';
// import Pagination from './components/Pagination';

const useEnhancedEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export default function ActionPane() {
  const status = useScript(`https://unpkg.com/feather-icons`);

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof window.feather !== 'undefined') {
      // @ts-ignore
      window.feather.replace();
    }
  }, [status]);

  return (
    <CssVarsProvider disableTransitionOnChange theme={customTheme}>
      <GlobalStyles
        styles={{
          '[data-feather], .feather': {
            color: 'var(--Icon-color)',
            margin: 'var(--Icon-margin)',
            fontSize: 'var(--Icon-fontSize, 20px)',
            width: '1em',
            height: '1em',
          },
        }}
      />
      <CssBaseline />
      <Box sx={{ display: 'flex', height: '100%' }}>
        {/* <Header />
        <FirstSidebar />
        <Main> */}
          <Grid
            container
            sx={{
              width: '100%',
              height: '100%',

              margin: 0,
            }}
          >
            <Grid
              xs={12}
              lg={8}
              sx={{
                overflowY: 'scroll',
                height: '100%',
                px: { xs: 2, md: 4 },
                pt: { xs: 8, md: 4 },
                pb: 5,
              }}
            >
              <Stack spacing={2}>
             
              </Stack>
            </Grid>
            <Grid
              xs={4}
              sx={{
                display: { xs: 'none', lg: 'block' },
              }}
            >
              <Box
                sx={{
                  borderLeft: '1px solid',
                  borderColor: 'divider',
                  padding: 1.5,
                  height: '100%',
                }}
              >
                {/* <Box
                  sx={{
                    backgroundColor: 'background.level1',
                    height: '100%',
                    borderRadius: 'sm',
                    backgroundSize: 'cover',
                    backgroundImage:
                      'url("https://images.unsplash.com/photo-1478860409698-8707f313ee8b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=4000&q=80")',
                  }}
                /> */}
                
              </Box>
            </Grid>
          </Grid>
        {/* </Main> */}
      </Box>
    </CssVarsProvider>
  );
}
