import { useState } from 'react';
import Editor from 'react-simple-wysiwyg';

export default function WEditor(props) {
  const [html, setHtml] = useState();
  

  return (
    <Editor containerProps={{ style: { resize: 'vertical' } }} value={props?.desc} onChange={props?.onChange} />
  );
}