import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import CenterLayout from './CenterLayout';
import ScrollCard from './ScrollCard';
import inter_video from '../../assets/images/inter_video.png';

export default function Hero3() {
  return (
    <CenterLayout>
        <div className='flexCol' style={{width: '100%'}}>
      {/* <Typography color="primary" fontSize="lg" fontWeight="lg">
        The power to do more
      </Typography> */}
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
       Hire faster & hire better!
      </Typography>
     <div className='flexRow' style={{paddingTop: 20,width: '100%',justifyContent: 'space-around'}}>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
      Interviewable video pre-interviews let recruiters and
HR save time and identify great candidates quickly!
      </Typography>
        {/* <ScrollCard></ScrollCard> */}
     </div>
     <div className='flexRow' style={{paddingTop: 20,width: '100%',justifyContent: 'center'}}>
     <div className="flexCol" style={{height: 400, width: 400}}>
        <img src={inter_video} style={{width: 400}}></img>
     </div>
     <div className = "flexCol" style={{height: 400, width: 400, boxSizing: 'border-box',paddingLeft: 40,alignItems: 'flex-start',paddingRight: 40}}>

     <Typography
        level="h3"
        fontWeight="xl"
        // fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
       Pre-recorded video answers
      </Typography>
      <Typography style={{marginTop: 20}} fontSize="lg" textColor="text.secondary" lineHeight="lg">
      You can view pre-recorded video answers to interview questions from candidates.
      </Typography>
      <div className='flexRow'>
      <Typography color="primary" style={{marginTop: 20}} fontSize="lg" fontWeight="lg" className="flexRow">
        Know More 
      </Typography><ArrowForward style={{marginLeft: 5}}color="primary" fontSize="xl" /></div>
     </div>
     </div>

      {/* <Typography
        level="body-xs"
        sx={{
          position: 'absolute',
          top: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        HeroLeft01
      </Typography> */}
      </div>
    </CenterLayout>
  );
}