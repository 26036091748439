
import { Outlet } from 'react-router-dom';
import './ViewPort.css';
import Navbar from '../Navbar/Navbar';
import SideNav from './SideNav';
import GSidebar from './GSidebar';
import Sidebar from "../sidebar/Sidebar"
import Topbar from '../topbar/Topbar';
import { useEffect, useState } from 'react';
import TabContext from './TabContext';
import { getUserDetails } from '../../services/toolsService';

function ViewPort() {
  const [tabs, setTabs] = useState([]);
  const [user, setUser] = useState({
    name: '',
    username: '',
    profilePic: ''
  });
  useEffect(() => {
    getUserDetails().then((res) => {
      setUser({
        name: res.data.name,
        username: res.data.username,
        profilePic: res.data.profilepic
      });
      console.log(res.data);
    }).catch((err) => {});
  },[])
  return (
  //   <>
  // <div className='flexRow' style={{width: '100vw', height: '6vh', backgroundColor: '#ededed', justifyContent: 'flex-start'}}>
  // <div className="flexRow" style={{width: '8vw', height: '4vh', borderRadius: 20, backgroundColor: 'white', margin: '20px auto 10px auto'}}>Logo</div>
  // <div className="flexRow" style={{width: '75vw', height: '4vh', borderRadius: 20, backgroundColor: 'white', margin: '20px auto 10px auto'}}></div>
  // <div className="flexRow" style={{width: '10vw', height: '4vh', borderRadius: 20, backgroundColor: 'white', margin: '20px auto 10px auto'}}></div>
  // </div>
  // <div className='flexRow' style={{height: '94vh', backgroundColor: '#ededed'}}>
  //   {/* <Navbar></Navbar> */}
  //   {/* <div className="side-navbar" style={{height: window.innerHeight, width: '20%'}}>
  //     <ul>
  //       <li>Home</li>
  //       <li>About</li>
  //       <li>Services</li>
  //       <li>Contact</li>
  //     </ul>
  //   </div> */}
  //   {/* <SideNav></SideNav> */}
  //   <GSidebar></GSidebar>
  
  //  <div style={{height: '93vh',backgroundColor: 'white',borderRadius: 10, width: '89vw',overflowY: 'scroll', overflowX: 'hidden'}} className="viewport">
  //  <Outlet/>
  //  </div>
  //  </div>
  //  </>
  <>
   <TabContext.Provider value={{ tabs, setTabs, user, setUser }}>
  <div className={"fullVisualHome"}>

     <Topbar tabs={[]} onChange={()=>{}}/>   
    
     <div className="homeContainer">
     <Sidebar/>
    <Outlet></Outlet>
     </div>

     </div>
     </TabContext.Provider>
</>
  );
}

export default ViewPort;
