let data = [
    {
      "country": "React",
      "React JS": 10,
      "React JSColor": "hsl(323, 70%, 50%)",
    },
    {
      "country": "Angular",
      "React JS": 26,
      "React JSColor": "hsl(9, 70%, 50%)",
      "Angular": 45,
      "AngularColor": "hsl(175, 70%, 50%)",
      "Python": 45,
      "PythonColor": "hsl(158, 70%, 50%)",
      ".NET": 32,
      ".NETColor": "hsl(328, 70%, 50%)",
      "Java": 5,
      "JavaColor": "hsl(171, 70%, 50%)",
      "Swift": 34,
      "SwiftColor": "hsl(30, 70%, 50%)"
    },
    {
      "country": ".NET",
      "React JS": 6,
      "React JSColor": "hsl(340, 70%, 50%)",
      "Angular": 34,
      "AngularColor": "hsl(319, 70%, 50%)",
      "Python": 56,
      "PythonColor": "hsl(176, 70%, 50%)",
      ".NET": 70,
      ".NETColor": "hsl(245, 70%, 50%)",
      "Java": 44,
      "JavaColor": "hsl(157, 70%, 50%)",
      "Swift": 33,
      "SwiftColor": "hsl(323, 70%, 50%)"
    },
    {
      "country": "Python",
      "React JS": 56,
      "React JSColor": "hsl(93, 70%, 50%)",
      "Angular": 10,
      "AngularColor": "hsl(184, 70%, 50%)",
      "Python": 87,
      "PythonColor": "hsl(148, 70%, 50%)",
      ".NET": 89,
      ".NETColor": "hsl(223, 70%, 50%)",
      "Java": 35,
      "JavaColor": "hsl(90, 70%, 50%)",
      "Swift": 22,
      "SwiftColor": "hsl(20, 70%, 50%)"
    },
    {
      "country": "Swift",
      "React JS": 47,
      "React JSColor": "hsl(163, 70%, 50%)",
      "Angular": 32,
      "AngularColor": "hsl(102, 70%, 50%)",
      "Python": 54,
      "PythonColor": "hsl(41, 70%, 50%)",
      ".NET": 23,
      ".NETColor": "hsl(250, 70%, 50%)",
      "Java": 88,
      "JavaColor": "hsl(5, 70%, 50%)",
      "Swift": 24,
      "SwiftColor": "hsl(59, 70%, 50%)"
    },
    {
      "country": "Java",
      "React JS": 37,
      "React JSColor": "hsl(226, 70%, 50%)",
      "Angular": 43,
      "AngularColor": "hsl(29, 70%, 50%)",
      "Python": 67,
      "PythonColor": "hsl(23, 70%, 50%)",
      ".NET": 68,
      ".NETColor": "hsl(225, 70%, 50%)",
      "Java": 24,
      "JavaColor": "hsl(265, 70%, 50%)",
      "Swift": 20,
      "SwiftColor": "hsl(81, 70%, 50%)"
    },
    {
      "country": "PHP",
      "React JS": 34,
      "React JSColor": "hsl(2, 70%, 50%)",
      "Angular": 29,
      "AngularColor": "hsl(67, 70%, 50%)",
      "Python": 25,
      "PythonColor": "hsl(175, 70%, 50%)",
      ".NET": 78,
      ".NETColor": "hsl(322, 70%, 50%)",
      "Java": 5,
      "JavaColor": "hsl(2, 70%, 50%)",
      "Swift": 56,
      "SwiftColor": "hsl(63, 70%, 50%)"
    }
  ]

  export default data;