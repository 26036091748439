// import React,{useEffect, useState} from 'react'
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './post.css'
import { Users } from "../../dummyData";
// import axios from 'axios';
// import {format} from "timeago.js"

function timeAgo(dateString) {
  const now = new Date();
  const past = new Date(dateString);
  const diffInSeconds = Math.floor((now - past) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);

  if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
  } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
  } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
  } else if (diffInDays < 7) {
      return `${diffInDays} days ago`;
  } else if (diffInWeeks < 4) {
      return `${diffInWeeks} weeks ago`;
  } else if (diffInMonths < 12) {
      return `${diffInMonths} months ago`;
  } else {
      return `${diffInYears} years ago`;
  }
}

export default function Post({post}) {
  const [like,setLike] = useState(post.likes);
  const [isLiked,setIsLiked] = useState(false);
  // const [user,setUser] = useState({});
  const likeHandler = ()=>{
    if(isLiked===false){
      setLike(like+1)
      setIsLiked(true)
    }else{
      setLike(like-1)
      setIsLiked(false)
    }
  }

  // useEffect(()=>{
  //   const fetchUser = async()=>{
  //     const res = await axios.get(`users/${post.userId}`)
  //     setUser(res.data)
  //   }
  //   fetchUser();
  // },[post.userId]);

  return (
   <div className="post">
    {/* <div className="postWrapper">
        <div className="postTop">
          <div className="postTopLeft">
            <img className='postProfileImage' src={user.profilePicture || PF+"/person/noAvatar.png"} alt="" />
            <span className="postUsername">{user.username}</span>
            <span className="postDate">{format(post.createdAt)}</span>
          </div>

          <div className="postTopRight">
               <MoreVertIcon style={{ cursor:'pointer'}}/>
          </div>
          </div>

      <div className="postCenter">
        <span className="postText">{post.desc}</span>
        <img className='postImage' src={PF+"/post/"+post.img} alt="" />
      </div>
      <div className="postBottom">
          <div className="postBottomLeft">
            <div style={{display:'flex'}}>
            <div className="likeIconCont">
            <img className='likeIcon' onClick={likeHandler} src={PF+"like.png"} alt="" />
            </div>
            <div className="likeIconCont">
            <img className='likeIcon' onClick={likeHandler}src={PF+"heart.png"} alt="" />
              </div>
            </div>
            <span className="postLikeCounter">{like} people like it</span>
          </div>
          <div className="postBottomRight">
           <span className="postCommentText">{post.comment} comments</span>
          </div>
          </div>
        </div> */}
    <div className="postWrapper">
    <div className="postTop">
      <div className="postTopLeft">
        <img className='postProfileImage' src={post.profilePic} alt="" />
        <span className="postUsername">{post.name}</span>
        <span className="postDate">{timeAgo(post.createdTime)}</span>
      </div>

      <div className="postTopRight">
           <MoreVertIcon style={{ cursor:'pointer'}}/>
      </div>
      </div>

  <div className="postCenter">
    <span className="postText">{post.postContent}</span>
    {/* <img className='postImage' src={post.photo} alt="" /> */}
  </div>
  <div className="postBottom">
      <div className="postBottomLeft">
        <div style={{display:'flex'}}>
        <div className="likeIconCont">
        <img className='likeIcon' onClick={likeHandler} src={"../assets/like.png"} alt="" />
        </div>
        <div className="likeIconCont">
        <img className='likeIcon' onClick={likeHandler}src={"../assets/heart.png"} alt="" />
          </div>
        </div>
        <span className="postLikeCounter">{like} people like it</span>
      </div>
      <div className="postBottomRight">
       <span className="postCommentText">{0} comments</span>
      </div>
      </div>
    </div>

    </div>
  )
}
