import { Button, Typography } from '@mui/joy';
import ilogo from '../../assets/images/invert_logo.png';
import { Facebook, FavoriteBorder, Instagram, KeyboardArrowRight, Twitter } from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';


export default function Footer(){
    return(
        <div className="flexRow" style={{width: window.innerWidth,backgroundColor: 'rgba(37,40,73,1)',height: (80/100)*window.innerHeight, borderTopLeftRadius: 40, borderTopRightRadius: 40}}>
        <div className='flexCol' style={{ justifyContent: 'flex-start',height: '100%',paddingTop: '10%',width: (30/10)*window.innerWidth}}>
            <img src={ilogo}></img>
            <Button size="lg" endDecorator={<KeyboardArrowRight/>} variant="solid" style={{marginTop: 30, borderRadius: 40}}>CONTACT US</Button>
        </div>
        <div className='flexRow'  style={{justifyContent: 'space-around',alignItems: 'flex-start',height: '100%',paddingTop: '10%', width: (70/10)*window.innerWidth}}>
            <div className='flexCol' style={{ boxSizing: 'border-box',height: '75%',justifyContent: 'flex-start',alignItems: 'flex-start'}}>
            <Typography style={{color: 'white'}} level="h3">Company</Typography>
            <Typography style={{marginTop: 15, color: 'white'}} fontSize="lg">Home</Typography>
            <Typography style={{marginTop: 20, color: 'white'}} fontSize="lg">About Us</Typography>
            <Typography style={{marginTop: 20, color: 'white'}} fontSize="lg">Faq</Typography>
            <Typography style={{marginTop: 20, color: 'white'}} fontSize="lg">Terms of Use</Typography>
            </div>
            <div className='flexCol' style={{ height: '70%',justifyContent: 'flex-start',alignItems: 'flex-start'}}>
            <Typography level="h3" style={{color: 'white'}}>Services</Typography>
            <Typography style={{marginTop: 15, color: 'white'}} fontSize="lg">Mock Interviews</Typography>
            <Typography style={{marginTop: 20, color: 'white'}} fontSize="lg">Live Interviews</Typography>
            <Typography style={{marginTop: 20, color: 'white'}} fontSize="lg">Apply to Jobs</Typography>
           
            </div>
            <div className='flexCol' style={{ height: '70%',justifyContent: 'flex-start',alignItems: 'flex-start'}}>
            <Typography level="h3" style={{color: 'white'}}>Account</Typography>
            <Typography style={{marginTop: 15,color: 'white'}} fontSize="lg">Register</Typography>
            <Typography style={{marginTop: 20, color: 'white'}} fontSize="lg">Login</Typography>
            <Typography style={{marginTop: 20, color: 'white'}} fontSize="lg">My Account</Typography>
           
            </div>
            <div className='flexCol' style={{ height: '70%',justifyContent: 'flex-start',alignItems: 'flex-start'}}>
            <Typography level="h3" style={{color: 'white'}}>Follow Us</Typography>
            <div className="flexRow" style={{width: '100%',justifyContent: 'space-around',marginTop: 15}} >

            <IconButton style={{borderRadius: 40}} variant="solid">
         <Instagram/>
          
        </IconButton>
        <IconButton style={{borderRadius: 40}}variant="solid">
          
          <Facebook></Facebook>
        </IconButton>
        <IconButton style={{borderRadius: 40}}variant="solid">
         <Twitter></Twitter>
          
        </IconButton>
            </div>
           
            </div>
        </div>
        </div>
    )
}