
import ViewPort from '../../components/ViewPort/ViewPort';
import './Jobs.css';



import Pagination from '@mui/material/Pagination';
import ListItems from '../../components/List/List';

import JobsTable from './JobsTable';
import { useSelector } from 'react-redux';
import ApplicationsTable from './ApplicationsTable';



function Jobs() {
  const type = useSelector((state) => state.auth.user.type);
  return (
    <div className='jobs_container' style={{overflowY: 'scroll'}}>
  

           { type==0 ? <ListItems table={<JobsTable></JobsTable>}></ListItems> : <ListItems table={<ApplicationsTable></ApplicationsTable>}></ListItems>}
             
   {/* <Pagination onChange={(event, value)=>{alert(value)}} style={{marginTop: 20, marginBottom: 20}} count={10} variant="outlined" color="primary" size="large" /> */}
   </div>
  );
}

export default Jobs;
