

import './PreInterviewsList.css';
import ListItems from '../../../../components/List/List';
import OrderTable from '../../../../components/List/OrderTable';
import PreInterviewsTable from './PreInterviewsTable';




function PreInterviewsList() {
  return (
    <div className='pre_container'>
    <ListItems table={<PreInterviewsTable></PreInterviewsTable>}></ListItems>
   </div>
  );
}

export default PreInterviewsList;
