import React, { useState } from 'react';
import GridWrapper from "./GridWrapper";
import CreateFolder from './CreateFolder';

export default function Playlists() {
    const [createMode, setCreateMode] = useState(false);
  return (
    <div style={{ display: 'flex', flex: 1, backgroundColor: 'white' }}>
      {!createMode? <GridWrapper />:
      <CreateFolder open={createMode} setOpen={setCreateMode} onSubmit={()=>{}}></CreateFolder>
      }
      <button style={styles.addButton} onClick={()=>{setCreateMode(!createMode)}}>+</button>
    </div>
  );
}

const styles = {
  addButton: {
    position: 'fixed', // Fixed position relative to the viewport
    right: '20px', // 20px from the right edge of the viewport
    bottom: '20px', // 20px from the bottom edge of the viewport
    width: '50px', // Diameter of the button
    height: '50px', // Diameter of the button
    borderRadius: '50%', // Makes the button round
    backgroundColor: '#FF69B4', // Pink background color for the button
    color: 'white', // Color of the "+" sign
    fontSize: '30px', // Size of the "+" sign
    border: 'none', // Removes the border
    cursor: 'pointer', // Changes the cursor on hover
    display: 'flex', // Using flex to center the "+" sign
    alignItems: 'center', // Center align the "+" vertically
    justifyContent: 'center', // Center align the "+" horizontally
    zIndex: 1000, // Ensures the button is above other elements
  }
};
