import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
const chunkSize = 1048576 * 50;

function getChunkCount(data){
 
    let totalCount =
        data.size % chunkSize == 0
          ? data.size / chunkSize
          : Math.floor(data.size / chunkSize) + 1;
  
    return totalCount; 
  }
  function getChunkSize(){
  
    return chunkSize; 
  }

async function uploadChunk(filename,blockid,chunk,config){
	
	const promise = axios.post(
      `https://interviewableapi20220528123614.azurewebsites.net/api/ChunkUpload/${filename}/${blockid}`,
      chunk,config
    );
 return promise;
}

async function multiUpload(fileToUpload,e,config,currentUser){
  let url = "";
  let file = fileToUpload;

  let headers = { headers: {
    "x-functions-key": currentUser.token
  }}


 config = Object.assign(config,headers);
 
  
  let filename = file.name;
  let count = getChunkCount(file);
 let bloblist = [];


  let chunkSize = getChunkSize();
  
  for(var i=0;i<count;i++){
  let start = i*chunkSize;
  let end = start+chunkSize; 
  let chunk = file.slice(start,end)
  let blockid = uuidv4();
  
  await uploadChunk(filename,blockid,chunk,config)
  .then((value)=>{
bloblist.push(value.data.split('$')[0]);
  }).catch(()=>{}) 
}

const promise = await axios.post(
 `https://interviewableapi20220528123614.azurewebsites.net/api/CompleteUpload`,
           {FileName: filename,bloblist},{ headers: {
            "x-functions-key": currentUser.token
          }}
         ).then((x)=>{url = x.data});

 return Promise.resolve(url);

}

async function multiFileUploader(e,config,currentUser){
  let url = "";
  let file = e;

 
  let headers = { headers: {
    "x-functions-key": currentUser.token
  }}


 config = Object.assign(config,headers);
 
  
  let filename = file.name;
  let count = getChunkCount(file);
 let bloblist = [];


  let chunkSize = getChunkSize();
  
  for(var i=0;i<count;i++){
  let start = i*chunkSize;
  let end = start+chunkSize; 
  let chunk = file.slice(start,end)
  let blockid = uuidv4();
  
  await uploadChunk(filename,blockid,chunk,config)
  .then((value)=>{
bloblist.push(value.data.split('$')[0]);
  }).catch(()=>{}) 
}

const promise = await axios.post(
 `https://interviewableapi20220528123614.azurewebsites.net/api/CompleteUpload`,
           {FileName: filename,bloblist},{ headers: {
            "x-functions-key": currentUser.token
          }}
         ).then((x)=>{url = x.data});

 return Promise.resolve(url);
}
async function fileUpload(e,config,currentUser) {
  let url = "";
  let file = e.target.files[0];

 
  let headers = { headers: {
    "x-functions-key": currentUser.token
  }}


 config = Object.assign(config,headers);
 
  
  let filename = file.name;
  let count = getChunkCount(file);
 let bloblist = [];


  let chunkSize = getChunkSize();
  
  for(var i=0;i<count;i++){
  let start = i*chunkSize;
  let end = start+chunkSize; 
  let chunk = file.slice(start,end)
  let blockid = uuidv4();
  
  await uploadChunk(filename,blockid,chunk,config)
  .then((value)=>{
bloblist.push(value.data.split('$')[0]);
  }).catch(()=>{}) 
}

const promise = await axios.post(
 `https://interviewableapi20220528123614.azurewebsites.net/api/CompleteUpload`,
           {FileName: filename,bloblist},{ headers: {
            "x-functions-key": currentUser.token
          }}
         ).then((x)=>{url = x.data});

 return Promise.resolve(url);

  // let idArray = [];
  // for(var i=0;i<count;i++){
  //    let chunkSize = getChunkSize();
  //    let start = i*chunkSize;
  //    let end = start+chunkSize; 
  //    let chunk = e.target.files[0].slice(start,end)
  //    let blockid = uuidv4();
  //    const promise =  await axios.post(
  //     `http://localhost:7071/api/ChunkUpload/${filename}/${blockid}`,
  //     chunk,config
  //   ).then((value)=>{
  //     idArray.push(value.data.split('$')[0]);
      
  //     if(idArray.length == count){
  //       filename = value.data.split('$')[1];
  //       const promise = axios.post(
  //         `http://localhost:7071/api/CompleteUpload`,
  //         {FileName: filename,bloblist:idArray}
  //       ).then((val)=>{ url = val.data})
      
  //     }
  //   }).catch(()=>{

  //   });
  //   }
  //   return url;
}




export {
    fileUpload,
    multiFileUploader
}