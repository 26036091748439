import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

export default function VideoContainer(props) {
  const [mode, setMode] = React.useState('normal');
 
  React.useEffect(()=>{
    let blobs = [...props.blobs];
    // console.log(props.videoCount)
    // console.log(props.state)
    // console.log(props.blobs)
  
    //console.log(blobs)
    if(props.state== 'stopped' && blobs.length == props.videoCount && blobs.filter(x=>typeof(x) !=='string')==0){
     
      props.setPreViewIndex(props.blobs.length-1);
      //console.log(props.blobs)
      setMode('preview')
    }
  },[props.blobs, props.videoCount, props.state])
  return (
    <Box
      component="ul"
      sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0 }}
    >
      <Card component="li" sx={{ height: `${(60/100)*window.innerHeight}px`, flexGrow: 1 }}>
       
         {mode == 'preview' && props.blobs[props.previewIndex] ?   <video style={{borderRadius: 10}} controls autoPlay  height={'100%'}
          src = {props.blobs[props.previewIndex]}
          ></video> :  <CardCover><video
          autoPlay
          height={`${(80/100)*window.innerHeight}px`}
          muted
          ref={props.recorder}
          poster="https://assets.codepen.io/6093409/river.jpg"
        >
        
        </video></CardCover>}
        
        <CardContent sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'flex-end'}}>
          <Typography
            level="body-lg"
            fontWeight="lg"
            textColor="#fff"
            // mt={{ xs: 12, sm: 18 }}
          >
            The video will be recorded as soon as you click the record button.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}