import "./topbar.css";
import { Search, Person, Notifications, Logout } from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {Link} from 'react-router-dom'
import TabContext from "../ViewPort/TabContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { userLogout } from "../../store/authSlice";


export default function Topbar() {
  const dispatch = useDispatch();
  const { tabs, setTabs, user, setUser } = useContext(TabContext);
  return (
    <>
    <div className="topbarContainer">
      <div className="topbarLeft" style={{paddingTop: 15, paddingBottom: 5, paddingLeft: 5}}>
        <Link  to="/" style={{textDecoration:'none'}}>
        <img height='37px' src={"assets/logo.png"} alt="" />
        </Link>
      </div>
      <div className="topbarLeft2">
        {/* <div className="searchbar">
          <Search className="searchIcon" />
          <input
            placeholder="Search"
            className="searchInput"
          />
        </div> */}
      </div>
      <div className="topbarCenter">
        {window.location.href.includes('ats')? <div  className="flexCol" style={{ backgroundColor: 'black', color: 'white', paddingBottom: 5,paddingLeft: 10, paddingRight: 10, borderRadius: 20,position: 'absolute', // Position it absolutely
    left: '50%',  transform: 'translateX(-50%)'}}>{`${tabs.length>0? tabs[0].resumes : '0'} resumes`}</div>:
      <div className="topbarLinks">
      {
            tabs.map((tab, index) => {
              return (
                <div className="topbarCenterIcon">
                 
<div  className="topbarHomeIcon">
<span class="tooltiptext">{tab.label}</span>
<Link to={tab.to} key={index} style={{textDecoration:'none'}}>
{tab.icon}
</Link>
</div>
</div>
              )
            })
          }
        {/* <div className="topbarCenterIcon">
          <div  className="topbarHomeIcon">
            <Link to="/">
          <HomeIcon style={{fontSize:'30px',color:"blue",position:'relative',  opacity:'0.7'}}/>
          </Link>
          </div>
        </div> */}
          {/* <div className="topbarCenterIcon">
          <LiveTvIcon style={{fontSize:'30px',color:"grey"}}/>
          </div> */}
          {/* <div className="topbarCenterIcon">
          <StorefrontIcon style={{fontSize:'30px',color:"grey"}}/>
          </div> */}
          {/* <div className="topbarCenterIcon">
          <SportsEsportsIcon style={{fontSize:'30px',color:"grey"}}/>
          </div> */}
     
        </div>}
      </div>
      <div className="topbarRight">
        <div className="topbarIcons">
          <div className="topbarIconItem">
            <div className="topbarIconCont">
            <Person style={{color:"black",borderRadius:'50%',fontSize:'21px'}} />
            </div>
            <span className="topbarIconBadge">1</span>
          </div>
          {/* <div className="topbarIconItem">
          <div className="topbarIconCont">
          <QuestionAnswerIcon  style={{color:"black",borderRadius:'50%',fontSize:'21px'}}/>
            </div>
            <span className="topbarIconBadge">2</span>
          </div> */}
          <div className="topbarIconItem">
          <div className="topbarIconCont">
          <Notifications  style={{color:"black",borderRadius:'50%',fontSize:'21px'}}/>
            </div>
            <span className="topbarIconBadge">1</span>
          </div>
          <div onClick={()=>{dispatch(userLogout())}} className="topbarIconItem">
          <div className="topbarIconCont">
          <Logout style={{color:"black",borderRadius:'50%',fontSize:'21px'}}/>
            </div>
            {/* <span className="topbarIconBadge">1</span> */}
          </div>
        </div>

        
        <Link to="/account" style={{textDecoration:'none'}}>
        <img src={user.profilePic} alt="" className="topbarImg"/>
        </Link>
      </div>
    </div>
    </>
  );
}
