import axios from "axios";
import store from "../store/store";

var baseUrl = "https://interviewableapi20220528123614.azurewebsites.net/api/";
// if(window.location.href.includes('localhost')){
//   baseUrl = "http://localhost:7071/api/";
// }
// baseUrl = "http://localhost:7071/api/";

axios.interceptors.request.use(
    config => {
      const token = store.getState().auth.token;
      if (token) {
        config.headers['x-functions-key'] = token
      }
      // config.headers['Content-Type'] = 'application/json';
      return config
    },
    error => {
      console.log(error);
      Promise.reject(error)
    }
  )

  function getIntro(){
    const promise = axios.get(
        `${baseUrl}intro`
      );
   return promise;
  }
  
  function getForms(){
    const promise = axios.get(
        `${baseUrl}Forms`
      );
   return promise;
  }



  function getCalendly(){
    const promise = axios.get(
        `${baseUrl}Calendly`
      );
   return promise;
  }

  function getCommunications(){
    const promise = axios.get(
        `${baseUrl}communications`
      );
   return promise;
  }
  function getWorkflows(){
    const promise = axios.get(
        `${baseUrl}Workflows`
      );
   return promise;
  }
  //getworkflowactions
  function getWorkflowActions(){
    const promise = axios.get(
        `${baseUrl}getworkflowactions`
      );
   return promise;
  }

  function getPreInterviews(){
    const promise = axios.get(
        `${baseUrl}PreInterview`
      );
   return promise;
  }

  
  function getJobs(){
    const promise = axios.get(
        `${baseUrl}Jobs`
      );
   return promise;
  }

  function getApplications(){
    const promise = axios.get(
        `${baseUrl}Applications`
      );
   return promise;
  }

  function updateApplication(data){
    const promise = axios.put(
        `${baseUrl}Applications`,
        data
      );
   return promise;
  }

  function createApplication(data){
    const promise = axios.post(
        `${baseUrl}Applications`,
        data
      );
   return promise;
  }

  function getFAQ(){
    const promise = axios.get(
        `${baseUrl}FAQ`
      );
   return promise;
  }

  function getEducation(){
    const promise = axios.get(
        `${baseUrl}Education`
      );
   return promise;
  }

function createIntro(data){
    const promise = axios.post(
        `${baseUrl}intro`,
        data
      );
   return promise;
}

function getWorkflow(data){
  const promise = axios.post(
      `${baseUrl}GetWorkflow`,
      data
    );
 return promise;
}
function getUserApplication(data){
  const promise = axios.post(
      `${baseUrl}GetUserApplication`,
      data
    );
 return promise;
}

function createCommunication(data){
  const promise = axios.post(
      `${baseUrl}communications`,
      data
    );
 return promise;
}

function createPreInterview(data){
  const promise = axios.post(
      `${baseUrl}PreInterview`,
      data
    );
 return promise;
}

function createCalendly(data){
  const promise = axios.post(
      `${baseUrl}Calendly`,
      data
    );
 return promise;
}

function createWorkflow(data){
  const promise = axios.post(
      `${baseUrl}Workflows`,
      data
    );
 return promise;
}

function getAction(data){
  const promise = axios.post(
      `${baseUrl}getaction`,
      data
    );
 return promise;
}

function createForms(data){
  const promise = axios.post(
      `${baseUrl}Forms`,
      data
    );
 return promise;
}

function createJob(data){
  const promise = axios.post(
      `${baseUrl}Jobs`,
      data
    );
 return promise;
}

function createAccount(data){
  const promise = axios.post(
      `${baseUrl}Account`,
      data
    );
 return promise;
}

function createFAQ(data){
  const promise = axios.post(
      `${baseUrl}FAQ`,
      data
    );
 return promise;
}

function createEducation(data){
  const promise = axios.post(
      `${baseUrl}Education`,
      data
    );
 return promise;
}

function createGroup(data){
  const promise = axios.post(
      `${baseUrl}creategroup`,
      data
    );
 return promise;
}

function getFriends(){
  const promise = axios.get(
      `${baseUrl}getfriends`
    );
 return promise;
}

function getAccount(){
  const promise = axios.get(
      `${baseUrl}Account`
    );
 return promise;
}

function login(data){
    const promise = axios.post(
        `${baseUrl}login`,
        data
      );
   return promise;
}

function invite(data){
    const promise = axios.post(
        `${baseUrl}inviteusers`,
        data
      );
   return promise;
}

function createOrganization(data){
  const promise = axios.post(
      `${baseUrl}Organizations`,
      data
    );
 return promise;
}
function getOrganizationUsers(id){
  const promise = axios.post(
      `${baseUrl}ListOrganizationUsers`,
      {id}
    );
 return promise;
}
function getOrganizations(){
  const promise = axios.get(
      `${baseUrl}Organizations`
    );
 return promise;
}
function getUserOrganizations(){
  const promise = axios.get(
      `${baseUrl}GetUserOrganizations`
    );
 return promise;
}

function addOrganizationUsers(data){
  const promise = axios.post(
    `${baseUrl}InsertOrganizationUsers`,
    data
  );
return promise;
}

//UpsertUserIntro
function getUserIntro(){
  const promise = axios.get(
      `${baseUrl}UpsertUserIntro`
    );
 return promise;
}

function getUserProfile(){
  const promise = axios.get(
      `${baseUrl}Profile`
    );
 return promise;
}

function getUserDetails(){
  const promise = axios.get(
      `${baseUrl}getuserdetails`
    );
 return promise;
}

function updateUserProfile(data){
  const promise = axios.post(
    `${baseUrl}Profile`,
    data
  );
return promise;
}

function updateUserIntro(data){
  const promise = axios.post(
    `${baseUrl}UpsertUserIntro`,
    data
  );
return promise;
}

//GetOrganizationDetail
function getOrganizationDetail(data){
  const promise = axios.post(
    `https://interviewable.com/GetOrganizationDetail.php`,
    data
  );
return promise;
}
//GetJobsByOrg
function getJobsByOrg(data){
  const promise = axios.post(
    `https://interviewable.com/GetJobsByOrg.php`,
    data
  );
return promise;
}
function updateOrganizationUser(data){
  const promise = axios.post(
    `${baseUrl}UpdateOrganizationUser`,
    data
  );
return promise;
}


//UpsertUserIntro
function getCandiateOrgJobs(){
  const promise = axios.get(
      `${baseUrl}CandidateOrgs`
    );
 return promise;
}

function getAppliedCandidates(){
  const promise = axios.get(
      `${baseUrl}GetAppliedCandidates`
    );
 return promise;
}

function insertCandiateOrg(data){
  const promise = axios.post(
    `${baseUrl}CandidateOrgs`,
    data
  );
return promise;
}

function getResume(){
  const promise = axios.get(
      `${baseUrl}Resume`
    );
 return promise;
}

function updateResume(data){
  const promise = axios.post(
    `${baseUrl}Resume`,
    data
  );
return promise;
}

function getLinksProjects(){
  const promise = axios.get(
      `${baseUrl}LinksProjects`
    );
 return promise;
}

function updateLinksProjects(data){
  const promise = axios.post(
    `${baseUrl}LinksProjects`,
    data
  );
return promise;
}

function updateJob(data){
  const promise = axios.post(
    `${baseUrl}UpdateJob`,
    data
  );
return promise;
}

//ApplicationsByJob

function getAppliedCandidatesByJob(data){
  const promise = axios.post(
    `${baseUrl}ApplicationsByJob`,
    data
  );
return promise;
}

function getCandidateAccount(data){
  const promise = axios.post(
    `${baseUrl}GetCandidateAccount`,
    data
  );
return promise;
}

function getCandidateFAQ(data){
  const promise = axios.post(
    `${baseUrl}GetCandidateFAQ`,
    data
  );
return promise;
}
function getCandidateEducation(data){
  const promise = axios.post(
    `${baseUrl}GetCandidateEducation`,
    data
  );
return promise;
}

function getCandidateResume(data){
  const promise = axios.post(
    `${baseUrl}GetCandidateResume`,
    data
  );
return promise;
}

function getCandidateIntro(data){
  const promise = axios.post(
    `${baseUrl}GetCandidateIntro`,
    data
  );
return promise;
}

//GetCandidateLinksProjects

function getCandidateLinksProjects(data){
  const promise = axios.post(
    `${baseUrl}GetCandidateLinksProjects`,
    data
  );
return promise;
}

function googleSignin(data){
  const promise = axios.post(
    `${baseUrl}GoogleLogin`,
    data
  );
return promise;
}
//PDFExtract
function upResume(data){
  const promise = axios.post(
    `${baseUrl}PDFExtract`,
    data
  );
return promise;
}

function GetATS(id){
  const promise = axios.get(
      `${baseUrl}GetATS/${id}`
    );
 return promise;
}

function GetATSFolders(id){
  const promise = axios.get(
      `${baseUrl}ATSFolders/${id}`
    );
 return promise;
}

function GetStats(id){
  const promise = axios.get(
      `${baseUrl}Stats`
    );
 return promise;
}

function CreateATSFolder(data){
  const promise = axios.post(
      `${baseUrl}ATSFolders/${data.org}`,
        data
    );
 return promise;
}
function GetPosts(){
  const promise = axios.get(
      `${baseUrl}Posts`
    );
 return promise;
}
function GetConnections(){
  const promise = axios.get(
      `${baseUrl}Connections`
    );
 return promise;
}
function CreatePost(data){
  const promise = axios.post(
      `${baseUrl}Posts`,
        data
    );
 return promise;
}
function MoveATSFolder(data){
  const promise = axios.post(
      `${baseUrl}MoveATSFolder`,
        data
    );
 return promise;
}
function BulkATSDelete(data){
  const promise = axios.post(
      `${baseUrl}BulkATSDelete`,
        data
    );
 return promise;
}
function DeleteATSFolder(data){
  const promise = axios.post(
      `${baseUrl}DeleteATSFolder`,
        data
    );
 return promise;
}
function FolderApplicants(data){
  const promise = axios.post(
      `${baseUrl}FolderApplicants`,
        data
    );
 return promise;
}

function SearchATS(data){
  const promise = axios.post(
      `${baseUrl}GetATS/${data.org}`,
        data
    );
 return promise;
}
function deleteATS(data){
  const promise = axios.post(
      `${baseUrl}DeleteATS`,
        data
    );
 return promise;
}

function verifyUser(data){
  const promise = axios.post(
      `${baseUrl}verifyuser`,
        data
    );
 return promise;
}

async function singleFileUploader(files, org, folder){




  const apiUrl = `${baseUrl}FileUpload/${org}`; // Replace with your Azure Functions API URL

            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
              formData.append('files', files[i]);
            }

           
            
                const response = await axios.post(apiUrl, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-functions-key': store.getState().auth.token,
                    'folder': folder
                  },
                });

               return response;
            
}

async function connectProfile(files, org){




  const apiUrl = `${baseUrl}ConnectProfile/${org}`; // Replace with your Azure Functions API URL

            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
              formData.append('files', files[i]);
            }

           
            
                const response = await axios.post(apiUrl, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'org': org
                  },
                });

               return response;
            
}

async function updateResumeJSON(data){


  const promise = axios.post(
    `${baseUrl}UpdateResumeFunction`,
      data
  );
return promise;
            
}

async function connectResume(data){


  const promise = axios.post(
    `${baseUrl}ConnectResume`,
      data
  );
return promise;
            
}

async function updateATSRemarks(data){


  const promise = axios.post(
    `${baseUrl}UpdateATSRemarks`,
      data
  );
return promise;
            
}
//DeleteATS
//GetATS
export {
  getUserProfile,
  updateUserProfile,
    createIntro,
    getIntro,
    getPreInterviews,
    createPreInterview,
    getCommunications,
    createCommunication,
    createCalendly,
    getCalendly,
    createForms,
    getForms,
    getWorkflowActions,
    createWorkflow,
    getWorkflows,
    createJob,
    getJobs,
    createFAQ,
    getFAQ,
    createAccount,
    getAccount,
    getApplications,
    getWorkflow,
    getAction,
    createOrganization,
    getOrganizations,
    getOrganizationUsers,
    addOrganizationUsers,
    updateOrganizationUser,
    updateApplication,
    getUserApplication,
    getUserOrganizations,
    getUserIntro,
    updateUserIntro,
    getOrganizationDetail,
    getJobsByOrg,
    getCandiateOrgJobs,
    insertCandiateOrg,
    createApplication,
    getAppliedCandidates,
    getEducation,
    createEducation,
    getResume,
    updateResume,
    getLinksProjects,
    updateLinksProjects,
    updateJob,
    getAppliedCandidatesByJob,
    getCandidateAccount,
    getCandidateFAQ,
    getCandidateEducation,
    getCandidateResume,
    getCandidateIntro,
    getCandidateLinksProjects,
    googleSignin,
    upResume,
    GetATS,
    SearchATS,
    deleteATS,
    singleFileUploader,
    updateResumeJSON,
    updateATSRemarks,
    GetATSFolders,
    CreateATSFolder,
    MoveATSFolder,
    FolderApplicants,
    DeleteATSFolder,
    BulkATSDelete,
    connectProfile,
    connectResume,
    getUserDetails,
    GetPosts,
    CreatePost,
    GetStats,
    GetConnections,
    verifyUser
}