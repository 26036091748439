

import Navbar from '../../components/Navbar/Navbar';
import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Header from '../../components/Account/Header';
import Candidates from './Candidates';
import TabsPane from '../../components/Tabs/Tabs';
import { TabPanel, Tabs } from '@mui/joy';
import { getAppliedCandidates } from '../../services/toolsService';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/appSlice';
import Loading from '../Loading/Loading';


const useEnhancedEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export default function CandidatesPage() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [candidates, setCandidates] = React.useState([]);
  React.useEffect(()=>{
    setIsLoading(true);
    getAppliedCandidates().then((res)=>{
      setIsLoading(false);
      let tempCandidates = res.data;
      tempCandidates.forEach((c,i)=>{
       tempCandidates[i].exp = (c.exp == '' || c.exp == null) ? [] : JSON.parse(c.exp);
      })
      console.log(tempCandidates)
setCandidates(tempCandidates)

    }).catch(()=>{})
  },[])

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof window.feather !== 'undefined') {
      // @ts-ignore
      window.feather.replace();
    }
  }, []);

  return (
    isLoading ? <Loading></Loading> : <>
      <CssBaseline />
        <Box
          component="main"
          className="MainContent"
          sx={(theme) => ({
            '--main-paddingTop': {
              xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
              md: '32px',
            },
            px: {
              xs: 2,
              md: 3,
            },
            pt: 'var(--main-paddingTop)',
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100%',
            boxSizing: 'border-box',
            gap: 1,
            overflow: 'auto',
          })}
        >
          <Candidates candidates={candidates}></Candidates>
          {/* <TabsPane tab={
          [
          <TabPanel value={0}><Candidates></Candidates></TabPanel>,
          <TabPanel value={1}>
         
        </TabPanel>,
        <TabPanel value={2}>
          
        </TabPanel>,
         <TabPanel value={3}>
          
         </TabPanel>,
          <TabPanel value={4}>
          
          </TabPanel>,
           <TabPanel value={5}>
          
           </TabPanel>,
            <TabPanel value={6}>
          
            </TabPanel>
          ]
          }/> */}
        </Box>
    </>
  );
}
