

import './CalendlyList.css';
import ListItems from '../../../components/List/List';
import OrderTable from '../../../components/List/OrderTable';
import CalendlyTable from './CalendlyTable';




function CalendlyList() {
  return (
    <div className='calendly_container'>
    <ListItems table={<CalendlyTable></CalendlyTable>}></ListItems>
   </div>
  );
}

export default CalendlyList;
