import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container,
  TextField,
  Typography,
  styled,
} from '@mui/material';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  maxWidth: '400px',
  margin: '0 auto',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
}));

const BlackButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'black',
  color: 'white',
  '&:hover': {
    backgroundColor: '#333',
  },
}));

function Apply() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    experiences: [],
    expectedSalary: '',
    technology: '',
  });

  const handleNext = () => {
    if (activeStep === 0) {
      // Validation for the first step (experiences)
      if (formData.experiences.length === 0) {
        //alert('Please add at least one experience.');
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCompleteStep1 = () => {
    // Validation for the first step (experiences)
    if (formData.experiences.length === 0) {
      //alert('Please add at least one experience.');
      return;
    }
    handleNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddExperience = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences: [...prevFormData.experiences, { employer: '', years: '' }],
    }));
  };

  const handleExperienceChange = (index, field, value) => {
    const updatedExperiences = [...formData.experiences];
    updatedExperiences[index][field] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences: updatedExperiences,
    }));
  };

  const steps = [
    {
      label: 'Past Experiences',
      content: (
        <div>
          {formData.experiences.map((experience, index) => (
            <div key={index}>
              <TextField
                label="Employer"
                value={experience.employer}
                onChange={(e) =>
                  handleExperienceChange(index, 'employer', e.target.value)
                }
                fullWidth
              />
              <TextField
                label="Years"
                value={experience.years}
                onChange={(e) =>
                  handleExperienceChange(index, 'years', e.target.value)
                }
                fullWidth
              />
            </div>
          ))}
          <BlackButton
            variant="contained"
            color="primary"
            onClick={handleAddExperience}
          >
            Add Experience
          </BlackButton>
        </div>
      ),
      onComplete: handleCompleteStep1,
    },
    {
      label: 'Step 2',
      content: <div>Step 2 Content (Placeholder)</div>,
    },
    {
      label: 'Step 3',
      content: <div>Step 3 Content (Placeholder)</div>,
    },
    {
      label: 'Step 4',
      content: <div>Step 4 Content (Placeholder)</div>,
    },
  ];

  return (
    <StyledContainer>
      <ContentContainer>
        <Typography variant="h4" align="center" gutterBottom>
          Application Form
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {steps[activeStep].content}
          <ButtonContainer>
            <Button
              className={BlackButton}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              className={BlackButton}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              Next
            </Button>
            {activeStep === steps.length - 1 && (
              <Button
                className={BlackButton}
                variant="contained"
                color="primary"
              >
                Finish
              </Button>
            )}
            {activeStep === 0 && (
              <Button
                className={BlackButton}
                variant="contained"
                color="primary"
                onClick={steps[activeStep].onComplete}
              >
                Complete
              </Button>
            )}
          </ButtonContainer>
        </div>
      </ContentContainer>
    </StyledContainer>
  );
}

export default Apply;
