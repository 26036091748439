import { useEffect } from "react"
import Landing from "../Landing/Landidng"


export default function Website(){
    useEffect(()=>{
        //window.location.href= "https://www.interviewable.com/recruiter/";
    },[])
    return(
      <Landing></Landing>

    )
}