/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
// import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
// import IconButton from '@mui/joy/IconButton';
import Settings from '@mui/icons-material/Settings';
import InputModal from '../../components/Modals/InputModal';
import { FolderApplicants, GetATS, SearchATS, deleteATS, getIntro, getJobs, singleFileUploader, upResume } from '../../services/toolsService';
import JobModal from '../../components/Modals/JobModal';
import Loading from '../Loading/Loading';
import FullScreenModal from '../../components/Modals/FullScreenModal';
import { Container } from '@mui/joy';
import { fileUpload, multiFileUploader } from '../../services/fileUploadService';
import ApplicantDetails from './ApplicatDetails';
import StarRating from '../../components/Rating';
import Timer from './Timer';
import StringList from './StringList';
import ATSTopBar from './ATSTopBar';
import ATSFolders from './ATSFolders';
import { Folder } from 'react-feather';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tag from '../../components/Kanban/Tags/Tag';
import TabContext from '../../components/ViewPort/TabContext';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(
  order,
  orderBy,
) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ATS(props) {
  const { tabs, setTabs, user, setUser } = React.useContext(TabContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState('desc');
  const [selected, setSelected] = React.useState(null);
  const [selectedUrl, setSelectedUrl] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [uploadResult, setUploadResult] = React.useState([]);
  const [filters,setFilters] = React.useState([]);
  const [duplicate, setDuplicate] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [searchSelector, setSearchSelector] = React.useState('skills');
  const [edit, setEdit] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(-1);
  const [remarks, setRemarks] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [folders, setFolders] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(()=>{
    setIsLoading(true);
GetATS(props.org).then((res)=>{
  setIsLoading(false);
console.log(res.data);
setRows(res.data);
setTabs([{resumes: res.data.length}]);
}).catch(()=>{

})
  },[])
 
  let config = {
    onUploadProgress: progressEvent => {
     
    }}
  
  const getFolderApplicants = (folderId) => {
    setIsLoading(true);
    if(folderId!= null){
    FolderApplicants({folder: folderId}).then((res)=>{
      setIsLoading(false);
    console.log(res.data);
    setRows(res.data);
    }).catch(()=>{
    
    })
  } else {
    GetATS(props.org).then((res)=>{
      setIsLoading(false);
    console.log(res.data);
    setRows(res.data);
    }).catch(()=>{
    
    })
  }
  }
  return (
    <div style={{marginBottom: 50, marginLeft: 10, marginRight: 10}}>{
     isLoading ? <Loading></Loading> : <React.Fragment>
     <ATSFolders getFolderApplicants={(val)=>{getFolderApplicants(val)}} folders={folders} setFolders={setFolders} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} org={props.org}></ATSFolders>
     <ATSTopBar setSelectedFolder={setSelectedFolder} selectedFolder={selectedFolder} folders={folders} org={props.org} search={search} setIsLoading={setIsLoading} setRows={setRows} checked={checked} rows={rows} selected={selected} selectedId={selectedId}
     selectedUrl={selectedUrl} setSelected={setSelected} setSelectedUrl={setSelectedUrl} view={view} setView={setView} setRemarks={setRemarks}
     remarks={remarks} setChecked={setChecked}  
     setSearchSelector={setSearchSelector} setSearch={setSearch} setOrder={setOrder} setDuplicate={setDuplicate} setOpen={setOpen} setProcessing={setProcessing} setUploaded={setUploaded} setUploadResult={setUploadResult}
     searchSelector={searchSelector} duplicate={duplicate} edit={edit} setEdit={setEdit}
     setSelectedId={setSelectedId}></ATSTopBar>
      
      {/* <Stack className='flexRow' spacing={2}>
      <Typography>Page: {page}</Typography>
      <Pagination count={10} page={page} onChange={handleChange} />
    </Stack> */}
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          // width: '100%',
          borderRadius: 'md',
          flex: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': (theme) =>
              theme.vars.palette.background.level1,
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': (theme) =>
              theme.vars.palette.background.level1,
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 48, textAlign: 'center', padding: 12 }}>
                <Checkbox
                  indeterminate={
                    checked.length > 0 && checked.length !== rows.length
                  }
                  checked={checked.length === rows.length}
                  onChange={(event) => {
                    setChecked(
                      event.target.checked ? rows.map((row) => row.id) : [],
                    );
                  }}
                  color={
                    checked.length > 0 || checked.length === rows.length
                      ? 'primary'
                      : undefined
                  }
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
              <th style={{ width: 100, padding: 12 }}>
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  fontWeight="lg"
                  endDecorator={<i data-feather="arrow-down" />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Name
                </Link>
              </th>
              <th style={{ width: 150, padding: 12 }}>Email</th>
              {/* <th style={{ width: 100, padding: 12 }}>Phone</th> */}
              <th style={{ width: 220, padding: 12 }}>Skills</th>
              {/* <th style={{ width: 150, padding: 12 }}>Rating</th> */}
              {/* <th style={{ width: 120, padding: 12 }}>Type</th> */}
              <th style={{ width: 160, padding: 12 }}> </th>
            </tr>
          </thead>
          <tbody>
            {/* {stableSort(rows, getComparator(order, 'id')) */}
            {rows.map((row,i) => (
              <tr key={i}>
                <td style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={checked.indexOf(row.id) !== -1}
                    color={'primary'}
                    onChange={(event) => {
                      setChecked((ids) =>
                        event.target.checked
                          ? ids.concat(row.id)
                          : ids.filter((itemId) => itemId !== row.id),
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
                <td>
                  <Typography fontWeight="md">{row.name}</Typography>
                </td>
                <td>{row.email}</td>
                {/* <td>
                
                   <Typography fontWeight="md">{row.phone}</Typography>
                </td> */}
                <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {/* <Avatar size="sm">{row.created}</Avatar> */}
                    <div class="marquee">
  {/* <div class="content"> */}
  {
                    row.skills.split(',').reduce((acc, skill, i) => (acc.totalChars + skill.length <= 30 ? (acc.tags.push(<Tag style={{marginLeft: '2px', scale: 0.3}} key={i} tagName={skill} color="#addbff"></Tag>), acc.totalChars += skill.length) : acc.totalChars < 30 && i === row.skills.split(',').length - 1 ? acc.tags.push(<Tag style={{marginLeft: '2px', scale: 0.3}} key="more" tagName="More" color="#addbff"></Tag>) : null, acc), {tags: [], totalChars: 0}).tags
                      
                      }
  {/* </div> */}
</div>
                    {/* <div style={{overflowX: 'hidden'}}> */}
                      {/* <Typography
                        fontWeight="lg"
                        level="body3"
                        textColor="text.primary"
                      >
                        {row.created}
                      </Typography> */}
                     
                       
                      {/* <Typography fontWeight="sm">{row.skills}</Typography> */}
                      {/* <Typography level="body3">{row.created}</Typography> */}
                    {/* </div> */}
                  </Box>
                </td>
                {/* <td>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <StarRating id={row.id}></StarRating>
                </Box>
                </td> */}
                {/* <td>{row.subscription}</td> */}
                <td>
              
                  <Link fontWeight="lg" component="button" sx={{ ml: 2 }} color="danger" onClick={()=>{
                setSelected(row);
                deleteATS({id: row.id}).then(()=>{
                  alert("Deleted");
                  window.location.reload();
                }).catch(()=>{})
}}>
                    Delete
                  </Link>
                 
                  <Link
                    fontWeight="lg"
                    component="button"
                    color="primary"
                    sx={{ ml: 2 }}
                    onClick={()=>{
                      setSelectedId(row.id);
                      setRemarks(JSON.parse(row.remarks));
                      setSelected(JSON.parse(row.output.trim()))
                      setSelectedUrl(row.url);
                      
                      setView(true)
                      

                    }}
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
       
      </Sheet>
     
    </React.Fragment>
    
}
<Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => {setOpen(false); if(uploaded){window.location.reload();}}}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
           <input
           multiple
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                      setProcessing(true);
                      const files = Array.from(e.target.files);
                      // for (var i = 0; i < e.target.files.length; i++) {
                    //  var element = e.target.files[i];
                    
                      // multiFileUploader(element,config,{token: 'B37vlz4qQea8GMr7h8IYJOSlffK8FCkOY8FSgK7kUi/pM+UQ0nPsyxp+p1gSubrnZqEeiERDdK8xbTVheEakXQ=='})
                      singleFileUploader(files,props.org, selectedFolder?.id)
                        .then(data => {
                          setUploaded(true);
                          setProcessing(false);
                          setUploadResult(data.data);
                        
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            // alert(err);
                            // this.setState({ ...this.state, showModal: false });
                          }
                        });
                      // }
                    }}
                    className="custom-file-input"
                    id="resume"
                  />
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            {processing ?  'Processing': uploaded ? 'Result' : 'Add Candidate'}
          </Typography>
          {processing ? <><Typography id="modal-desc" textColor="text.tertiary">
           {`The uploaded file is being processed - Time elapsed -`}
          </Typography><Timer seconds={seconds} setSeconds={setSeconds} uploadResult={uploadResult}></Timer></>:
          uploaded ? <StringList seconds={seconds} arrayOfStrings={uploadResult}></StringList> :
          <Typography id="modal-desc" textColor="text.tertiary">
           You could upload a resume and have Interviewable automatically pick your data or you could enter your details manually.
          </Typography>}
          { !processing ? <Box
            sx={{
              mt: 1,
              display: 'flex',
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row-reverse' },
            }}
          >
            {uploaded ? <Button variant="solid" color="primary" onClick={()=>{
              window.location.reload();
            }}>
             Ok
            </Button> : <Button variant="solid" color="danger" onClick={()=>{
              document.getElementById("resume").click();
            }}>
             Upload
            </Button>}
           
            {!uploaded ? <Button
              variant="outlined"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>: <></>}
          </Box> : <></>}
        </Sheet>
      </Modal>
</div>
  );
}
