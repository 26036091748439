import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardCover from '@mui/joy/CardCover';
import Typography from '@mui/joy/Typography';
import OrganizationModal from '../Modals/OrganizationModal';
import { getOrganizations } from '../../services/toolsService';
import AddUsersModal from '../Modals/AddUsersModal';

const Organizations = () => {
  // Sample profile data

  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false)
  const [openInvite, setOpenInvite] = useState(false)

  // Filter profiles based on search query
  const filteredOrgs = orgs.filter((profile) =>
    profile.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  useEffect(()=>{
    getOrganizations().then((res)=>{
      setOrgs(res.data)
    }).catch(()=>{})
  },[])
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Container style={{marginTop: 10}}>
      {/* <TextField
        fullWidth
        label="Search Profiles"
        variant="outlined"
        margin="normal"
        value={searchQuery}
        onChange={handleSearchChange}
      /> */}
      <OrganizationModal open={open} setOpen={setOpen}></OrganizationModal>
      <AddUsersModal open={openInvite} org={selectedOrg} setOpen={setOpenInvite}></AddUsersModal>
      <Grid container spacing={2}>
        {filteredOrgs.map((org, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card component="li" sx={{ width: 200, height: 200, flexGrow: 1 }} onClick={()=>{setOpenInvite(true); setSelectedOrg(org.id)}}>
        <CardCover>
          <img
            src={org.logo !='' ? org.logo : "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=400"}
            srcSet={org.logo !='' ? org.logo : "https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=400&dpr=2 2x"}
            loading="lazy"
            alt=""
          />
        </CardCover>
        <CardContent style={{marginTop: 40}}>
          <Typography
            level="body-lg"
            fontWeight="lg"
            // textColor=""
            mt={{ xs: 12, sm: 18 }}
          >
            {org.name}
          </Typography>
        </CardContent>
      </Card>

           
          </Grid>
        ))}
         <Grid item xs={12} sm={6} md={4} lg={3} onClick={()=>{setOpen(true)}}>
         <Card component="li" sx={{ width: 200, height: 200, flexGrow: 1 }}>
        <CardCover className="flexRow">
          <img
            style={{width: 120, height: 120}}
            src={"https://www.interviewable.com/add_org.png"}
            srcSet={"https://www.interviewable.com/add_org.png"}
            loading="lazy"
            alt=""
          />
        </CardCover>
        <CardContent>
          <Typography
            level="body-lg"
            fontWeight="lg"
            // textColor="black"
            mt={{ xs: 12, sm: 18 }}
          >
            {/* New Org */}
          </Typography>
        </CardContent>
      </Card>
            </Grid>
      </Grid>
    </Container>
  );
};

export default Organizations;
