import React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import ATS from './ATS';
import { getUserOrganizations } from '../../services/toolsService';

const ATSOrgs = () => {
    const [orgs, setOrgs] = React.useState([]);
    React.useEffect(()=>{
        getUserOrganizations().then((res)=>{

            
            setOrgs(orgs.concat(res.data));
        
           }).catch(()=>{})
    },[])

    return (
        // Your JSX code goes here
        <Tabs id="ats_tabs" style={{
          display: 'flex',
          flexBasis: 'fit-content'
        }} aria-label="Basic tabs" defaultValue={0}>
          <TabList>
            {orgs.map((x)=>(<Tab>{x.name}</Tab>))}
            
          </TabList>
          {
                orgs.map((x,i)=>(
                <TabPanel style={{   display: 'flex'}} value={i}>
                    <ATS org={x.id}></ATS>
                </TabPanel>
                ))
          }
          {/* <TabPanel value={0}>
            <ATS></ATS>
          </TabPanel>
          <TabPanel value={1}>
           
          </TabPanel>
          <TabPanel value={2}>
           
          </TabPanel> */}
        </Tabs>
    );
};

export default ATSOrgs;
