import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createFAQ, getFAQ } from '../../services/toolsService';
import Input from '@mui/joy/Input';

function Links(props) {

  return (
    <Container>
    
      <form>
        {props.links.map((data, index) => (
          <Grid container spacing={10} key={index}>
           
            <Grid item xs={9}>
              <Input
                placeholder="Link"
               
                
                value={data.link}
                onChange={(e) => props.handleLinkChange(index, e.target.value)}
              />
            </Grid>
           
            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.isVisible}
                    onChange={(e) => props.handleVisibility(index, e.target.checked)}
                    color="primary"
                  />
                }
                label="Hyperlink"
              />
            </Grid>
          </Grid>
        ))}
        {props?.viewMode == true ? <></> : <IconButton color="primary" onClick={props.handleAddLink}>
          <AddCircleIcon />
        </IconButton>}
      </form>
    </Container>
  );
}

export default Links;
