import Navbar from '../../components/Navbar/Navbar';
import StatsPane from '../../components/StatsPane/StatsPane';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import { useNavigate } from 'react-router-dom';


import ViewPort from '../../components/ViewPort/ViewPort';
import './Tools.css';
import HomeAction from '../../components/HomeActions/HomeAction';

import { faTasks, faTools, faMessage,faUserTie ,faPlug ,faFileArrowUp, faCalendarTimes, faNetworkWired, faUserGroup, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'



function Tools() {
  const navigate = useNavigate();
  return (
   <div className='home'>
   <div className='link_container'>
  <div className='quick-links'>
    <HomeAction color={'#fed5d1'} title={'Workflows'} icon={faTasks} nav={()=>{
      navigate('/workflows');
    }}></HomeAction>
    {/* <HomeAction color={'#fde3d4'} title={'Plugins'} icon={faPlug} ></HomeAction> */}
    <HomeAction color={'#e1f8db'} title={'Communications'} icon={faMessage} nav={()=>{
      navigate('/communications');
    }}></HomeAction>
    <HomeAction color={'#d6edff'} title={'Scheduler'} icon={faFileArrowUp} nav={()=>{navigate('/scheduler');}}></HomeAction>
  </div>
  </div>
  <div className='link_container'>
  <div className='quick-links'>
    <HomeAction color={'#fed5d1'} title={'Intros'} nav={()=>{
      navigate('/intros');
    }} icon={faUserTie}></HomeAction>
    {/* <HomeAction color={'#fde3d4'} title={'Schedule Interviews'} icon={faCalendarTimes} nav={()=>{
      navigate('/pre-interview');
    }} ></HomeAction> */}
    <HomeAction color={'#e1f8db'} title={'Pre Interviews'} nav={()=>{
      navigate('/pre-interview');
    }} icon={faUserGroup}></HomeAction>
    <HomeAction  nav={()=>{
      navigate('/forms');
    }} color={'#d6edff'} title={'Forms'} icon={faNetworkWired}></HomeAction>
  </div>
  </div>
  
   </div>
  );
}

export default Tools;
