import React, { useEffect,useContext, useState } from 'react'
import './share.css';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import TabContext from '../ViewPort/TabContext';
import { Button, Textarea } from '@mui/joy';
import { CreatePost } from '../../services/toolsService';

export default function Share({changeState}) {
    const {user, setUser} = React.useContext(TabContext);
    const [post, setPost] = useState('');
    useEffect(() => {
        console.log(user)
    },[])
  return (
    <div className='share' style={{height: 'max-content'}}>

        <div className="shareWrapper">
            <div className="shareTop">
            <img className='shareProfileImage' src={user.profilePic} alt="" />
            <div className="shareInputCont"></div>
            <Textarea placeholder={`What's on your mind, ${user.name} ?`} 
            onChange={(e)=>{
              setPost(e.target.value);
            }}
            className='shareInput' />
            </div>
            <hr className='shareHr' />
            <div className="shareButtom">
             <div className="shareOptions">
             {/* <div className="shareOption"> */}
             <Button variant="soft" size="sm" onClick={()=>{
              let data = {
                "visibility": "connections",
                "post": post
            }
            CreatePost(data).then((res)=>{
              changeState();
            }).catch((err)=>{});
             }}>
          Post to Organisation
        </Button> <Button variant="soft" size="sm" disabled>
          Post to Connections
        </Button>
        <Button variant="soft" size="sm" disabled>
          Post to Wall (Public)
        </Button>
                {/* </div> */}
                {/* <div className="shareOption">
                    <PermMediaIcon htmlColor='green' className='shareIcon'/>
                    <span className="shareOptionLongText">
                        Photo/video
                    </span>
                    <span className="shareOptionText">
                        Gallery
                    </span>
                </div>
                <div className="shareOption">
                    <EmojiEmotionsIcon htmlColor='orange' className='shareIcon'/>
                    <span className="shareOptionLongText">
                       Job
                    </span>
                    <span className="shareOptionText">
                       Job
                    </span>
                </div> */}
             </div>
            </div> 
             </div>

    </div>
  )
}
