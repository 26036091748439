import React, { useEffect, useState } from 'react';
import Folder from './Folder';
import { Typography } from '@mui/material';
import { GetATSFolders, getOrganizations } from '../../services/toolsService';

const GridWrapper = () => {
 const [orgs,setOrgs] = useState([]);
 const [folders, setFolders] = useState([]);
 useEffect(() => {
    getOrganizations().then((res) => {
      setOrgs(res.data);
      res.data.forEach((org) => { // Changed from map to forEach since you're not transforming the array
        GetATSFolders(org.id).then((folderRes) => {
          setFolders((prevFolders) => [...prevFolders, ...folderRes.data.filter((folder) => !prevFolders.some((prevFolder) => prevFolder.id === folder.id))]);
        }).catch((err) => {
          console.error(err); // Always good to handle errors
        });
      });
    }).catch((err) => {
      console.error(err); // Always good to handle errors
    });
  }, []);
  const divs = new Array(20).fill(<Folder></Folder>); // This will create 20 divs for example

  return (
    <div style={styles.gridContainer}>
      {folders.length === 0 ? (
        <div style={styles.noFoldersContainer}>
          <Typography>No folders found</Typography>
        </div>
      ) : (
        folders.map((item, index) => (
           
          <Folder folder={item} key={index} />
          
        ))
      )}
    </div>
  );
};

const styles = {
  gridContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    gap: '10px',
    padding: '10px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative', // Needed for absolute positioning of the noFoldersContainer
  },
  noFoldersContainer: {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    flex: 1, // Take up all available space
    position: 'absolute', // Position absolutely to the relative parent
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    textAlign: 'center', // Center text if it wraps to more than one line
  },
  // ... your other styles
};

export default GridWrapper;
