import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createFAQ, getFAQ } from '../../services/toolsService';
import Input from '@mui/joy/Input';
import DatePicker from 'react-date-picker';
import MonthYearPicker from './MonthYearPicker';

function ExperienceInput(props) {
  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };
  return (
    <Container style={{pointerEvents: props?.viewMode== true ? 'none': 'auto'}}>
    
      <form>
        {props.experiences.map((experience, index) => (
          <Grid container spacing={2} key={index}>
           
            <Grid item xs={3}>
           
              <Input
                placeholder="Employer"
               
                
                value={experience.employer}
                onChange={(e) => props.handleExperienceChange(index, 'employer', e.target.value)}
              />
            </Grid>
            <Grid item xs={3.5}>
            {/* <Input
           
        type="date"
        value={experience.startDate}
        onChange={(e) => props.handleExperienceChange(index, 'startDate', e.target.value)}
    
      /> */}
      <MonthYearPicker/>
            
            </Grid>
            <Grid item xs={3.5}>
            <Input
           disabled={experience.isPresent}
        type="date"
        value={experience.endDate}
        onChange={(e) => props.handleExperienceChange(index, 'endDate', e.target.value)}
       
      />
             
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={experience.isPresent}
                    onChange={(e) => props.handlePresentChange(index, e.target.checked)}
                    color="primary"
                  />
                }
                label="Present"
              />
            </Grid>
          </Grid>
        ))}
       { props?.viewMode == true ? <></> : <IconButton color="primary" onClick={props.handleAddExperience}>
          <AddCircleIcon />
        </IconButton>}
      </form>
    </Container>
  );
}

export default ExperienceInput;
