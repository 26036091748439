import { Button, Typography } from '@mui/joy';
import ilogo from '../../assets/images/ilogo.png';
import { useNavigate } from 'react-router-dom';

export default function Header(){
    const navigate = useNavigate();
    return(
        <div className="flexRow">
            <div className="flexRow" style={{width:(20/100)*window.innerWidth, height: (15/100)*window.innerHeight}}>
                <img src={ilogo}></img>
            </div>
            <div className="flexRow" style={{width:(50/100)*window.innerWidth, height: (15/100)*window.innerHeight, justifyContent: 'space-around'}}>
            <Typography fontWeight="lg" fontSize="lg">For Job Seekers</Typography>
            <Typography fontWeight="lg" fontSize="lg">For Recruiters</Typography>
            <Typography fontWeight="lg" fontSize="lg">About Us</Typography>
            <Typography fontWeight="lg" fontSize="lg">Contact Us</Typography>
            </div>
            <div className="flexRow" style={{width:(20/100)*window.innerWidth, height: (15/100)*window.innerHeight}}>
            <Button onClick={()=>{navigate('/login')}} variant="outlined" style={{borderRadius: 40}}>LOG IN</Button>
            <Button onClick={()=>{navigate('/signup')}} variant="solid" style={{marginLeft: 20, borderRadius: 40}}>GET STARTED</Button>
            </div>
        </div>
    )
}