import { useState, useEffect } from "react";
import OrgJob from "./OrgJob";
import Box from '@mui/joy/Box';
import { getCandiateOrgJobs, getApplications, getAccount, singleFileUploader, GetATS } from "../../services/toolsService";
import AlertDialogModal from "../../components/Modals/AlertDialogModal";
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { fileUpload } from "../../services/fileUploadService";




export default function CandidateOrgJobs(){

const [jobs, setJobs] = useState([]);
const [open, setOpen] = useState(false);
const [resume, setResume] = useState('');
const [applied, setApplied] = useState([]);
const [inCompleteProfile, setIncompleteProfile] = useState(false);
const [processing, setProcessing] = useState(false);
useEffect(()=>{

  getCandiateOrgJobs().then((res)=>{
    setJobs(res.data)
  }).catch(()=>{})

  getApplications().then((res)=>{
    console.log(res.data);
    setApplied(res.data);
    }).catch(()=>{
    
    })
  
    GetATS().then((res)=>{
    if(res.data.length==0){
      setOpen(true)
      setIncompleteProfile(true);
    }
    }).catch(()=>{});
    // getAccount().then((res)=>{
    //   console.log(res.data);
    //   setOpen(true)
    //   setIncompleteProfile((res.data.bio =='' && res.data.role == ''));
    // }).catch(()=>{})

   },[])
   let config = {
    onUploadProgress: progressEvent => {
     
    }}
return(
  <>
   <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
             <input
           multiple
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                      setProcessing(true);
                      const files = Array.from(e.target.files);
                      // for (var i = 0; i < e.target.files.length; i++) {
                    //  var element = e.target.files[i];
                    
                      // multiFileUploader(element,config,{token: 'B37vlz4qQea8GMr7h8IYJOSlffK8FCkOY8FSgK7kUi/pM+UQ0nPsyxp+p1gSubrnZqEeiERDdK8xbTVheEakXQ=='})
                      
                      singleFileUploader(files)
                        .then(data => {
                          //setUploaded(true);
                          setProcessing(false);
                          //setUploadResult(data.data);
                          setIncompleteProfile(false);
                          
                        
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            // alert(err);
                            // this.setState({ ...this.state, showModal: false });
                          }
                        });
                      // }
                    }}
                    className="custom-file-input"
                    id="resume"
                  />
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Incomplete Profile
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
           Your profile is incomplete, you could upload your resume and have Interviewable automatically pick your data or you could enter your details manually.
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row-reverse' },
            }}
          >
            <Button variant="solid" color="danger" onClick={()=>{
              document.getElementById("resume").click();
            }}>
             Upload
            </Button>
            {/* <Button variant="solid" color="warning" onClick={()=>{}}>
             Enter manually
            </Button> */}
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Sheet>
      </Modal>
    {jobs.map((job)=>(
        <OrgJob inCompleteProfile={inCompleteProfile} onApplyClick={()=>{}} applied={applied} link={job.id} logo={job.logo} title={job.name} desc={job.desc}></OrgJob>
      ))
    }
  </>    
)
}