import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import CenterLayout from './CenterLayout';
import ScrollCard from './ScrollCard';
import ScrollCard2 from './ScrollCard2';
import ScrollCard3 from './ScrollCard3';

export default function Hero2() {
  return (
    <CenterLayout>
        <div className='flexCol' style={{width: '100%'}}>
      {/* <Typography color="primary" fontSize="lg" fontWeight="lg">
        The power to do more
      </Typography> */}
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
       Hire faster & hire better!
      </Typography>
     <div className='flexRow' style={{paddingTop: 60,width: '100%',justifyContent: 'space-around'}}>
        <ScrollCard></ScrollCard>
        <ScrollCard2></ScrollCard2>
        <ScrollCard3></ScrollCard3>
        {/* <ScrollCard></ScrollCard> */}
     </div>

      {/* <Typography
        level="body-xs"
        sx={{
          position: 'absolute',
          top: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        HeroLeft01
      </Typography> */}
      </div>
    </CenterLayout>
  );
}