import styled from 'styled-components';

const Avatar = styled.img`
width: 60px;
height: 60px;
border-radius: 50%;
`;
const DashContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 10px;

margin-top: 20px;
margin-bottom: 20px;
width: 95%;
height: 100%;
background-color: white;
`;
const DashboardItem = styled.div`
display: flex;
width: 100%;
flex-direction: row;
border-radius: 10px;
align-items: center;
justify-content: center;
color: rgba(37,40,73,1);
margin-top: 10px;
margin-bottom: 10px;
height: 45px;
background-color: white;
`;
const MainContainer = styled.div`
display: flex;
flex-direction: row;
width: ${window.innerWidth}px;
height: ${window.innerHeight}px;
`;
const Color = styled.div`
height: 10px;
width: 10px;
`;
const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
background-color: white;
background-color: white;
`;
const DashNav = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
padding-bottom: 20px;
justify-content: space-between;
width: 100%;
height: 100%;
background-color: white;
`;
const BigText = styled.textarea`
width: 70%;
padding-left: 20px;
border-color: gainsboro;
height: 200px;
margin: 10px 0 10px 0;
border-style:solid;
border-radius: 15px;
`;
const Input = styled.input`
width: 70%;
padding-left: 20px;
border-color: gainsboro;
height: 40px;
margin: 10px 0 10px 0;
border-style:solid;
border-radius: 15px;
`;
const ButtonContainer = styled.div`
display: flex;
width: 70%;
flex-direction: row;
background-color: white;
justify-content: space-between;
padding: 20px 0 20px 0;
height: 50px;
`;
const Button = styled.div`
display: flex;
width: 100%;
flex-direction: column;
border-radius: 20px;
align-items: center;
justify-content: center;
color: white;
`;

const ResumeLeftContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding-top: ${(210/3)/6}mm; 
width: ${210/3}mm;
    /* border: 1px rgb(56, 51, 51) solid; */
height: 297mm;
`;
const ResumeAboutMe =  styled.div`
display: flex;
color: white;
flex-direction: column;
align-items: center;
padding-top: ${(210/3)/8}mm; 
padding-left: 20px;
padding-right: 20px;
width: ${210/3}mm;
`;
const ResumeRightContainer = styled.div`
display: flex;
flex-direction: column;
width: ${210*2/3}mm;

    /* border: 1px rgb(56, 51, 51) solid; */
height: 297mm;
`;
const ResumeRightHeader  = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: ${210*2/3}mm;
background-color: white;
height: ${297/7}mm;
`;
const ResumeRightBody  = styled.div`
display: flex;
flex-direction: column;
width: ${210*2/3}mm;
background-color: white;
min-height: ${297/6}mm;
`;
const ResumeBodyTitle = styled.div`
width: 100%;
height: 40px;
font-weight: 600;
padding-top: 10px;
padding-left: 5px;
background-color: white;
`;
const ResumeBodyContent = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
min-height: 60px;
font-weight: 500;
padding-top: 10px;
padding-left: 5px;
background-color: white;
`;
const ResumeLeftBodyBlock  = styled.div`
display: flex;
flex-direction: column;
padding-left: 10px;
width: ${(210*2/3)/2 - 25}mm;
`;
const ResumeRightBodyBlock  = styled.div`
display: flex;
flex-direction: column;
padding-left: 20px;
border-left: 1px solid black;
width: ${(210*2/3)/2+25}mm;
`;
const ResumeRightBlock  = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-left: 10px;
width: ${(210*2/3)/2}mm;
`;
const ResumeName = styled.div`
font-size: 30px;
width: ${(210*2/3)/2}mm;
font-weight: 800;
`;
const ResumeTitle = styled.div`
font-size: 20px;
width: ${(210*2/3)/2}mm;
font-weight: 600;
`;
const ResumeAddress = styled.div`
font-size: 18px;
width: ${(210*2/3)/2}mm;
padding-left: ${(210*2/3)/10}mm;
font-weight: 400;
`; 
const ProfilePic = styled.img`
height: ${210/3-30}mm;
border-radius: ${(210/3-30)/2}mm;
width: ${210/3-30}mm;
`;
export {
    MainContainer,
    Container,
    Input,
    ButtonContainer,
    Button,
    ResumeLeftContainer,
    ResumeRightContainer,
    ProfilePic,
    ResumeRightHeader,
    ResumeRightBlock,
    ResumeName,
    ResumeTitle,
    ResumeAddress,
    ResumeRightBody,
    ResumeBodyTitle,
    ResumeBodyContent,
    ResumeRightBodyBlock,
    ResumeLeftBodyBlock,
    ResumeAboutMe,
    Color,
    DashboardItem,
    DashNav,
    Avatar,
    DashContainer,
    BigText
}