import Box from '@mui/joy/Box';

import { Container } from '@mui/joy';
import StatCard from './StatCard';
import BarChart from './BarChartDisplay';
import BarChartDisplay from './BarChartDisplay';
import data from './data';


export default function Dashboard(){
    return (
        <Container>
            <div className='flexCol'>
            <div className="flexRow" style={{width: '100%' ,marginTop: 30,justifyContent: 'space-around', alignItems: 'center'}}>
           
     {
        ['Jobs','Applications','Stacks','Connects'].map((x)=>(<StatCard name={x}></StatCard>))
     }
            </div>
            <div className="flexRow" style={{width: '100%' ,height: '100%',marginTop: 30,justifyContent: 'space-around'}}>
            
            <BarChartDisplay style={{width: 500 ,height: 150}} data = {data}></BarChartDisplay>
            </div>
            </div>
        </Container>
    )
}