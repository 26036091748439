import { LinearProgress } from "@mui/joy";


export default function Loading(props){
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',width: window.innerWidth, height: window.innerHeight}}>
              <div style={{height: '10%', width: '30%'}}>
               <LinearProgress></LinearProgress>
               </div>
        </div>
    )
}