import * as React from 'react';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';

function createData(
  name,
  calories,
  fat,
  carbs,
  protein,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Salim Malik', 159, 6.0, 24, 4.0),
  createData('Nagalakshmi Repana', 237, 9.0, 37, 4.3),
  createData('Sudhanshu Pratap Singh', 262, 16.0, 24, 6.0),
  createData('Vikash', 305, 3.7, 67, 4.3),
  createData('ARATHI A S', 356, 16.0, 49, 3.9),
];

export default function RecentApplicants() {
  return (
    <Sheet
     
      sx={{
        pt: 1,
        borderRadius: 'sm',
        
      }}
    >
      <Table hoverRow>
        <caption>Applicants</caption>
        <thead>
          <tr>
            <th style={{ width: '40%' }}>Name</th>
            <th>Source</th>
            <th>Action</th>
           
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.name}>
              <td>{row.name}</td>
              <td>{row.calories}</td>
              <td>{row.carbs}</td>
             
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
}