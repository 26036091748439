import React from 'react';

const StringList = ({ seconds, arrayOfStrings }) => {
  const renderStringList = () => {
    return arrayOfStrings.map((string, index) => (
      <li key={index}>{string == 'Parsed' ? 'The file has been uploaded' : string}</li>
    ));
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  return (
    <div>
        <span>{`Completed in ${formatTime(seconds)}`}</span>
      <ul>
       The result's will be sent to you by email shortly.
      </ul>
    </div>
  );
};

export default StringList;
