

import './CommunicationList.css';
import ListItems from '../../../../components/List/List';
import OrderTable from '../../../../components/List/OrderTable';
import CommunicationsTable from './CommunicationsTable';




function CommunicationsList() {
  return (
    <div className='intros_container'>
    <ListItems table={<CommunicationsTable></CommunicationsTable>}></ListItems>
   </div>
  );
}

export default CommunicationsList;
