import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Organizations from '../Account/Organizations';

import { getAppliedCandidates, getAppliedCandidatesByJob } from '../../services/toolsService';
import MyProfile from '../Account/MyProfile';


export default function CandidateViewModal(props) {
    const [layout, setLayout] = React.useState('fullscreen');
    React.useEffect(()=>{
        console.log(props.candidate)
    },[props])
  return (
    <React.Fragment>
      <Stack direction="row" spacing={1}>
       
      </Stack>
      <Modal open={props.open} onClose={() => props.setOpen(false)} >
        <ModalDialog layout={layout}>
          <ModalClose />
        <div style={{maxWidth: '95%', overflowY: 'scroll'}}>
        <MyProfile username = {props?.candidate?.username} viewMode={true}></MyProfile>
        </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}