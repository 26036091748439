import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { ModalClose, Textarea } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { createIntro, createJob, getUserOrganizations, getWorkflows, updateJob } from '../../services/toolsService';
import WEditor from '../WYSIWYG/WEditor';
import JobWizard from './JobWizard';


export default function JobModal(props) {
   const [name, setName] = React.useState('');
   const [org, setOrg] = React.useState('personal');
   const [desc, setDesc] = React.useState(props.edit? props.selected.description : '');
   const [workflows, setWorkflows] = React.useState([]);
   const [workflow, setWorkflow] = React.useState(null);
   const [endDate, setEndDate] = React.useState(new Date());
   const [orgs, setOrgs] = React.useState([{id: 'personal', name: 'My Account'}]);
   React.useEffect(()=>{
   getWorkflows().then((res)=>{
    setWorkflows(res.data);
   }).catch(()=>{})
   getUserOrganizations().then((res)=>{

    setOrgs(res.data);

   }).catch(()=>{})
   if(props.edit){
    setName(props.selected.name)
    setDesc(props.selected.description)
   }
   },[props])
   const handleChange = (event, newValue) => {
    //alert(`You chose "${newValue}"`);
    setOrg(newValue)
  };
  return (
    <React.Fragment>
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog
        layout={'fullscreen'}
        style={{overflowY: 'scroll'}}
          // aria-labelledby="basic-modal-dialog-title"
          // aria-describedby="basic-modal-dialog-description"
          // sx={{ width: '60%'}}
        >
          <ModalClose/>
          {props.view ?<>
          <Typography id="basic-modal-dialog-title" component="h2">
            {props?.edit ? 'Update Job' : 'Add new job'}
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
          {props?.edit ? 'Update Job Details' : 'Create a job for your client'}
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              props.setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input  value={ name} onChange={(e)=>{
                
                  setName(e.target.value)
                
                  }} autoFocus required />
              </FormControl>
              <FormControl>
                <FormLabel>Under</FormLabel>
                <Select value={props.edit? props.selected.org_id : org} disabled={props.edit} defaultValue="personal" onChange={handleChange}>
                <Option value={'personal'}>{'My Account'}</Option>
      {orgs.map((y)=>(
 <Option value={y.id}>{y.name}</Option>
      ))}
     
      
    </Select>
              </FormControl>
              {/* <FormControl>
                <FormLabel>Workflow</FormLabel>
                <Select disabled={props.view}  onChange={(e, value)=>{
                  setWorkflow(value)
                }}>
                 {
                    workflows.map((x)=>(
                        <Option value={x.id}>{x.name}</Option>
                    ))
                 }
     
     
             </Select>
              </FormControl> */}
              {/* <FormControl>
              <FormLabel>Valid till</FormLabel>
             <input type='date' onChange={(e)=>{setEndDate(e.target.value)}}></input>
              </FormControl>  */}
              <FormControl>
                <FormLabel>Description</FormLabel>
                <WEditor desc={desc} onChange={(e)=>{setDesc(e.target.value)}}></WEditor>
                {/* <Textarea  onChange={(e)=>{setDesc(e.target.value)}} value={desc} minRows={4} /> */}
              </FormControl>
              <Stack spacing={2}>

              </Stack>
              { !props.view ? <Button type="submit" onClick={()=>{
                if(!props.edit){
                let obj = {
                  name,
                  organization: org,
                  description: desc,
                  endDate,
                  workflow
                }
                console.log(obj);
                createJob(obj).then(()=>{}).catch(()=>{});
              } else {
                let obj = {
                  name,
                  description: desc,
                  id: props.selected.id
                }
                console.log(obj);
                updateJob(obj).then(()=>{}).catch(()=>{});
              }
                // createIntro(obj).then((res)=>{
                //   console.log(res);
                //   props.setOpen(false);

                // }).catch(()=>{})
              }}>{props.edit ? 'Update' : 'Submit'}</Button> : ''}
            </Stack>
          </form>
          </> : <JobWizard props={props}></JobWizard>
          
          }
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}