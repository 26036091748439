import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip, { chipClasses } from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import DropZone from './DropZone';
import FileUpload from './FileUpload';
import CountrySelector from './CountrySelector';
import EditorToolbar from './EditorToolbar';
import { fileUpload } from '../../services/fileUploadService';
import AccountsTab from './AccountTabs';
import { TabPanel } from '@mui/joy';
import Organizations from './Organizations';
import TeamCreation from './TeamCreation';
import Teams from './Teams';
import ExperienceInput from '../ExperienceInput/ExperienceInput';
import { GetATS, createAccount, createEducation, createFAQ, getAccount, getCandidateEducation, getCandidateFAQ, getCandidateResume, getEducation, getFAQ, getResume, getUserProfile, updateResume, updateResumeJSON, updateUserProfile } from '../../services/toolsService';
import PersonalIntro from './PersonalIntro';
import CandidateView from '../../pages/CandidateView/CandidateView';
import EducationInput from '../ExperienceInput/EducationInput';
import UserCard from '../../pages/CandidateView/UserCard';
import LinksAndPortfolio from './LinksAndPortfolio';

export default function MyProfile(props) {
  const [id, setId] = React.useState('');
  const [experience, setExperience] = React.useState([]);
  const [education, setEducation] = React.useState([])
  const [resume, setResume] = React.useState('');
  const [skills, setSkills] = React.useState('');
  const [output, setOutput] = React.useState(null);

  React.useEffect(()=>{
    getUserProfile().then((res)=>{
      console.log(res.data)
      let json = res.data;
      console.log(json);
      setOutput(json)
      // setId(res.data[0].id);
      setExperience(json.experience);
      setEducation(json.education);
      setSkills(json.skills);
      //setResume(json.url);

    }).catch(()=>{});

  //   if(props?.viewMode == true){
  //   getCandidateFAQ({username: props?.username}).then((res)=>{
  //     setExperiences(JSON.parse(res.data.data));
  //   }).catch(()=>{})

  //   getCandidateEducation({username: props?.username}).then((res)=>{
  //     setEducation(JSON.parse(res.data.data));
  //   }).catch(()=>{})

  //   getCandidateResume({username: props?.username}).then((res)=>{
  //     setResume(res?.data?.resume == null ? '' : res?.data?.resume);
  //   }).catch(()=>{})
    
  //   } else {
  //   getFAQ().then((res)=>
  //   {
  //  setExperiences(JSON.parse(res.data.data));
  //   }).catch(()=>{})

  //   getEducation().then((res)=>
  //   {
  //  setEducation(JSON.parse(res.data.data));
  //   }).catch(()=>{})
  //   getResume().then((res)=>{
  //   setResume(res?.data?.resume == null ? '' : res?.data?.resume);
  //   }).catch(()=>{})
  // }
  },[])

  // const handleAddExperience = () => {
  //   setExperiences([...experiences, { employer: '', startDate: '', endDate: '', isPresent: false }]);
  // };

  const handleExperienceChange = (name, value, i) => {
    // Create a new array with the updated value
    const updatedExperience = [...experience];
    updatedExperience[i] = {
      ...updatedExperience[i],
      [name]: value,
    };
    
    // Update the state with the new array
    setExperience(updatedExperience);
  };

  const handlePresentChange = (index, isChecked) => {
    //const updatedExperiences = [...experiences];
    //updatedExperiences[index].isPresent = isChecked;
    //updatedExperiences[index].endDate = isChecked ? 'Present' : '';
    //setExperiences(updatedExperiences);
  };
  const handleSave = () => {
    // You can save the experiences data here
    //console.log('Experiences:', experiences);
    // let data = {
    //     json: JSON.stringify(experiences)
    // }
    // createFAQ(data).then(()=>{}).catch(()=>{})
  };

  //education

  const handleAddEducation = () => {
    setEducation([...education, { employer: '', startDate: '', endDate: '', isPresent: false }]);
  };

  const handleEducationeChange = (index, field, value) => {
    // const updatedExperiences = [...education];
    // updatedExperiences[index][field] = value;
    // setEducation(updatedExperiences);
  };

  const handleEducationChange = (name, value, i) => {
    // Create a new array with the updated value
    const updatedEducation = [...education];
    updatedEducation[i] = {
      ...updatedEducation[i],
      [name]: value,
    };
    
    // Update the state with the new array
    setEducation(updatedEducation);
  };

  const handleEducationPresentChange = (index, isChecked) => {
    const updatedExperiences = [...education];
    updatedExperiences[index].isPresent = isChecked;
    updatedExperiences[index].endDate = isChecked ? 'Present' : '';
    setEducation(updatedExperiences);
  };
  const renderEducationContent = (content) => (
    <section>
    <ul>
      {content.map((edu, index) => (
        <div>
          <label>
            Name:
            <Input name='name' type="text" value={edu.name} onChange={(e)=>{handleEducationChange(e.target.name,e.target.value, index)}} />
          </label>
          <br></br>
          <label>
            Institution:
            <Input name='institution' type="text" value={edu.institution}  onChange={(e)=>{handleEducationChange(e.target.name,e.target.value, index)}}/>
          </label>
          <br></br>
          <label>
            Date:
            <Input name='date' type="text" value={edu.date}  onChange={(e)=>{handleEducationChange(e.target.name,e.target.value, index)}}/>
          </label>
          
        </div>
      ))}<Button onClick={()=>{

        let tempEducation = [...education];
        tempEducation.push({
          name: '',
          institution: '',
          date: ''
        });
        setEducation(tempEducation);
      }}>Add Education</Button>
    </ul>
  </section>
  );
  const renderExperienceContent = (content) =>(
    <section>
    <ul>
      {content.map((exp, index) => (
        <div>
          <label>
            Institution:
            <Input name='name' type="text" value={exp.name} onChange={(e)=>{handleExperienceChange(e.target.name, e.target.value, index)}}/>
          </label>
          <br></br>
          <label>
            Place:
            <Input name='place' type="text" value={exp.place} onChange={(e)=>{handleExperienceChange(e.target.name, e.target.value, index)}} />
          </label>
          <br></br>
          <label>
            Date:
            <Input name='date' type="text" value={exp.date} onChange={(e)=>{handleExperienceChange(e.target.name, e.target.value, index)}} />
          </label>
       </div>
      ))} <Button onClick={()=>{
        let tempExperience = [...experience];
        tempExperience.push({
          name: '',
          place: '',
          date: ''
        });
        setExperience(tempExperience);
      }}>Add Experience</Button>
    </ul>
  </section>
  

  )
  const handleEducationSave = () => {
    // You can save the experiences data here
    console.log('Experiences:', education);
    let data = {
        json: JSON.stringify(education)
    }
    createEducation(data).then(()=>{}).catch(()=>{})
  };

  //education
  let config = {
    onUploadProgress: progressEvent => {
     
    }
  }
  return (
    <Sheet
      sx={{
        bgcolor: 'background.body1',
        flex: 1,
        maxWidth: 1200,
        width: '100%',
        paddingBottom: '50px',
        mx: 'auto',
      }}
    >
      {/* <Typography level="h1" fontSize="xl2" sx={{ mb: 1 }}>
        My profile
      </Typography> */}
     
        <Box
          sx={{
            '--_shadow-height': '16px',
            height: 0,
            position: 'sticky',
            top: 'calc(var(--Tab-height) - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
            zIndex: 1,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'relative',
              zIndex: 1,
              height: 'var(--_shadow-height)',
              // background:
              //   'radial-gradient(closest-side, rgba(0 0 0 / 0.12), transparent 100%)',
            },
          }}
        />
        {/* <UserCard username={props?.username} viewMode={props?.viewMode !=null ? props.viewMode : false}></UserCard> */}
        <AccountsTab 
        viewMode={props?.viewMode !=null ? props.viewMode : false}
        tab={
          [
          <TabPanel value={0}>
          {/* <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button>Personal Info</Button> <Button style={{marginLeft: '10px'}}onClick={()=>{window.scrollTo({top: 1000, behavior: "smooth"})}}>Work Experience</Button>
          </div> */}
             <Box
          
          sx={{
            pt: 3,
            pb: 10,
            display: 'grid',
            gridTemplateColumns: {
              xs: '100%',
              sm: 'minmax(120px, 30%) 1fr',
              lg: '280px 1fr minmax(120px, 208px)',
            },
            columnGap: { xs: 2, sm: 3, md: 4 },
            rowGap: { xs: 2, sm: 2.5 },
            '& > hr': {
              gridColumn: '1/-1',
            },
          }}
        >
        
        

         
          <Box>
            <FormLabel>Work History</FormLabel>
            <FormHelperText>Share your past experiences.</FormHelperText>
          </Box>
          <Stack useFlexGap spacing={1.5}>
          {/* <ExperienceInput viewMode={props.viewMode} handleAddExperience={handleAddExperience} handleExperienceChange={handleExperienceChange}
          handlePresentChange={handlePresentChange}
          experiences={experiences}></ExperienceInput> */}
           {renderExperienceContent(experience)}

          </Stack>
          
          <Divider role="presentation" />
          <Box>
            <FormLabel>Skills</FormLabel>
            <FormHelperText>Share your skills.</FormHelperText>
          </Box>
          <Stack useFlexGap spacing={1.5}>
          {/* <ExperienceInput viewMode={props.viewMode} handleAddExperience={handleAddExperience} handleExperienceChange={handleExperienceChange}
          handlePresentChange={handlePresentChange}
          experiences={experiences}></ExperienceInput> */}
          <Textarea style={{ minHeight: 200 }} value={skills} onChange={(e) => setSkills(e.target.value)} />

          </Stack>
          <Divider role="presentation" />
          <Box>
            <FormLabel>Education</FormLabel>
            <FormHelperText>Share your education.</FormHelperText>
          </Box>
          <Stack useFlexGap spacing={1.5}>
          {/* <EducationInput viewMode={props.viewMode} handleAddExperience={handleAddEducation} handleExperienceChange={handleEducationeChange}
          handlePresentChange={handleEducationPresentChange}
          experiences={education}></EducationInput> */}
           {renderEducationContent(education)}

          </Stack>
          <Divider role="presentation" />
           <Box>
            <FormLabel>Resume</FormLabel>
            <FormHelperText>Share an optional resume.</FormHelperText>
          </Box>
          <Stack useFlexGap spacing={1.5}>
          <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                    
                      fileUpload(e,config,{token: 'B37vlz4qQea8GMr7h8IYJOSlffK8FCkOY8FSgK7kUi/pM+UQ0nPsyxp+p1gSubrnZqEeiERDdK8xbTVheEakXQ=='})
                        .then(data => {
                        //  let userFiles = [...projects];
                        //  userFiles.push(data);
                         setResume(data);
                        })
                        .catch((err) => {
                          if(err=="You have exceeded your storage quota. Please Upgrade."){
                            // alert(err);
                            // this.setState({ ...this.state, showModal: false });
                          }
                        });
                    }}
                    className="custom-file-input"
                    id="portfolio"
                  />
             {props?.viewMode == true ? <></> : <DropZone profilePicUpload={()=>{ document.getElementById("portfolio").click();}}/>}
{ resume.length>0? <FileUpload
    fileName={resume.split('files/')[1].replace(/%20/g,' ')}
    fileSize=""
    progress={100}
  />:<></>
  // projects.map((x)=>(
  //   <FileUpload
  //   fileName={x.split('files/')[1].replace(/%20/g,' ')}
  //   fileSize=""
  //   progress={100}
  // />
  // ))
}
          

          </Stack>

          <Divider role="presentation" />

          { props.viewMode == true ? <></> : <Box
            sx={{
              gridColumn: '1/-1',
              justifySelf: 'flex-end',
              display: 'flex',
              gap: 1,
            }}
          >
            <Button variant="outlined" color="neutral" size="sm">
              Cancel
            </Button>
            <Button style={{marginTop: 20}} onClick={()=>{
          
          
            updateUserProfile({profile: JSON.stringify({...output, skills, experience, education})}).then((res)=>{
              alert('Updated');
            }).catch(()=>{})
          }}>Update</Button>
          </Box>}
        </Box>
       
          </TabPanel>,
          <TabPanel value={2}><Organizations></Organizations></TabPanel>,
          <TabPanel value={1}><PersonalIntro username={props?.username} viewMode={props.viewMode}></PersonalIntro></TabPanel>,
          <TabPanel value={3}><LinksAndPortfolio username={props?.username} viewMode={props.viewMode}></LinksAndPortfolio></TabPanel>,
          // <TabPanel value={4}><LinksAndPortfolio></LinksAndPortfolio></TabPanel>
          ]
        }
        ></AccountsTab>
       
    </Sheet>
  );
}
