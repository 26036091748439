import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'

export default function StarRating(props) {

  // Catch Rating value
  const handleRating = (rate) => {
    props.setRating(rate)

    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)

  return (
   
      <Rating
        size ={20}
        initialValue={props.rating}
        onClick={handleRating}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        onPointerMove={onPointerMove}
        readonly={props.setRating ? false : true}
        /* Available Props */
      />
   
  )
}