import "./rightbar.css";
import React, { useEffect, useState } from 'react'; // Import useState as well
import { Users } from '../../dummyData';
import OnlineFriend from "../online/OnlineFriend";
import StatsCard from "./StatsCard";
import { GetStats } from "../../services/toolsService";
import { NavLink } from "react-router-dom";

function Rightbar() {
  const [stats, setStats] = useState({
    connections: 0,
    resumes: 0,
    jobs: 0
  });

  useEffect(() => {
    GetStats().then((res) => {
      // Assuming res.data contains the stats in a similar structure
      setStats(res.data);
    }).catch((err) => {
      console.error(err);
    });
  }, []);

  // Utility function to format the count
  const formatCount = (count) => count < 10 ? `0${count}` : `${count}`;

  return (
    <div className="rightbar">
      <div className="rightbarWrapper">
      <NavLink
    to={`/connections`}
    style={{textDecoration: 'none'}}
  >
        <StatsCard name={'Connections'} count={formatCount(stats.connections)} buttonText='Go to Connections'></StatsCard>
        </NavLink>
        <NavLink
    to={`/ats`}
    style={{textDecoration: 'none'}}
  >
        <StatsCard name={'Resumes'} count={formatCount(stats.resumes)} buttonText='Go to Resumes'></StatsCard>
        </NavLink>
        <NavLink
    to={`/jobs`}
    style={{textDecoration: 'none'}}
  >
        <StatsCard name={'Jobs'} count={formatCount(stats.jobs)} buttonText='Go to Jobs'></StatsCard>
        </NavLink>
      </div>
 
      <hr className="rightbarHr" />
    </div>
  );
}

export default Rightbar;
