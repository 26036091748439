

import './FormsList.css';
import ListItems from '../../../../components/List/List';
import FormsTable from './FormsTable';




function FormsList() {
  return (
    <div className='pre_container'>
    <ListItems table={<FormsTable></FormsTable>}></ListItems>
   </div>
  );
}

export default FormsList;
