import * as React from 'react';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import Sheet  from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

import ButtonGroup from '@mui/joy/ButtonGroup';

import { BulkATSDelete, DeleteATSFolder, GetATS, MoveATSFolder, SearchATS} from '../../services/toolsService';

import ApplicantDetails from './ApplicatDetails';


const ATSTopBar = (props) => {
    const [filters,setFilters] = React.useState([]);
    const [isMoveToFolder, setIsMoveToFolder] = React.useState(false);
    const [moveToFolder, setMoveToFolder] = React.useState('');
    return (
        <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          justifyContent: 'space-between',
          py: 2,
          display: {
            xs: 'none',
            sm: 'flex',
          },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
         <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isMoveToFolder}
        onClose={() => setIsMoveToFolder(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Select a folder to Move
          </Typography>
          <Select onChange={(e,x)=>{
            setMoveToFolder(x);
          }}>
            {
                props.folders.map((item)=>(<Option value={item.id}>{item.name}</Option>))
            }
      
     
    </Select>
    <Button variant='outlined' color='primary' onClick={()=>{
     MoveATSFolder({folder: moveToFolder, ids: props.checked}).then((res)=>{
        setIsMoveToFolder(false);
        props?.setChecked([]);
     }).catch(()=>{});
    }}>Move</Button>
        </Sheet>
      </Modal>
        <div className="flexRow">
        <FormControl sx={{ flex: 0.2 }} size="md">
          <FormLabel>Search</FormLabel>
          <Input placeholder="Search" 
          onChange={(e)=>{props.setSearch(e.target.value)}}
          startDecorator={<i data-feather="search" />} />
        </FormControl>
        <FormControl sx={{ flex: 0.2 }} style={{marginLeft: 10}}size="md">
        <FormLabel style={{color: 'white'}}>Selector</FormLabel>
          <Select defaultValue="others" onChange={(e,x)=>{
            props.setSearchSelector(x)
          }}>
      <Option value="name">Name</Option>
      <Option value="email">Email</Option>
      <Option value="phone">Phone</Option>
      <Option value="skills">Skills</Option>
      <Option value="others">Others</Option>
    </Select>
        </FormControl>
        <FormControl sx={{ flex: 0.2 }} style={{marginLeft: 10}}size="md">
        <FormLabel style={{color: 'white'}}>Search</FormLabel>
       
        <Button variant='outlined' color='primary' onClick={()=>{
         //new logic
         let tempFilters = filters.concat([]);
         if(tempFilters.filter((item)=>item.search==props.search).length==0){
         tempFilters.push({search: props.search, selector: props.searchSelector});
            setFilters(tempFilters);
         }

         //

         let data = {search: tempFilters, org: props.org};
         SearchATS(data).then((res)=>{
          props.setIsLoading(false);
        props.setRows(res.data);
        }).catch(()=>{});
      }}>Add Filter</Button>
        </FormControl>
      
        {filters.length>0?<FormControl sx={{ flex: 0.2 }} style={{marginLeft: 10}}size="md">
        <FormLabel style={{color: 'white'}}>Search</FormLabel>
       
        <Select style={{backgroundColor: 'black', color: 'white'}}  placeholder={`Filters=${filters.length}`}>
            {filters.map((item,index)=>(
                <Option><span>{item.selector} like '{item.search}' <span style={{color: 'red'}} onClick={()=>{
                    let tempFilters = filters.concat([]);
                    tempFilters.splice(index,1);
                    setFilters(tempFilters);
                }}>remove</span></span></Option>
                )
            )}
      {/* <Option value="name">Name like 'Hashif'</Option>
      <Option value="email">Email like 'Jurassic'</Option>
      <Option value="phone">Skills like 'C#'</Option>
      <Option value="skills">Skills</Option>
      <Option value="experience">Experience</Option> */}
    </Select>
        </FormControl>:<></>}
        </div>
       
       
        <React.Fragment>
    
    <Modal open={props.view} onClose={() => props.setView(false)} >
        <ModalDialog layout={'fullscreen'}>
          {/* <ModalClose /> */}
        <div className="flexCol" style={{height: window.innerHeight, boxSizing: 'border-box',overflowY: 'scroll', alignItems: 'center'}}>
         {
          props.selected!= null ? <ApplicantDetails remarks={props.remarks} setRemarks={(val)=>{props.setRemarks(val)}} id={props.selectedId} close={()=>{props.setView(false); window.location.reload()}} applicantData={props.selected} url={props.selectedUrl}></ApplicantDetails>
          : <></>
         }
        </div>
        </ModalDialog>
      </Modal>
      {/* {props.rows.length} resumes */}
    <ButtonGroup style={{height: 'max-content', marginTop: 'auto'}} spacing="1rem" aria-label="spacing button group">
      
    {props.checked.length>0 ? <>
    <Button variant='outlined' color='danger' 
    onClick={()=>{
      BulkATSDelete({ids: props.checked}).then((res)=>{
        props.setIsLoading(true);
        GetATS(props.org).then((res)=>{
          props?.setChecked([]);
          props.setIsLoading(false);
        console.log(res.data);
        props.setRows(res.data);
        }).catch(()=>{});
      }).catch(()=>{});
    }}
    >Bulk Delete</Button>
    <Button variant='outlined' color='success' >Assign to Board</Button>
    <Button variant='outlined' color='success' 
    onClick={()=>{
     setIsMoveToFolder(true);
    }}
    >Move to Folder</Button>
    {/* <Button variant='outlined' color='success' >Add Tag</Button> */}
    </>:<></>}
    {props?.selectedFolder?.id != null ? <Button variant='outlined' color='success' onClick={()=>{
      DeleteATSFolder({folder: props?.selectedFolder?.id}).then((res)=>{
        props?.setSelectedFolder(null);
        props.setIsLoading(true);
        GetATS(props.org).then((res)=>{
          props.setIsLoading(false);
        console.log(res.data);
        props.setRows(res.data);
        }).catch(()=>{
        
        })
      }).catch(()=>{});
    }}>{`Delete ${props?.selectedFolder?.name}`}</Button>:<></>}
    <Button variant='outlined' color='primary' onClick={()=>{
           props.setIsLoading(true);
           GetATS(props.org).then((res)=>{
             props.setIsLoading(false);
           console.log(res.data);
           props.setRows(res.data);
           }).catch(()=>{
           
           })
      }}>Clear Filters</Button>
       {props?.selectedFolder?.id != null ? <Button onClick={()=>{
        props.setProcessing(false);
        props.setDuplicate('');
        props.setOpen(true);
      }}>{`Add Candidate to ${props?.selectedFolder?.name}`}</Button>:
      <Button variant='solid' color='danger' onClick={()=>{
        props.setProcessing(false);
        props.setDuplicate('');
        props.setOpen(true);
      }}>Add Candidate</Button>}
      {/* <IconButton>
        <Settings />
      </IconButton> */}
    </ButtonGroup>
    </React.Fragment>
        
      </Box>
    );
};

export default ATSTopBar;
