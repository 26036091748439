import Post from "../post/Post";
import "./feed.css";
import Share from "../share/Share";
// import axios from 'axios'
// import { useEffect,useState } from "react";
import { Posts } from '../../dummyData';
import { GetPosts } from "../../services/toolsService";
import { useEffect, useState } from "react";

export default function Feed({changeState}) {
  const[posts,setPosts] = useState([]);
  useEffect(()=>{
    GetPosts().then((res)=>{
      setPosts(res.data);
    }).catch((err)=>{});
  },[]);
  const updatePosts = () =>{
    GetPosts().then((res)=>{
      setPosts(res.data);
    }).catch((err)=>{});
  }
  return (
    <div className="feed">
      <div className="feedWrapper">
      <Share  changeState={updatePosts}/>
      {posts.map((p)=>(
               <Post key={p.id} post={p}/>
      ))}
      </div>
    </div>
  );
}
