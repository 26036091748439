
import { text } from '@fortawesome/fontawesome-svg-core';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function NavButton(props) {
  return (
  
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
   <div  onClick={props.click} className='navTab tabButton'>
    {props?.icon}
    <span className='iconText common'>{props?.text}</span>
   </div>
   <span></span>
   </div>
  );
}

export default NavButton;
