import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import CenterLayout from './CenterLayout';
import ScrollCard from './ScrollCard';
import better_videos from '../../assets/images/better_videos.png';

export default function Hero7() {
  return (
    <CenterLayout>
        <div className='flexCol' style={{width: '100%'}}>
      {/* <Typography color="primary" fontSize="lg" fontWeight="lg">
        The power to do more
      </Typography> */}
     
     <div className='flexRow' style={{paddingTop: 20,width: '100%',justifyContent: 'center'}}>
    
     <div className = "flexCol" style={{height: 400, width: 400, boxSizing: 'border-box',paddingLeft: 40,alignItems: 'flex-start',paddingRight: 40, marginRight: 20}}>

     <Typography
        level="h3"
        fontWeight="xl"
        // fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
       Explain better through videos
      </Typography>
      <Typography style={{marginTop: 20}} fontSize="lg" textColor="text.secondary" lineHeight="lg">
      HR and recruiters can also use video job descriptions to explain requirements more clearly than static text job descriptions.
      </Typography>
      <div style={{marginTop: 20}} className='flexRow'>
      <Typography color="primary" fontSize="lg" fontWeight="lg" className="flexRow">
        Know More 
      </Typography><ArrowForward style={{marginLeft: 5}}color="primary" fontSize="xl" /></div>
     </div>
     <div className="flexCol" style={{height: 400, width: 400}}>
        <img src={better_videos} style={{width: 450}}></img>
     </div>
     </div>

     

    
      </div>
    </CenterLayout>
  );
}