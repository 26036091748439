import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/joy";
import OrgJob from "./OrgJob";
import { connectProfile, getJobsByOrg, getOrganizationDetail } from "../../services/toolsService";
import AlertDialogModal from "../../components/Modals/AlertDialogModal";
import { useSelector } from "react-redux";
import ConnectProfileView from "../ATS/ConnectProfileView";
import MobileOrganization from "./MobileOrganization";



export default function Organization() {
  let params = useParams();
  // Use an iframe or window.location to navigate to the static HTML file
  window.location.href = `/jobs.html?org=${params.org}`;
  return null;
}

// export default function Organization(props) {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const hideProfile = queryParams.get('hideProfile');
//   const hideJobs = queryParams.get('hideJobs');
//   const hideJobLogo = queryParams.get('hideJobLogo');
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.auth.user);
//   const [org,setOrg] = useState({});
//   const [alert, setAlert] = useState({open: false, title: '', actionText: '', text: '', exp: ''});
//    const [jobs, setJobs] = useState([]);
//    const [applyingTo, setApplyingTo] = useState('');
//    const [resume, setResume] = useState(null);
//     let params = useParams();
//     useEffect(()=>{
//      getOrganizationDetail({id: params.org}).then((res)=>{
//       setOrg(res.data)
//      }).catch(()=>{})
//      //get jobs by org
//      getJobsByOrg({id: params.org}).then((res)=>{
//       setJobs(res.data);
//      }).catch(()=>{})
//     },[])
//     return (window.innerWidth <= 800 ? <MobileOrganization></MobileOrganization> :
//     resume != null ? <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: window.innerHeight - 100}}>
//       <ConnectProfileView applyingTo={applyingTo} id={params?.org} remarks={''} setRemarks={(val)=>{}} onSave={()=>{}} close={()=>{setResume(null)}} data={resume} url = {resume.url}></ConnectProfileView>
//       </div> :
//     <div>
    
//       <AlertDialogModal onAction={()=>{
//        //action
//        document.getElementById("resume").click();
//         setAlert({...alert, open: false});
//       }} title={alert.title} actionText={alert.actionText} exp={alert.exp} text={alert.text} open={alert.open} setOpen={(val)=>{ setAlert({...alert, open: val})}} ></AlertDialogModal>
       
//        {hideProfile!= 'true' ? <div style={{display: 'flex', flexDirection: 'row',borderBottom: '1px solid gainsboro', justifyContent: 'center', alignItems: 'center'}}>
//         <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: window.innerHeight - 100 ,justifyContent: 'center', alignItems: 'center'}}>
        
       
      
//         <div style={{maxWidth: '60%'}}>
//         <div style={{width: 200, height: 200, borderRadius: 10  ,backgroundImage: `url("${org?.logo}")`}}>
         
//         </div>
//         <div style={{marginTop: 80}}>{org?.address}</div>
        
//         </div>
//         </div>
//         <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: window.innerHeight - 100, justifyContent: 'center', alignItems: 'flex-start'}}>
//         <div style={{maxWidth: '60%'}}>
//         <h1>{org?.name}</h1>
        
//         <div style={{fontSize: 20}}>{org?.desc}</div>
//         <div style={{display: 'flex', flexDirection: 'row', marginTop:40 , height: 'max-content'}}>
//         <input
//            multiple
//                     type="file"
//                     style={{ display: "none" }}
//                     onChange={e => {
//                       //setProcessing(true);
//                       const files = Array.from(e.target.files);
//                       connectProfile(files,params.org)
//                         .then(data => {

                          
//                           setResume(data.data[0]);
//                           //setUploaded(true);
//                           //setProcessing(false);
//                           //setUploadResult(data.data);
                        
//                         })
//                         .catch((err) => {
//                           if(err=="You have exceeded your storage quota. Please Upgrade."){
//                             // alert(err);
//                             // this.setState({ ...this.state, showModal: false });
//                           }
//                         });
//                       // }
//                     }}
//                     className="custom-file-input"
//                     id="resume"
//                   />
//           <Button color="primary" onClick={()=>{
//           if(user?.type==0){
//           setAlert({open: true, title: 'Are you sure you want to connect?', text: 'You are logged in as a recruiter. If you are sure you want to connect with this organization, click on Connect else click Cancel. Your details may be shared to the organizations recruiter.', actionText: 'Connect'})
//           } else {
//             document.getElementById("resume").click();
//           }
//         }}>Connect your profile</Button>
//         {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 10, fontSize: 15}}> Connect to see and apply to jobs from this organization as they come up.</div> */}
//         </div>
//         </div>
//         </div>
        
//        </div> : <></>}

//        { hideJobs != 'true' ?
//        <div style={{display: 'flex', flexDirection: 'column',boxSizing: 'border-box',height: 'max-content', paddingLeft: 50, paddingRight: 50, justifyContent: 'flex-start', alignItems: 'center'}}>
      
//       {
//         jobs.map((job)=>(
//           <OrgJob onApply={(val)=>{ 
//             if(val!=null){
//               setApplyingTo(val);
//             }
//             document.getElementById("resume").click();}} resume={resume} applied={[]} link={job.id} logo={hideJobLogo!='true' ? job.logo: ''} title={job.name} desc={job.desc}></OrgJob>
//         ))
//       }
      
//       </div> : <></>
//       }

//       </div>
//     //   </TabPanel>
//     //   <TabPanel value={1}>
     
       
//     //   </TabPanel>
//     //   {/* <TabPanel value={2}>
//     //     <b>Third</b> tab panel
//     //   </TabPanel> */}
//     // </Tabs>
//     )
// }