import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import { Textarea } from '@mui/joy';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { createCalendly, createIntro } from '../../services/toolsService';
import RadioGroup from '@mui/joy/RadioGroup';
import Divider from '@mui/joy/Divider';
import Radio from '@mui/joy/Radio';
import ScheduleForm from '../Calendly/ScheduleForm';

export default function CalendlyModal(props) {
   const [name, setName] = React.useState('');
   const [type, setType] = React.useState('online');
   const [page, setPage] = React.useState(0);
   const [duration, setDuration] = React.useState('15');
   const [numberOfDays, setNumberOfDays] = React.useState(0);
   const [startDate, setStartDate] = React.useState('');
   const [endDate, setEndDate] = React.useState('');
   const [desc, setDesc] = React.useState('');
   const [dateRange, setDateRange] = React.useState('set');
   const [customHours, setCustomHours] = React.useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    from: '9:00am',
    to: '5:00pm',
  });
   React.useEffect(()=>{
    if(props.view || props.edit){
      setName(props.selected.name);
      setDesc(props.selected.description)
   
    }
   },[props])
   const handleChange = (event, newValue) => {
    //alert(`You chose "${newValue}"`);
    //setOrg(newValue)
  };
  return (
    <React.Fragment>
      <Modal sx={{overflowY: 'scroll'}} open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{ width: '60%'}}
        >
          <Typography id="basic-modal-dialog-title" component="h2">
            Add new event
          </Typography>
          <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
        {/* An intro allows the recruiter to collect an short video from the candidate */}
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
            //props.setOpen(false);
            }}
          >
            {page == 0 ? <Stack spacing={2}>
              <FormControl>
                <FormLabel>Event Name</FormLabel>
                <Input readOnly={props.view} value={name} onChange={(e)=>{
                
                  setName(e.target.value)
                
                  }} autoFocus required />
              </FormControl>
              
              <FormControl>
                <FormLabel>Type</FormLabel>
                <Select  disabled={props.view} defaultValue={type} onChange={(e)=>{
                  setType(e.target.value)
                }}>
      <Option value="phone">Phone</Option>
      <Option value="online">Online Call</Option>
      <Option value="person">In Person</Option>
      <Option value="other">Other</Option>
    </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea readOnly={props.view} onChange={(e)=>{setDesc(e.target.value)}} value={desc} minRows={4} />
              </FormControl>
             

            </Stack> : <Stack spacing={2}>
            <FormControl>
  <FormLabel>Date range</FormLabel>
  <RadioGroup onChange={(e)=>{setDateRange(e.target.value)}} defaultValue="medium" name="radio-buttons-group">
    <Radio value="set" label="Set days" color="primary" />
    <Radio value="range" label="Within a date range" color="neutral" />
    <Radio value="indefinite" label="Indefinitely into future" color="danger" />
  </RadioGroup>
   {dateRange == 'set' ? <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', maxWidth: '30%'}}><Input onChange={(e)=>{setNumberOfDays(e.target.value)}} type='number' ></Input> calendar days</div>
   : dateRange == 'range' ? <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', maxWidth: '30%'}}><Input onChange={(e)=>{setStartDate(e.target.value)}} type='date' ></Input> <span style={{marginLeft: 5, marginRight: 5}}>to</span> <Input onChange={(e)=>{setEndDate(e.target.value)}} type='date' ></Input></div>
   : ''}
</FormControl>
<Divider></Divider>
<FormControl>
      <FormLabel>Duration</FormLabel>
      <Select  disabled={props.view} defaultValue={duration} onChange={(e)=>{
                  setDuration(e.target.value)
                }}>
      <Option value="15">15 min</Option>
      <Option value="30">30 min</Option>
      <Option value="45">45 min</Option>
      <Option value="60">60 min</Option>
      <Option value="other">Custom</Option>
    </Select>
    </FormControl>
              <Divider></Divider>
<ScheduleForm customHours={customHours} setCustomHours={setCustomHours}></ScheduleForm>
            </Stack> }
            <div style={{marginTop: 10}}>
            {page == 0 ? <Button type="submit" onClick={()=>{
                setPage(1);
            }}>{'Next'}</Button> : <Button type="submit" onClick={()=>{
              let data = {
                name,
                description: desc,
                organization: 'personal',
                json: JSON.stringify({
                type,
                dateRange,
                numberOfDays,
                startDate,
                endDate,
                duration,
                customHours
                })
              }
              console.log(data);
              createCalendly(data).then((res)=>{
                console.log(res)
              }).catch(()=>
              {})
            }}>{'Create'}</Button>}
            </div>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}