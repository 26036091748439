import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';

export default function ScrollCard3(){
    return (
        <div className="flexCol scrollCard" style={{justifyContent: 'flex-start', alignItems: 'flex-start' ,boxSizing: 'border-box',paddingLeft: 40, paddingRight: 40,height: (60/100)*window.innerHeight, width: (20/100)*window.innerWidth, borderRadius: 10}}>
<Typography
        level="h3"
        fontWeight="xl"
        style={{marginTop: 25}}
        // fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
      Team Collaboration
      </Typography>
      <Typography style={{marginTop: 20}} fontSize="lg" textColor="text.secondary" lineHeight="lg">
      Collaborate on hiring candidates more easily with simple and powerful team collaboration features! 
      </Typography>
      <div className='flexRow' style={{marginTop: 20}}>
      <Typography  color="primary" fontSize="lg" fontWeight="lg" className="flexRow">
        Know More 
      </Typography><ArrowForward style={{marginLeft: 5}}color="primary" fontSize="xl" /></div>
        </div>
    )
}