import logo from './logo.svg';
import './App.css';
import Home from './pages/Home/Home';
import { HashRouter, Route, Router, Routes } from "react-router-dom";
import ViewPort from './components/ViewPort/ViewPort';
import Candidates from './pages/Candidates/Candidates';
import Jobs from './pages/Jobs/Jobs';
import Tools from './pages/Tools/Tools';
import Calendar from './pages/Calendar/Calendar';
import Network from './pages/Network/Network';
import Account from './pages/Account/Account';
import Messages from './pages/Messages/Messages';
import CandidatesPage from './pages/Candidates/CandidatesPage';
import Action from './pages/Action/Action';
import IntroList from './pages/Tools/Intro/IntroList/IntroList';
import PreInterviewsList from './pages/Tools/PreInteviews/PreInterviewsList/PreInterviewsList';
import Login from './pages/Login/Login';
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
import store from './store/store';
import FormsList from './pages/Tools/Forms/FormsList/FormsList';
import Signup from './pages/Signup/Signup';
import ProtectedRoute from './shared/ProtectedRoute';
import CreateForm from './pages/Tools/Forms/CreateForm/CreateForm';
import Fill from './pages/Tools/Forms/FillForm/Fill';
import CalendlyList from './pages/Tools/Calendly/CalendlyList';
import CommunicationsList from './pages/Tools/Communications/CommunicationList/CommunicationList';
import WorkFlowsTable from './pages/Tools/Workflows/WorkFlowsTable';
import WorkFlowsList from './pages/Tools/Workflows/WorkFlowsList';
import Apply from './pages/JobApplication/Apply';
import Organization from './pages/Organization/Organization';
import Website from './pages/Website/Website';
import Loading from './pages/Loading/Loading';
import CandidateView from './pages/CandidateView/CandidateView';
import Dashboard from './pages/Dashboard/Dashboard';
import MyProfile from './components/Account/MyProfile';
import TOS from './pages/Landing/TOS';
import Privacy from './pages/Landing/Privacy';
import ATS from './pages/ATS/ATS';
import ResumeView from './pages/ATS/ResumeViewScreen';
import Kanban from './pages/Kanban/Kanban';
import Playlists from './components/Playlists/Playlists';
import Connections from './pages/Connect/Connect';
import ATSOrgs from './pages/ATS/ATSOrgs';
import Home2 from './pages/Home2/Home2';
import VerifyComponent from './components/VerifyComponent';



function App() {
  const isLoading = useSelector((state)=> state.app.isLoading);
  
//   let isLoggedIn = 
  return (
     isLoading ? <Loading></Loading> :
     <HashRouter>
     <Routes> 
      
      <Route path="/" element={<ProtectedRoute element={<ViewPort></ViewPort>}/>}>
        <Route path="home" element={<Home2></Home2>}></Route>
        <Route path="connections" element={<Connections></Connections>}></Route>
        <Route path="playlists" element={<Playlists></Playlists>}></Route>
        <Route path="candidates" element={<CandidatesPage></CandidatesPage>}></Route>
        <Route path="jobs" element={<Jobs></Jobs>}></Route>
        <Route path="intros" element={<IntroList></IntroList>}></Route>
        <Route path="scheduler" element={<CalendlyList></CalendlyList>}></Route>
        <Route path="communications" element={<CommunicationsList></CommunicationsList>}></Route>
        <Route path="workflows" element={<WorkFlowsList></WorkFlowsList>}></Route>
        <Route path="pre-interview" element={<PreInterviewsList></PreInterviewsList>}></Route>
        <Route path="tools" element={<Tools></Tools>}></Route>
        <Route path="ats" element={<ATSOrgs></ATSOrgs>}></Route>
        <Route path="kanban" element={<Kanban></Kanban>}></Route>
        {/* <Route path="tools" element={<Action></Action>}></Route> */}
        <Route path="schedule-interviews" element={<Calendar></Calendar>}></Route>
        <Route path="forms" element={<FormsList></FormsList>}></Route>
        <Route path="create-form" element={<CreateForm></CreateForm>}></Route>
        <Route path="fill-form" element={<Fill></Fill>}></Route>
        <Route path="network" element={<Network></Network>}></Route>
        <Route path="account" element={<Account></Account>}></Route>
        <Route path="messages" element={<Messages></Messages>}></Route>
        <Route path="dashboard" element={<Dashboard></Dashboard>}></Route>
        <Route path="applications/:jobid" element={<Messages></Messages>}></Route>
        <Route path="profile" element={<MyProfile></MyProfile>}></Route>
        
      </Route>
      <Route path="/interviews" element={<Calendar></Calendar>}></Route>
      <Route path="/apply" element={<Apply></Apply>}></Route>
      <Route path="/login" element={<Login></Login>}></Route>
      <Route path="/signup" element={<Signup></Signup>}></Route>
      <Route path="/tos" element={<TOS></TOS>}></Route>
      <Route path="/privacy" element={<Privacy></Privacy>}></Route>
      <Route path="/org/:org" element={<Organization></Organization>}></Route>
      <Route path="/candidate/:id" element={<MyProfile viewMode={true}></MyProfile>}></Route>
      <Route path="/resume" element={<ResumeView></ResumeView>}></Route>
      <Route path="/verify" element={<VerifyComponent></VerifyComponent>} />
      
     

      
      
     
      
     </Routes>
   </HashRouter>
  );
}

export default App;
