import { useState } from 'react'
import { useNavigate } from "react-router-dom"

import AddFieldModal from '../../../../components/Forms/AddFieldModal'
import RenderPlainForm from '../../../../components/Forms/RenderPlainForm'

import { updateObjState } from "../../../../utils"
import '../Forms.css'
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { createForms } from '../../../../services/toolsService'
// import { createForm as saveForm } from "../db"

function CreateForm(){
    const [showAddModal, setShowAddModal] = useState(false)
    const [inputType, setInputType] = useState("text")
    const [err, setErr] = useState("")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const openAddModal = inputType => {
        setShowAddModal(true)
        setInputType(inputType)
    }

    const [formModel, setFormModel] = useState({
        title: "",
        description: "",
        createdAt: +(new Date()),
        organization: 'personal',
        fields: [
            // {
            //     title: "Enter your email",
            //     type: "short-text",
            //     required: true
            // }
        ],
        endMessage: "",
        expiration: ""
    })

    const addFieldToFormModel = field => {
        let _model = Object.assign({}, formModel)
        _model.fields.push(field)
        setFormModel(_model)
    }

    const inputTypes = ["short-text", "long-text", "number", "multioption-singleanswer", "multioption-multianswer", "file"]

    const createForm = async () => {
        if(loading) return
        setErr("")

        if(!formModel.title.trim()) return setErr("Title is required")
        if(formModel.title.trim().length < 5 || formModel.title.trim().length > 50) return setErr("Title should be 5 - 50 characters long")

        if(formModel.expiration.trim() && formModel.expiration < 1) return setErr("Validity should be at least an hour")

        if(formModel.fields.length < 2) return setErr("You need to add at least two fields")

        setLoading(true)
        try{
            // await saveForm(formModel)
            //console.log(formModel);

            let data = {
                name: formModel.title,
                description: formModel.description,
                organization: formModel.organization,
                json: JSON.stringify(formModel)
            }
            createForms(data).then(()=>{}).catch(()=>{});
            console.log(data);
            setLoading(false)
            navigate("/forms")
        }catch(e){
            setErr(e.message)
            setLoading(false)
        }
    }

    return (
        <div className="create-form">
            <h1 className="heading">Create new form</h1>
            
            <div className="form">
                <div className="input">
                    <label>Title of the from</label>
                    <input type="text" placeholder="Enter title" onChange={e => updateObjState(setFormModel, formModel ,"title", e.target.value)} />
                </div>
                <div className="input">
                    <label>Description</label>
                    <input type="text" placeholder="Enter title" onChange={e => updateObjState(setFormModel, formModel ,"description", e.target.value)} />
                </div>
                {/* <FormControl>
                <FormLabel>Under</FormLabel> */}
                <Select defaultValue={formModel.organization} onChange={
                    (e,n) => {console.log(n); updateObjState(setFormModel, formModel ,"organization", n)}
                }>
      <Option value="personal">Personal</Option>
      <Option value="xilligence">Xilligence</Option>
    </Select>
              {/* </FormControl> */}
                {formModel.fields.length > 0 && <RenderPlainForm model={formModel} />}

                <div className="input">
                    <label>End message</label>
                    <input type="text" placeholder="What should user see after submitting the form" onChange={e => updateObjState(setFormModel, formModel ,"endMessage", e.target.value)} />
                </div>

                <div className="input">
                    <label>Validity(Optonal)</label>
                    <input type="number" placeholder="For how many hours the form should be fillable" onKeyDown={e => {if(e.key==='.' || e.key==='-'){e.preventDefault()}}} onChange={e => updateObjState(setFormModel, formModel ,"expiration", e.target.value)} />
                </div>
            </div>

            <p className="mb-2 text-right">
                { err && <p className="err text-right mb-1">{err}</p> }
                <button className="btn" onClick={createForm}>{ loading ? <span className="spinner white"></span> : <span>create form</span>}</button>
            </p>
            
            <div className="add-field-container grey-container">
                <p>Add new field</p>
                { inputTypes.map((inputType, index) => <button className="btn" key={index} onClick={() => openAddModal(inputType)}>{inputType.replace("-", " ")}</button>)}
            </div>
            
            { showAddModal && <AddFieldModal inputType={inputType}  close={() => setShowAddModal(false)} add={addFieldToFormModel} /> }
        </div>
    )
}

export default CreateForm