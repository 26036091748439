import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import CenterLayout from './CenterLayout';
import ScrollCard from './ScrollCard';
import ScrollCard2 from './ScrollCard2';
import ScrollCard3 from './ScrollCard3';

export default function TOS() {
  return (
    <CenterLayout>
    <div className='flexCol' style={{width: '100%'}}>
  {/* <Typography color="primary" fontSize="lg" fontWeight="lg">
    The power to do more
  </Typography> */}
  <Typography
    level="h1"
    fontWeight="xl"
    fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
  >
  Terms of Service
  </Typography>
 <div className='flexRow' style={{paddingTop: 20,width: '60%',justifyContent: 'space-around'}}>
  <Typography style={{marginTop: 20}}fontSize="lg" textColor="text.secondary" lineHeight="lg">
  By accessing and using Interviewable, you agree to comply with the following terms: You must be at least 18 years old, provide accurate information, and maintain the confidentiality of your account. Users shall not engage in unlawful activities or disrupt the platform's integrity. Employers and job seekers are responsible for accurate information and interactions. Submitted content remains your intellectual property, but you grant Interviewable a license to use, modify, and distribute it. Please review our Privacy Policy for information on data processing.
  </Typography>
    {/* <ScrollCard></ScrollCard> */}
 </div>
 
 

  </div>
</CenterLayout>
  );
}