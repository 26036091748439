import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const initialPeople = [
  'User 1',
  'User 2',
  'User 3',
  'User 4',
  // Add more people to the list
];

const TeamCreation = () => {
  const [teamName, setTeamName] = useState('');
  const [teamDescription, setTeamDescription] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [inviteLink, setInviteLink] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAddPerson = (person) => {
    if (!selectedPeople.includes(person)) {
      setSelectedPeople([...selectedPeople, person]);
    }
  };

  const handleCreateTeam = () => {
    // You can implement the logic to create the team here
    // For simplicity, we'll just log the selected data
    console.log('Team Name:', teamName);
    console.log('Team Description:', teamDescription);
    console.log('Selected People:', selectedPeople);
    // Generate a random invite link for demonstration purposes
    const randomCode = Math.random().toString(36).substring(7);
    setInviteLink(`https://yourwebsite.com/invite/${randomCode}`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Create a Team</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Team Name"
          variant="outlined"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Team Description"
          variant="outlined"
          value={teamDescription}
          onChange={(e) => setTeamDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Search People"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h6">Available People</Typography>
          <ul>
            {initialPeople
              .filter((person) =>
                person.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((person) => (
                <li key={person}>
                  {person}{' '}
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleAddPerson(person)}
                  >
                    Add
                  </Button>
                </li>
              ))}
          </ul>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Typography variant="h6">Selected People</Typography>
          <ul>
            {selectedPeople.map((person) => (
              <li key={person}>{person}</li>
            ))}
          </ul>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleCreateTeam}>
          Create Team
        </Button>
      </Grid>
      {inviteLink && (
        <Grid item xs={12}>
          <Typography variant="body1">
            Invite Link: <a href={inviteLink}>{inviteLink}</a>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default TeamCreation;
