import { useEffect, useState } from "react";
import PreInterview from "../RecordVideoInterview/PreInterview";
import { getCandidateIntro, getUserIntro, updateUserIntro } from "../../services/toolsService";
import { Button, Typography } from "@mui/joy";

export default function PersonalIntro(props){
    const [intro, setIntro] = useState('');
    useEffect(()=>{
      if(props?.viewMode == true){
      getCandidateIntro({username: props?.username}).then((res)=>{
        let test = res.data;
        console.log(test)
        setIntro(test);
    }).catch(()=>{})
      } else {
        getUserIntro().then((res)=>{
            let test = res.data;
            console.log(test)
            setIntro(test);
        }).catch(()=>{})
      }
    },[])

    return( 
        <div className="flexRow" style={{alignItems: 'flex-start'}}>
        {props?.viewMode == true ? <></>: <Typography style={{ marginTop: 20, marginBottom: 20, maxWidth: '30%'}} fontSize="sm" fontWeight="md">
        <p>Pre-Interview are a great way to put your best foot forward, speed up the interview process and save time & effort in scheduling and attending interviews. You can start with a quick intro video about yourself.</p>

<p>You have complete control on whether you want to make pre-interview videos viewable to recruiters and HR administrators, or keep them private for certain jobs only.</p>
          </Typography>}
            {
    intro == '' ? <PreInterview  style={{borderRadius: 10, marginTop: 10}} onConfirm={(x)=>{
        console.log(x)
        updateUserIntro({intro: x[0]}).then((res)=>{
           setIntro(res.data);
        }).catch(()=>{

        })
         
      }} mode="intro"></PreInterview> : <div style={{width: '100%', marginTop: 10, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
      <video
      style={{borderRadius: 10}}
          autoPlay
          height={`${(60/100)*window.innerHeight}px`}
          muted
          src = {intro}
          poster="https://assets.codepen.io/6093409/river.jpg"
        ></video>
        {props?.viewMode == true ? <></>: <Button style={{marginTop: 10}} onClick={()=>{setIntro('')}}>Redo</Button>}
      </div>
}
</div>
      )
}