

import './IntroList.css';
import ListItems from '../../../../components/List/List';
import OrderTable from '../../../../components/List/OrderTable';
import IntrosTable from './IntrosTable';




function IntroList() {
  return (
    <div className='intros_container'>
    <ListItems table={<IntrosTable></IntrosTable>}></ListItems>
   </div>
  );
}

export default IntroList;
