import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
const chunkSize = 1048576 * 50;


function getChunkCount(data){
 
  let totalCount =
      data.size % chunkSize == 0
        ? data.size / chunkSize
        : Math.floor(data.size / chunkSize) + 1;

  return totalCount; 
}
function getChunkSize(){

    return chunkSize; 
  }
async function uploadChunk(filename,blockid,chunk,config){
	
	const promise = axios.post(
      `https://interviewableapi20220528123614.azurewebsites.net/api/ChunkUpload/${filename}/${blockid}`,
      chunk,config
    );
 return promise;
}
async function chunkUpload(file,config,currentUser) {
  
    let url = "";

  
   
  
    if(url=="You have exceeded your storage quota. Please Upgrade."){
      return Promise.reject(url);
    }
  
    let headers = { headers: {
      "x-functions-key": currentUser.token
    }}
  
  
   config = Object.assign(config,headers);
   
    
    let filename = file.name;
    let count = getChunkCount(file);
    let bloblist = [];
  
  
    let chunkSize = getChunkSize();
    
    for(var i=0;i<count;i++){
    let start = i*chunkSize;
    let end = start+chunkSize; 
    let chunk = file.slice(start,end)
    let blockid = uuidv4();
    
    await uploadChunk(filename,blockid,chunk,config)
    .then((value)=>{
  bloblist.push(value.data.split('$')[0]);
    }).catch(()=>{}) 
  }
  
  const promise = await axios.post(
   `https://interviewableapi20220528123614.azurewebsites.net/api/CompleteUpload`,
             {FileName: filename,bloblist},{ headers: {
              "x-functions-key": currentUser.token
            }}
           )
 
  
   return Promise.resolve(promise);
  }

  export {chunkUpload}