import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import { useNavigate } from 'react-router-dom';

export default function Hero1() {
  const navigate = useNavigate();
  return (
    <TwoSidedLayout reversed={false}>
      {/* <Typography color="primary" fontSize="lg" fontWeight="lg">
        The power to do more
      </Typography> */}
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
       Hire faster & hire better!
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg" style={{marginTop: 10}}>
      Interviewable lets HR & Recruiters quickly find great candidates and hire them quickly!
      </Typography>
      <Button onClick={()=>{navigate('/signup')}} size="lg" style={{marginTop: 10, borderRadius: 40}} endDecorator={<ArrowForward fontSize="xl" />}>
        Get Started
      </Button>
      <Typography style={{marginTop: 10}}>
        Already a member? <Link onClick={()=>{navigate('/login')}} fontWeight="lg">Sign in</Link>
      </Typography>

      {/* <Typography
        level="body-xs"
        sx={{
          position: 'absolute',
          top: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        HeroLeft01
      </Typography> */}
    </TwoSidedLayout>
  );
}