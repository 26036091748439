
import { text } from '@fortawesome/fontawesome-svg-core';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

function NavTab(props) {
  return (
    <NavLink
    to={`/${props.link}`}
    style={{textDecoration: 'none'}}
    className={({ isActive, isPending }) =>
     isActive ? "active" : isPending ? "pending": "common"
    }
  >
  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
   <div className='navTab'>
    {props?.icon}
    <span className='iconText'>{props?.text}</span>
   </div>
   <FontAwesomeIcon style={{marginTop: 3}}className="dot" fontSize={10} color={props.dotColor} icon={faCircle} />
   </div>
   </NavLink>
  );
}

export default NavTab;
